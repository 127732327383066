import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { register } from "../../../services/authService";

const Register = () => {
  const [error, setError] = useState("");
  const [confirmationMsg, setConfirmationMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      userName: "",
      agentName: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      privacyAgreement: false,
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter a username"),
      agentName: Yup.string().required("Please Enter an agent name"),
      phone: Yup.string()
        .required("Required")
        .matches(/^\d{9}$/, "Must contain 9 digits"),
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .required("Required")
        .matches(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})",
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      privacyAgreement: Yup.bool().oneOf(
        [true],
        "Please confirm you have read and agreed to the privacy policy"
      ),
    }),

    onSubmit: (values) => {
      console.log(values);
      values.phone = `+254${values.phone}`;
      let userDetails = {
        info: values,
      };
      register(userDetails)
        .then((res) => {
          setConfirmationMsg(res?.data?.message);
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    },
  });

  const [eyeIcon, setEyeIcon] = useState(true);
  const [password, setPassword] = useState("password");

  const TogglePasswordVisibilty = () => {
    if (password === "password") {
      setPassword("text");
      setEyeIcon(false);
    } else {
      setPassword("password");
      setEyeIcon(true);
    }
  };

  const [eyeIconConfirmPassword, setEyeIconConfirmPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("password");

  const ToggleConfirmPasswordVisibilty = () => {
    if (confirmPassword === "password") {
      setConfirmPassword("text");
      setEyeIconConfirmPassword(false);
    } else {
      setConfirmPassword("password");
      setEyeIconConfirmPassword(true);
    }
  };

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent d-flex justify-content-center align-items-center">
            <div className="text-muted text-center">
              <h2 className="font-weight-bolder text-center mb-0">Sign up</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {error != "" ? (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            ) : null}
            {confirmationMsg != "" ? (
              <div className="alert alert-info" role="alert">
                {confirmationMsg}
              </div>
            ) : null}
            <Form role="form" onSubmit={formik.handleSubmit}>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Name"
                    type="text"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                {formik.touched.userName && formik.errors.userName ? (
                  <small className="text-danger">
                    {formik.errors.userName}
                  </small>
                ) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-user-tie" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="agentName"
                    type="text"
                    name="agentName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                {formik.touched.agentName && formik.errors.agentName ? (
                  <small className="text-danger">
                    {formik.errors.agentName}
                  </small>
                ) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-phone mr-2" />
                      +254
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="phone number"
                    type="tel"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                {formik.touched.phone && formik.errors.phone ? (
                  <small className="text-danger">{formik.errors.phone}</small>
                ) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                {formik.touched.email && formik.errors.email ? (
                  <small className="text-danger">{formik.errors.email}</small>
                ) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={password}
                    autoComplete="new-password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        onClick={TogglePasswordVisibilty}
                        className={`fa ${eyeIcon ? "fa-eye-slash" : "fa-eye"}`}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {formik.touched.password && formik.errors.password ? (
                  <small className="text-danger">
                    {formik.errors.password}
                  </small>
                ) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm password"
                    type={confirmPassword}
                    autoComplete="new-password"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        onClick={ToggleConfirmPasswordVisibilty}
                        className={`fa ${
                          eyeIconConfirmPassword ? "fa-eye-slash" : "fa-eye"
                        }`}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <small className="text-danger">
                    {formik.errors.confirmPassword}
                  </small>
                ) : null}
              </FormGroup>

              <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                      name="privacyAgreement"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a
                          href="rentika.app/RENTIKA-PRIVACY-POLICY.pdf"
                          onClick={(e) => e.preventDefault()}
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                  {formik.touched.privacyAgreement &&
                  formik.errors.privacyAgreement ? (
                    <small className="text-danger d-flex text-center">
                      {formik.errors.privacyAgreement}
                    </small>
                  ) : null}
                </Col>
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" type="submit">
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/login">
              <small>Already have an account?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default withRouter(Register);
