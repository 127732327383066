import {useEffect, useState, useMemo, useRef} from "react";
import { Card,CardHeader, Row } from "reactstrap"
import { useDispatch, useSelector} from "react-redux";
import './RecentPayment.css'
import MTable from './MTable';

import { fetchDashboardRecentPayments, selectPayments, selectPaymentsStatus } from "store/reducers/paymentsSlice";

 const RecentPaymentTable = () =>{
    const dispatch = useDispatch();
    const headings = ["Building Name", "No. of houses"];
    const payments = useSelector(selectPayments)
    const paymentsStatus = useSelector(selectPaymentsStatus)
    const [buildingId, setBuildingId] = useState("");
    const data = useMemo(
        () =>
            buildingId === ""
            ? payments
            : payments.filter((payment) => payment.buildingId === buildingId),
        [payments, buildingId]
    );

    
     // fetch initial payments data
     useEffect(() => {
        dispatch(fetchDashboardRecentPayments());
    }, []);

    // refresh data on updating status
    useEffect(async () => {
        if (paymentsStatus === 'idle') {
            await dispatch(fetchDashboardRecentPayments());
        }
    }, [payments]);
    return (
        <Card className="shadow overflow-hidden">
            <CardHeader className="border-0" style={{backgroundColor: '#F9FAFD'}}>
              <Row className="align-items-center">
                <div className="col-9">
                  <h3 className="mb-0 text-dark">Recent Payments</h3>
                </div>
              </Row>
            </CardHeader>
                <MTable
                    headings={headings}
                    data={data}
                    actions={true}
                    clickable={true}
                />
          </Card>
    )
}


export default RecentPaymentTable;