import React, { Children } from 'react'
import {Modal,ModalHeader,ModalBody,ModalFooter} from 'reactstrap'


function DynamicModal(props) {
  const {modal,closeModal,Title} = props
  const toggle = () => closeModal(!modal);
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
    <ModalHeader className="text-center d-block">{Title}</ModalHeader>
    <ModalBody>
      {Children}
    </ModalBody>
    <ModalFooter>
      {Children}
    </ModalFooter>
  </Modal>
  )
}

export default DynamicModal