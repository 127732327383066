import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {  Formik } from "formik";
import * as Yup from "yup";
import {  Form, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input } from "reactstrap";
import { addParameter } from "store/reducers/parametersSlice";
import formGroup from "components/Forms/FormFieldFunction";


const ParameterForm = (props) => {
    const dispatch = useDispatch();
    const toggle = () => closeModal();
    const { parameter, modal, closeModal, action, confirmModal } = props;
    const [initialValues, setInitialValues] = useState({
        name: parameter?.name ? parameter.name : "",
        value: parameter?.value ? parameter.value : ""
    });

    useEffect(async () => {
        // check if parameter is selected on edit
        const isEmpty = parameter == null || Object.keys(parameter).length === 0;
        if(!isEmpty){
            // set formik initial values with parameter details
            let initValue = initialValues;
            initValue.name = parameter.name;
            initValue.value = parameter.value;

            setInitialValues(initValue);
        }
    }, [parameter]);

    const validationSchema = Yup.object({
        name: Yup.string().required("required *"),
        value: Yup.string().required("required *"),
    })


    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
                <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true}
                    onSubmit={async (values, { resetForm }) => {
                        if (parameter !== undefined) {
                            confirmModal(parameter["_id"], values);
                            resetForm();
                            toggle();
                        } else {
                            dispatch(addParameter(values));
                            resetForm();
                            toggle();
                        }
                    }}
                >
                {(formik) => (
                    <Form role="form" onSubmit={formik.handleSubmit}>
                    <ModalHeader className="text-center d-block">
                        {action.toUpperCase()} PARAMETER
                    </ModalHeader>
                    <ModalBody>

                        {formGroup("fas fa-adjust", "text", "name", formik, "Name")}
                        {formGroup("fas fa-bullseye", "text", "value", formik, "Value")}

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={!formik.isValid}
                        >
                            {action}
                        </Button>{" "}
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                    </Form>
                )}
                </Formik>
            </Modal>
        </>
    );
};

export default ParameterForm;
