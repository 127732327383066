import React from "react";
import { useDispatch } from "react-redux";
import { Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { Form, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input } from "reactstrap";
import { addBuilding } from "store/reducers/buildingsSlice";
import formGroup from "components/Forms/FormFieldFunction";


const BuildingForm = (props) => {
  const dispatch = useDispatch();
  const toggle = () => closeModal();
  const { building, modal, closeModal, action, confirmModal } = props;

  const initialValues = {
    name: building?.name ? building.name : "",
    address: building?.address ? building.address : "",
    propertyType: building?.propertyType
      ? building.propertyType
      : "RESIDENTIAL",
    noOfUnits: building?.noOfUnits ? building.noOfUnits : 0,
    utilities: building?.utilities?building.utilities:[]
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("required *"),
    address: Yup.string().required("required *"),
    propertyType: Yup.string().required("required *"),
    noOfUnits: Yup.number().required("required *"),
    utilities: Yup.array().of(
      Yup.object().shape({
        utilityName: Yup.string()
          .required('Name is required'),
        utilityUnit: Yup.number()
          .required("Unit price is required")
      })
    )
  })


  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true}
          onSubmit={async (values, { resetForm }) => {
            if (building !== undefined) {
              confirmModal(building["_id"], values);
              resetForm();
              toggle();
            } else {
              dispatch(addBuilding(values));
              resetForm();
              toggle();
            }
          }}
        >
          {(formik) => (
            <Form role="form" onSubmit={formik.handleSubmit}>
              <ModalHeader className="text-center d-block">
                {action} Building
              </ModalHeader>
              <ModalBody>

                {formGroup("fas fa-home", "text", "name", formik, "building name")}
                {formGroup("fas fa-map-marker-alt", "text", "address", formik, "Nairobi, Kenya")}
                {formGroup("fas fa-sort-numeric-up-alt", "number", "noOfUnits", formik, "")}
                {formGroup("far fa-building", "select", "propertyType", formik)}
                <FieldArray
                  name="utilities"
                  render={arrayHelpers => (
                    <div>
                      {
                        formik.values.utilities.map((utility, i) => {
                          return <Row key={i} className="mt-2">
                            <Col className="col">
                              <Field name={`utilities.${i}.utilityName`}>
                                {({
                                  field,
                                  form: { touched, errors },
                                  meta,
                                }) => (<>
                                  <Input
                                    placeholder="Utility Name"
                                    id={`utilities.${i}.utilityName`}
                                    {...field}

                                  >
                                  </Input>
                                  {meta.touched && meta.error &&
                                    <small className="text-danger">{meta.error}</small>
                                  }
                                </>
                                )}
                              </Field>
                            </Col>
                            <Col className="col">
                              <Field name={`utilities.${i}.utilityUnit`}>
                                {({
                                  field,
                                  form: { touched, errors },
                                  meta,
                                }) => (<>
                                  <Input
                                    placeholder="Unit price"
                                    type="number"
                                    id={`utilities.${i}.utilityUnit`}
                                    min={1}
                                    {...field}
                                  >
                                  </Input>
                                  {meta.touched && meta.error &&
                                    <small className="text-danger">{meta.error}</small>
                                  }
                                </>
                                )}
                              </Field>
                            </Col>
                            <Col className="col-1 d-flex align-items-center" style={{ height: "40px" }} onClick={() => arrayHelpers.remove(i)}>
                              <i className="fas fa-trash fa-xs text-danger"></i>
                            </Col>
                          </Row>
                        })
                      }
                      <Row className="d-flex justify-content-center mt-2">
                        <Button className="text-white" color="primary" size="sm" onClick={() => arrayHelpers.push({ utilityName: '', utilityUnit: 1 })}>
                          <i className="fas fa-plus fa-xs"></i>
                        </Button>
                      </Row>
                    </div>
                  )}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                >
                  {action}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default BuildingForm;
