import { useState, useEffect, useMemo } from "react";
import { useTable, usePagination } from "react-table";

// reactstrap components
import {  Card, CardHeader, Container, Row } from "reactstrap";
import { useDispatch, useSelector, connect } from "react-redux";

// core components
import AllPaymentsLayout from "components/Table/AllPaymentsLayout";
import { paymentColumns } from "utils/allpaymentsColumns";
import { fetchAllPayments, selectPayments, selectPaymentsStatus } from "store/reducers/paymentsSlice";

const AllPayments = () => {
    const [buildingId, setBuildingId] = useState("");
    const dispatch = useDispatch();
    const payments = useSelector(selectPayments)
    const paymentsStatus = useSelector(selectPaymentsStatus)

    const data = useMemo(
        () =>
            buildingId === ""
            ? payments
            : payments.filter((payment) => payment.buildingId === buildingId),
        [payments, buildingId]
    );


    const columns = useMemo(() => paymentColumns, []);
    const initialState = { hiddenColumns: ['arrearAmount'] };

    const tableInstance = useTable({ columns, initialState, data }, usePagination);

    // refresh data on updating status
    useEffect(async () => {
        if (paymentsStatus === 'idle') {
            await dispatch(fetchAllPayments());
        }
    }, [payments]);


    return (
        <>
            <div className="" style={{height:'200px'}} ></div>

            <Container style={{backgroundColor: '#f1f1f1'}} className="mt--7"  fluid>
                <Row >
                    <div className="col">
                    <Card className="shadow" style={{overflow:'scroll'}}>
                        <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col-4">
                            <h3 className="mb-0">All Payments</h3>
                            </div>
                            <div className="col-4"></div>
                        </Row>
                        </CardHeader>
                        <AllPaymentsLayout item={true} tableInstance={tableInstance} />
                    </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

const mapPropsToState = (state) => {
    return {
        payments: state.payments.value,
    };
};

export default connect(mapPropsToState)(AllPayments);
