import { API } from "../utils/api";

async function getAllPayments() {
      const response = await API.get(`payments`);
      return response?.data
  }

async function getAllPaymentsAndTypes() {
    const response = await API.get(`allpayments/`);
    return response?.data
}

async function getDashboardRecentPayments() {
        const response = await API.get(`recentpayments/`);
        return response?.data
}

async function getAllBalances() {
    try {
      const response = await API.get(`users/payments/`);
      return response?.data
    } catch (err) {
        console.info(err)
    }
  }

async function createPayment(values) {
  try {
    console.log(values)
    const response = await API.post(`payment/add/${values.tenantId}`, values);
    return response?.data
  } catch (err) {
      console.error("err")
  }
}


async function createInvoice(data) {
  try {
    const response = await API.post(`invoices/`,data);
    return response?.data
  } catch (err) {
      console.info(err)
  }
}

async function setPaymentStatusService(values) {
    console.log("status before send ", values);
try {
    const response = await API.post(`allpayments/${values.id}`, values);
    return response?.data
} catch {
    console.log("something went wrong")
    return false
}
}
   
export {getAllPayments, getAllPaymentsAndTypes, getDashboardRecentPayments, createPayment,getAllBalances, setPaymentStatusService,createInvoice}
