import {Badge} from 'reactstrap'
export const unitColumns = [
  
    {
      Header: "UNIT NO.",
      accessor: "name",
    },
    {
      Header: "BEDROOMS",
      accessor: "bedrooms",
    },
    {
      Header: "RENT",
      accessor: "rent",
    },
    {
      Header: "STATUS",
      accessor: "isOccupied",
      Cell:({ value })=>
        <Badge color="" className="badge-dot mr-4">
        <i className={value?"bg-success":"bg-danger"} />
        {value?"occupied":"vacant"}
      </Badge>
      
    },
  
  ];
  