export const agentColumns = [
  
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "phone",
  },
  {
    Header: "ID NUMBER",
    accessor: "IDNo",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },

];
