import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {  Formik } from "formik";
import * as Yup from "yup";
import {  Form, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input } from "reactstrap";
import { addUtilityType } from "store/reducers/utilitytypesSlice";
import formGroup from "components/Forms/FormFieldFunction";


const UtilityTypeForm = (props) => {
    const dispatch = useDispatch();
    const toggle = () => closeModal();
    const { utilityType, modal, closeModal, action, confirmModal } = props;
    const [initialValues, setInitialValues] = useState({
        name: utilityType?.name ? utilityType.name : ""
    });

    useEffect(async () => {
        // check if utilityType is selected on edit
        const isEmpty = utilityType == null || Object.keys(utilityType).length === 0;
        if(!isEmpty){
            // set formik initial values with utilityType details
            let initValue = initialValues;
            initValue.name = utilityType.name;

            setInitialValues(initValue);
        }
    }, [utilityType]);

    const validationSchema = Yup.object({
        name: Yup.string().required("required *"),
    })


    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
                <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true}
                    onSubmit={async (values, { resetForm }) => {
                        if (utilityType !== undefined) {
                            confirmModal(utilityType["_id"], values);
                            resetForm();
                            toggle();
                        } else {
                            dispatch(addUtilityType(values));
                            resetForm();
                            toggle();
                        }
                    }}
                >
                {(formik) => (
                    <Form role="form" onSubmit={formik.handleSubmit}>
                    <ModalHeader className="text-center d-block">
                        {action.toUpperCase()} UTILITY TYPE
                    </ModalHeader>
                    <ModalBody>

                        {formGroup("fas fa-adjust", "text", "name", formik, "Name")}

                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            type="submit"
                            disabled={!formik.isValid}
                        >
                            {action}
                        </Button>{" "}
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                    </Form>
                )}
                </Formik>
            </Modal>
        </>
    );
};

export default UtilityTypeForm;
