
import { Link } from "react-router-dom";
import {useDispatch,useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
// reactstrap components
import {
  Navbar,
  Container,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Media,
  Button
} from "reactstrap";
import './nav.css'
import {getUserProfile} from 'services/authService';
import {getSmsBalance} from 'services/smsbalanceService';
import {getUser, selectUser} from 'store/reducers/userSlice';
import {getSmsUnitsBalance, selectBalance} from 'store/reducers/smsbalanceSlice';
import TopUpForm from "components/Forms/TopUpForm";
import {logOut} from 'utils/authChecks'
import defaultImg from '../../assets/img/brand/default.png'

const AdminNavbar = (props) => {
    const [openTopUp, setOpenTopUp] = useState(false);
    const user = useSelector(selectUser)
    const balance = useSelector(selectBalance)
    const balanceStatus = balance.units ? (balance.units > 2 ? "success": "warning") : "warning"
    const balanceAmount = balance.units ? balance.units : 0;
    const dispatch = useDispatch();
    const [isMenuHovered, setIsMenuHovered] = useState(false);

    useEffect(() => {
        const getProfile = async () => {
            const response = await getUserProfile() 
            dispatch(getUser(response))      
        }
        getProfile()
        // call sms units balance
        const getUnitsBalance = async () => {
            const response = await getSmsBalance() 
            dispatch(getSmsUnitsBalance(response))      
        }
        getUnitsBalance()
    }, [])

    const handleClose = () =>{
        setOpenTopUp(false)
    }

    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <TopUpForm modal = {openTopUp} user={user} closeModal = {handleClose} action= {"create"}></TopUpForm>
                <Container fluid>
                    <Link
                        className="h4 mb-0 text-uppercase d-none d-lg-inline-block"
                        to="/"
                    >
                        {props.brandText}
                    </Link>
                    <div style={{flexDirection: 'row'}}>
                        <Button
                            color={balanceStatus}
                            onClick={()=>setOpenTopUp(true)}
                            size="md"
                            className="btn-md ml-2 custom-btn"
                        >
                            <span className="d-none d-md-inline">Sms Units {balanceAmount}</span>
                        </Button>

                        {/* <Nav className="align-items-center d-none d-md-flex" navbar> */}
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="pr-0" caret nav 
                                    style={{color: isMenuHovered ? '#666' : '#333'}}
                                    onMouseEnter={()=>setIsMenuHovered(true)} onMouseLeave={()=>setIsMenuHovered(false)}
                                >
                                    <img
                                        alt="avatar"
                                        className="img-fluid rounded-circle shadow"
                                        src={defaultImg}
                                        style={{ width: "40px" }}
                                    ></img>
                                     <span style={{fontSize: '14px',paddingLeft: '10px', paddingRight: '5px'}}>{user.userName}</span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        <Link
                                            style={{color: '#212529'}}
                                            className="mb-0 d-none d-lg-inline-block"
                                            to="/admin/addApp"
                                        >
                                            Account Settings
                                        </Link>
                                    </DropdownItem>
                                    <DropdownItem onClick={()=>logOut(props)}>Logout</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        {/* </Nav> */}
                    </div>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
