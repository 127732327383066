import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Alert, Table, Card, CardHeader, CardBody, CardFooter ,Container, Row, Col, Input, Button } from "reactstrap";
import AgentForm from 'components/Forms/AgentForm'
import Loader from "components/loader/Loader";

import { 
    getReadingByBuildingAndUtility, 
    selectedReading,
    selectReadingStatus,
    readingError,
    postWhatsappReadingByBuilding,
    disableReadingActions
} from "store/reducers/readingsSlice";

const ReadingInput = (props) => {
    const [num, setNum] = useState("");

    const changeHandler = async(event) =>{
        let value = event.target.value;
        if(!isNaN(value)) {
            if(num.includes(".")){
                value = parseFloat(value).toString();
            }
            setNum(value);
            props.setUnitReading(props.unitId, value);
        }
    }

    return (
        <Input 
            disabled={props.disableActions}
            className="form-control"
            type="text" 
            bsSize="sm" 
            value={num} 
            onChange={changeHandler} />
    );
}


const ReadingsWhatsapp = () => {
    const disableActions = useSelector(disableReadingActions);
    const error = useSelector(readingError);
    const reading = useSelector(selectedReading);
    const readingStatus = useSelector(selectReadingStatus);
    const buildingName = reading?.building ? reading.building.name : "";
    const units =  reading?.units ? reading.units : [];
    const [unitIds, setUnitIds] = useState({});
    
    //get search parameters
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const dispatch = useDispatch();

    useEffect(() => {
        // fetch building readings
        if (readingStatus === 'idle') {
            if(params.get("buildingId") && params.get("utilityType") && params.get("memberId")){
                const getParams = {
                    buildingId: params.get("buildingId"),
                    utilityType: params.get("utilityType"),
                    memberId: params.get("memberId")
                }
                dispatch(getReadingByBuildingAndUtility(getParams));
            }
        }
    }, [readingStatus]);

    const setUnitReading = async(id, value) =>{
        unitIds[id] = value;
        setUnitIds(unitIds);
    }

    const submitAction = () =>{
        let units = [];
        for (const key in unitIds) {
            const unit = {id: key, units:unitIds[key]};
            units.push(unit);
        }

        const newReading = {
            buildingId: params.get("buildingId"),
            utilityType: params.get("utilityType"),
            agentId: params.get("memberId"),
            units
        }

        dispatch(postWhatsappReadingByBuilding(newReading));

    }

    const cancelAction = () =>{
        console.log("cancel ")
    }

    return (
        <div>
            <div className="" style={{ height: '100px' }} ></div>
            <Container className="mt--5" fluid>
            <Row>
                {
                    error && (
                        <Col sm="12" xl={{ size: 8, offset: 2 }}>
                            <Alert color="danger" className="text-center"> {error} </Alert>
                        </Col>
                    )
                }
                <Col xl="12">
                    <Card className=" shadow overflow-hidden">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">  
                                <div className="col-12">
                                    <h2 className="mb-3 text-dark">{params.get("utilityType")} Readings</h2>
                                    <h4 className="mb-0 text-dark">Building: {buildingName}</h4>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th>Unit</th>
                                        <th>Previous</th> 
                                        <th>New</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { 
                                    units.length > 0 && units.map((rd)=>{
                                        return(
                                        <tr key={rd._id}>
                                            <td> {rd.name} </td>
                                            <td> {rd?.readings.units || 0} </td>
                                            <td> 
                                                <div>
                                                    <ReadingInput 
                                                        unitId={rd._id} 
                                                        disableActions={disableActions}
                                                        setUnitReading={setUnitReading} />
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    }) 
                                }

                                { 
                                    units.length == 0 && 
                                        (
                                        <tr>
                                            <td colSpan="3" className="text-center"> <h3> No Units Found </h3> </td>
                                        </tr>
                                        )
                                }

                                </tbody>

                                </Table>
                        </CardBody>
                        <CardFooter className="text-center">
                            <Button 
                                color="primary" 
                                onClick={submitAction}
                                disabled={disableActions}
                                >
                                Submit
                            </Button>{" "}
                            <Button 
                                color="secondary" 
                                onClick={cancelAction}
                                disabled={disableActions}
                                >
                                Cancel
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    );
}



const Properties = () => {
  const readingStatus = useSelector(selectReadingStatus);

  return (
    <>
      {readingStatus === 'loading' ? <Loader /> : <ReadingsWhatsapp />}
    </>
  );
};




export default Properties;


