import XLSX from 'xlsx'
import * as Yup from "yup";

const schema = Yup.object().shape({
  firstName: Yup.string().required("required *"),
  lastName: Yup.string().required("required *"),
  IDNo: Yup.number().required("required *"),
  email: Yup.string().required("required *").email("invalid email"),
  phone: Yup.string().required("required *").matches("(?:^([+]254))((7)\\d{8})$","Incorrect phone number format"),
  tenantWhatsapp: Yup.string().required("required *"),
  gender: Yup.string().required("required *"),
  unitId: Yup.string().required("required *")
});

function handleFileChange(e,callback) {
    const file = e.target.files[0];
    const reader = new FileReader();
    let elements = [];
    let fileError = []
    reader.onload = (e) => {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: "array" });
      var firstSheet = workbook.SheetNames[0];
      /* Convert array of arrays */
      elements = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
      var valid = true
      for (let element of elements) {
        schema.validate(element).catch(function (err) {
          fileError.push(err.errors)
          valid = false
        });
      }
      callback(elements,fileError)
    };
    reader.readAsArrayBuffer(file);
  }


  export {handleFileChange}