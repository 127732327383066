import {configureStore} from '@reduxjs/toolkit';
import paymentsReducer from 'store/reducers/paymentsSlice';
import buildingsReducer from 'store/reducers/buildingsSlice';
import tenantReducer from 'store/reducers/tenantsSlice';
import userReducer from 'store/reducers/userSlice';
import revenueReducer from 'store/reducers/revenuesSlice';
import smsbalanceSlice from 'store/reducers/smsbalanceSlice';
import maintenanceReducer from 'store/reducers/maintenanceSlice';
import dashboardmaintenanceReducer from 'store/reducers/dashboardmaintenanceSlice';
import smstopupsSlice from "./reducers/smstopupsSlice";
import agentsReducer from 'store/reducers/agentsSlice';
import utilitytypesReducer from 'store/reducers/utilitytypesSlice';
import parametersReducer from "store/reducers/parametersSlice";
import readingsReducer from "store/reducers/readingsSlice";

export default configureStore({
    reducer:{
        buildings:buildingsReducer,
        agents:agentsReducer,
        payments: paymentsReducer,
        tenants:tenantReducer,
        maintenances: maintenanceReducer,
        user:userReducer,
        smstopup:smstopupsSlice,
        smsbalance:smsbalanceSlice,
        revenues: revenueReducer,
        dashboardmaintenances: dashboardmaintenanceReducer,
        utilitytypes: utilitytypesReducer,
        parameters: parametersReducer,
        readings: readingsReducer
    }
})