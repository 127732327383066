import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { Label, InputGroup, FormGroup, Form, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input } from "reactstrap";
import { addAgent } from "store/reducers/agentsSlice";
import formGroup from "components/Forms/FormFieldFunction";
import Select from "react-select";
import { selectBuildings, fetchAllBuildings, selectBuildingStatus } from "store/reducers/buildingsSlice";


const AgentForm = (props) => {
    const dispatch = useDispatch();
    const toggle = () => closeModal();
    const { agent, modal, closeModal, action, confirmModal } = props;
    const [isMulti, setIsMulti] = useState(true);
    const [selOptions, setSelOptions] = useState([]);
    const buildings = useSelector(selectBuildings);
    const buildingStatus = useSelector(selectBuildingStatus)
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [initialValues, setInitialValues] = useState({
        name: agent?.name ? agent.name : "",
        email: agent?.email ? agent.email : "",
        IDNo: agent?.IDNo ? agent.IDNo : "",
        phone: agent?.phone? agent.phone:"",
        manageAllBuildings: "FALSE",
        buildings: []});

    useEffect(async () => {
        // check if agent is selected on edit
        const isEmpty = agent == null || Object.keys(agent).length === 0;
        if(!isEmpty){
            let options = [];
            let buildingIds = [];

            agent?.buildings.map((row, index) => {
                if(row && row._id){
                    options.push({ label: row.name, value: row._id })
                    buildingIds.push(row._id);
                }
            });
            setSelOptions(options);

            // set formik initial values with agent details
            let initValue = initialValues;
            initValue.name = agent.name;
            initValue.email = agent.email;
            initValue.phone = agent.phone;
            initValue.IDNo = agent.IDNo;
            initValue.buildings = buildingIds;
            initValue.manageAllBuildings = agent?.manageAllBuildings ? agent.manageAllBuildings : "FALSE";

            setInitialValues(initValue);
        }
    }, [agent]);

    useEffect(async () => {
        // fetch buildings
        if (buildingStatus === 'idle') {
          await dispatch(fetchAllBuildings());
        }
        
        // set as options
        let options = [];
        options.push({ label: "Select All", value: "all" })
        buildings.map((row, index) => {
            options.push({ label: row.name, value: row._id },)
        });
        setBuildingOptions(options);
    }, [buildings]);

    const validationSchema = Yup.object({
        name: Yup.string().required("required *"),
        IDNo: Yup.number().required("required *"),
        email: Yup.string().required("required *").email("invalid email"),
        phone: Yup.string().required("required *").matches("(?:^([+]254))((7)\\d{8})$","Incorrect phone number format. Start with +254"),
        manageAllBuildings: Yup.string().required("required *"),
    })

    const handleChange = (form, value) => {
        setSelOptions(value);
        // set formik value for buildings
        let buildingIds = [];
        value.map((row, index) => {
            buildingIds.push(row.value);
        });

        // if all are selected, then set manageAllBuildings as True else False
        const lengthOfOptionsWithoutSelectAll = (buildingOptions.length - 1);
        const isAllSelected = lengthOfOptionsWithoutSelectAll == value.length;
        form.setFieldValue("manageAllBuildings", isAllSelected ? "TRUE" : "FALSE");
        form.setFieldValue("buildings", buildingIds);
    }


    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
                <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true}
                    onSubmit={async (values, { resetForm }) => {
                        if (agent !== undefined) {
                            //convert 07 number to +254
                            let re = new RegExp("(?:^(0))((7)\\d{8})$")
                            if(re.test(values.phone)){
                                let phone = values.phone
                                console.log(phone)
                                phone = phone.split('')
                                phone.splice(0,1)
                                phone = ['+254',...phone].join('')
                                values.phone = phone
                            }
                            confirmModal(agent["_id"], values);
                            resetForm();
                            toggle();
                        } else {
                            dispatch(addAgent(values));
                            resetForm();
                            toggle();
                        }
                    }}
                >
                {(formik) => (
                    <Form role="form" onSubmit={formik.handleSubmit}>
                    <ModalHeader className="text-center d-block">
                        {action.toUpperCase()} TEAM MEMBER
                    </ModalHeader>
                    <ModalBody>

                        {formGroup("fas fa-home", "text", "name", formik, "Name")}
                        {formGroup("fas fa-envelope", "text", "email", formik, "Email")}
                        {formGroup("fas fa-passport", "text", "IDNo", formik, "ID Number")}
                        {formGroup("fas fa-phone", "text", "phone", formik, "Phone Number")}

                        <FormGroup className="mb-3">
                            <Select
                                name="buildings"
                                value={selOptions}
                                placeholder="Select Buildings..."
                                options={buildingOptions}
                                isMulti={isMulti}
                                onChange={selected => {
                                    isMulti &&
                                    selected.length &&
                                    selected.find(option => option.value === "all")
                                    ? handleChange(formik, buildingOptions.slice(1))
                                    : !isMulti
                                        ? handleChange(formik, (selected && selected.value) || null)
                                        : handleChange(formik, selected);
                                }}
                            />
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button
                        color="primary"
                        type="submit"
                        disabled={!formik.isValid}
                        >
                        {action}
                        </Button>{" "}
                        <Button color="secondary" onClick={toggle}>
                        Cancel
                        </Button>
                    </ModalFooter>
                    </Form>
                )}
                </Formik>
            </Modal>
        </>
    );
};

export default AgentForm;
