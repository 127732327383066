import { useState,useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Col,
} from "reactstrap";
import {useHistory} from 'react-router-dom'

import { useFormik } from "formik";
import * as Yup from "yup";
import formGroup from "../../components/Forms/FormFieldFunction";
import jwt from 'jsonwebtoken'
import {resetPassword,forgot} from 'services/authService'

function ForgotPassword(props) {
  const [error, seterror] = useState("");
  const [response, setresponse] = useState("");
  const { id } = props.match.params;
  let history = useHistory()

  const imgStyle = {
    height: 64,
  };
  const initVal =
    id !== undefined ? { newPassword: "", confirmPassword: "" } : { email: "" };
  const validationObj =
    id !== undefined
      ? {
          newPassword: Yup.string()
            .required("Required")
            .matches(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})",
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
          confirmPassword: Yup.string()
            .required("Required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
        }
      : {
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
        };
  const formik = useFormik({
    initialValues: initVal,
    validationSchema: Yup.object(validationObj),
    onSubmit: async (values) => {

      let res = id !==undefined? await resetPassword({...values,token:id}):await forgot(values)
      if(res==='Your password was reset successfull!'){
          history.push('/auth/login')
      }
      setresponse(res)
    },
  });

  useEffect(() => {
    props.match.params.id && jwt.verify(props.match.params.id, 'authSecret65', function(err, decoded) {
        if(err){
            if(err.message === 'jwt malformed'){
                seterror('invalid URL')
            }else if(err.message === 'jwt expired'){
                seterror('Sorry looks like your link expired')
            }else seterror(err.message)
            
        }
    })
  }, []);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5 d-flex justify-content-center">
            <div className="text-center">
              <img
                alt="Rentika logo"
                src={
                  require("../../assets/img/brand/apple-touch-icon.png").default
                }
                style={imgStyle}
              />
              <h3
                className="mt-3 font-weight-bold"
                style={{ textAlign: "center" }}
              >
                Forgot Password
              </h3>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center mb-3">
              <small className="text-center text-danger mb-4">{error.toString()}</small>
              <small className="text-center mb-4">{response.toString()}</small>
            </div>
            <Form role="form" onSubmit={formik.handleSubmit}>
              {id === undefined && formGroup(
                "ni ni-email-83",
                "email",
                "email",
                formik,
                "janedoe@gmail.com"
              )}
              {(id !== undefined && error==='')? formGroup(
                "ni ni-lock-circle-open",
                "password",
                "newPassword",
                formik,
                "Enter new password"
              ):null}
              {(id !== undefined && error==='') ? formGroup(
                "ni ni-lock-circle-open",
                "password",
                "confirmPassword",
                formik,
                "Confirm password"
              ):null}
             


              <div className="text-center">
                {error ===''?<Button className="my-4" color="primary" type="submit" disabled={!formik.isValid}>
                  Send
                </Button>:null}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default ForgotPassword;
