import React, {useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup, Label, Input, InputGroup, FormText
} from "reactstrap";
import { updateMaintenance } from 'services/maintenanceService';
import loader from 'assets/img/brand/rentika-loading.gif'
import ColorPicker from 'components/ColorPicker/ColorPicker';
import Select from 'react-select';

const MaintenanceScheduleModal = (props) => {
    const dispatch = useDispatch();
    const { modal, closeModal, selectedMaintenance, action, isEditAction } = props;
    const toggle = () => closeModal();
    
    const [maintenanceType, setMaintenanceType] = useState(selectedMaintenance.maintenanceType);
    const [tenantName, setTenantName] = useState(selectedMaintenance.tenantName);
    const [tenantPhone, setTenantPhone] = useState(selectedMaintenance.tenantPhone);
    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);    
    const [eventColor, setEventColor] = useState("#eb1616");
    const [eventDate, setEventDate] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState({
        eventDate: "",
        eventDescription: "",
        status: ""
    });
    const [selectedType, setSelectedType] = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    const [eventScheduled, setEventScheduled] = useState(true);

    // reset the fields everytime the modal is opened.
    useEffect(() => {
        if(modal){
            setMaintenanceType(selectedMaintenance.maintenanceType);
            setTenantName(selectedMaintenance.tenantName);
            setTenantPhone(selectedMaintenance.tenantPhone);
            setEventColor(selectedMaintenance.eventColor);
            setEventDate(selectedMaintenance.eventDate);
            setEventDescription(selectedMaintenance.eventDescription);
            setLoading(false);
            const selectedType = { value: selectedMaintenance.status.toLowerCase(), label: selectedMaintenance.status.toLowerCase()}            
            setSelectedType(selectedType);
            setStatus(selectedMaintenance.status.toLowerCase());
            
            let dateStatus = (selectedMaintenance.eventDate == "" || typeof selectedMaintenance.eventDate === "undefined") ? "invalid" : "valid";
            let descriptionStatus = (selectedMaintenance.eventDescription == "" || typeof selectedMaintenance.eventDescription === "undefined") ? "invalid" : "valid";
            let scheduleStatus = (selectedMaintenance.status == "" || selectedMaintenance.status.toLowerCase() == "schedule") ? "invalid" : "valid";

            setErrors({
                ...errors, 
                eventDate: dateStatus, 
                eventDescription: descriptionStatus, 
                status: scheduleStatus
            });


            let options = [];
            options.push({value: "pending", label: "pending"});
            options.push({value: "complete", label: "complete"});
            options.push({value: "cancelled", label: "cancelled"});
            setTypeOptions(options);

            setEventScheduled(true);
            if(selectedMaintenance.status.toLowerCase() == "schedule"){
                console.log("event is not yet scheduled.")
                setEventScheduled(false);
                setSelectedType({ value: "pending", label: "pending"});
                setStatus("pending");
                setErrors({...errors, status: "valid"})
            }

        }
      }, [modal]);

    useEffect(() => {
        // check if there's any error to disable the submit button
        let disableButton = false;
        for (const key in errors) {
            console.log(`${key}: ${errors[key]}`);
            if(errors[key] == "invalid" || errors[key] == ""){
                disableButton = true;
            }
        }

        setDisableSubmit(disableButton);
    }, [errors]);

    const handleChange = (event, name) => {
        const value = name.toLowerCase() === "status" ? event.target.checked : event.target.value;
        switch (name.toLowerCase()) {
            case "eventdescription": {
                setEventDescription(value);
                let val = value == "" ? "invalid" : "valid";
                setErrors({...errors, eventDescription: val})
                break;
            }
            case "eventcolor": {
                setEventColor(value);
                break;
            }
            case "eventdate": {
                setEventDate(value);
                let val = value == "" ? "invalid" : "valid";
                setErrors({...errors, eventDate: val})
                break;
            }
            case "tenantname": {
                setTenantName(value);
                break;
            }
            case "tenantphone": {
                setTenantPhone(value);
                break;
            }
            case "maintenancetype": {
                setMaintenanceType(value);
                break;
            }
            default:
                break;
        }
        
    };

    const handleSelect = (selectedType) => {
        setSelectedType(selectedType);
        setStatus(selectedType.value);
        let errorStatus = selectedType.value.toLowerCase() == "schedule" ? "invalid" : "valid";
        setErrors({...errors, status: errorStatus})
    };

    const isFormValid = () =>{
        let isValid = true;

        if(eventDate === "" || typeof eventDate === 'undefined'){
            setErrors({...errors, eventDate: "invalid"})
            isValid = false;
        }

        if(eventDescription === "" || typeof eventDescription === 'undefined'){
            setErrors({...errors, eventDescription: "invalid"})
            isValid = false;
        }

        if(status === "" || status === "schedule"){
            setErrors({...errors, status: "invalid"})
            isValid = false;
        }

        return isValid;
    }

    const submitPayment = async (event) =>{
        event.preventDefault();
        
        // check if form is valid
        if(!isFormValid()){
            console.log("fill the required details");
            return;
        }
        // create a post first then send it
        const eventData = {
            eventDate,
            eventColor,
            eventDescription,
            status: selectedType.value
        }

        setLoading(true);
        console.log("_id=", selectedMaintenance, " eventData=",eventData);
        const response = await updateMaintenance(selectedMaintenance._id, eventData);
        console.log("response ", response)
        if(response.responseCode == "00"){
            // disable something else
            // send redux action to update list
            toggle();
            window.location.reload();
        }
        setLoading(false);
    }

    return (
        <>
            <Modal isOpen={modal} size="md" toggle={toggle} className="modal-dialog-centered">
                <ModalHeader className="text-center d-block">
                    Add Schedule Event
                </ModalHeader>
                <ModalBody>
                    <Form role="form">
                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                {`${tenantName} [ ${tenantPhone} ]`}
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Input
                                    placeholder="Maintenance Type"
                                    type={"text"}
                                    disabled
                                    value={maintenanceType}
                                    onChange={(event) => handleChange(event, "maintenancetype")}
                                >
                                </Input>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label for="eventDate">Event Date:</Label>
                            <Input
                                type="date"
                                name="eventDate"
                                id="eventDate"
                                placeholder="Event Date"
                                value={eventDate}
                                valid={ errors.eventDate === 'valid' }
                                invalid={ errors.eventDate === 'invalid' || typeof eventDate === 'undefined' }
                                onChange={ (event) => handleChange(event, "eventdate") }
                                disabled={isEditAction}
                            />
                            {
                                (errors.eventDate === 'invalid' || errors.eventDate === '' || typeof eventDate === 'undefined') && (
                                    <FormText color="danger">
                                        Field is required.
                                    </FormText>
                                )
                            }
                            
                        </FormGroup>

                        <FormGroup>
                            <label
                                className="form-control-label" >Event Color
                            </label>
                            <div>
                                <ColorPicker disabled={isEditAction} setColorCode={setEventColor} eventColor={eventColor}/>
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Event Description
                            </label>
                            <InputGroup className="input-group-alternative">
                                <Input
                                    placeholder="Enter Description"
                                    type={"textarea"}
                                    value={eventDescription}
                                    valid={ errors.eventDescription === 'valid' }
                                    invalid={ errors.eventDescription === 'invalid' || typeof eventDescription === 'undefined' }
                                    onChange={(event) => handleChange(event, "eventDescription")}
                                    disabled={isEditAction}
                                >
                                </Input>
                            </InputGroup>
                            {
                                (errors.eventDescription == 'invalid' || errors.eventDescription == '' || typeof eventDescription === 'undefined') && (
                                    <FormText color="danger">
                                        Field is required.
                                    </FormText>
                                )
                            }
                        </FormGroup>

                        { eventScheduled && (
                            <FormGroup>
                                <Label for="status">Status:</Label>
                                <Select
                                    isMulti = {false}
                                    value={selectedType}
                                    onChange={handleSelect}
                                    options={typeOptions}
                                />
                                {
                                    (errors.status == 'invalid' || errors.status == '') && (
                                        <FormText color="danger">
                                            Status is schedule: Update to add it to calendar.
                                        </FormText>
                                    )
                                }
                            </FormGroup>
                        )}
                        
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        type="submit"
                        onClick={submitPayment}
                        disabled={disableSubmit}
                    >
                        Submit
                    </Button>{" "}
                   
                    <Button color="secondary" onClick={toggle}> Cancel </Button>
                    
                </ModalFooter>
            </Modal>
        </>
    );
};

export default MaintenanceScheduleModal;
