import { Row, Col } from "reactstrap";
import MaintenanceChart from './MaintenanceChart';
import MaintenanceTable from './MaintenanceTable';

const MaintenanceSummary = () => {

    return (
        <div>
            <Row className="mt-5">
                <Col xl="8">
                    <MaintenanceTable />
                </Col>

                <Col xl="4">
                    <MaintenanceChart />
                </Col>
            </Row>
        </div>
    );
};


export default MaintenanceSummary;
