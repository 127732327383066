import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import ParameterTable from "components/Table/ParameterTable";
import ParameterForm from 'components/Forms/ParameterForm'
import Loader from "components/loader/Loader";
import {
    fetchAllParameters,
    selectParameters, 
    selectParameterStatus,
    parameterError
} from "store/reducers/parametersSlice";



const Parameters = () => {
    const parameters = useSelector(selectParameters);
    const error = useSelector(parameterError);
    const headings2 = ["Name", "Value"];
    const [OpenParameter, setOpenParameter] = useState(false);
    const [OpenUnit, setOpenUnit] = useState(false);

    const handleClose = () => {
        setOpenParameter(false)
        setOpenUnit(false)
    }

    return (
        <div>
            <div className="" style={{ height: '200px' }} ></div>
            <Container className="mt--5" fluid>
            <ParameterForm modal={OpenParameter} closeModal={handleClose} action={"create"}></ParameterForm>
            
            <Row>
                {
                    error && (
                        <Col sm="12" xl={{ size: 8, offset: 2 }}>
                            <Alert color="danger" className="text-center"> {error} </Alert>
                        </Col>
                    )
                }
                <Col xl="12">
                    <Card className=" shadow overflow-hidden">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">  
                                <div className="col-6">
                                    <h3 className="mb-0 text-dark">Parameters</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <Button
                                        color="primary"
                                        onClick={() => setOpenParameter(true)}
                                        size="sm"
                                        className="btn-sm ml-2"
                                    >
                                        <i className="fas fa-plus mr-0 mr-md-2"></i>
                                        <span className="d-none d-md-inline">Add Parameter</span>
                                    </Button>
                                </div>
                            </Row>
                        </CardHeader>
                        <ParameterTable
                            headings={headings2}
                            data={parameters}
                            actions={true}
                            clickable={true}
                        />
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    );
}



const Properties = () => {
  const parameterStatus = useSelector(selectParameterStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (parameterStatus === 'idle') {
      dispatch(fetchAllParameters());
    }
  }, [parameterStatus]);

  return (
    <>
      {parameterStatus === 'loading' ? <Loader /> : <Parameters />}
    </>
  );
};




export default Properties;


