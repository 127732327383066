import {API} from "../utils/api";

async function createAgent(agent) {
  try {
    const response = await API.post(`agents/add`,agent);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getAgent(id){
    try {
        const response = await API.get(`agents/${id}`);
        return response?.data
      } catch {
          console.log("something went wrong")
      }
}

async function getAgents() {
  try {
    const response = await API.get(`agents/`);
    return response?.data
  } catch(error) {
      console.log("something went wrong ")
  }
}

async function updateAgent(id,agentData) {
  try {
    const response = await API.post(`agents/update/${id}`,agentData);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function deleteAgent(id) {
  try {
    const response = await API.delete(`agents/delete/${id}`);
    return response.data
  } catch {
      console.log("something went wrong")
      return false
  }
}


export {
    getAgent,
    getAgents,
    createAgent,
    updateAgent,
    deleteAgent
}