import {format} from 'date-fns'
import { dateBetweenFilterFn, DateRangeColumnFilter } from './DateRangeColumnFilter';

export const reportColumns = [
  {
    Header: "Payment Date",
    accessor: "createdTime",
    Cell:({value})=>{return format(new Date(value),'dd/MM/yyyy')},
    Filter: DateRangeColumnFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "BUILDING",
    accessor: "building",
  },
  {
    Header: "UNIT",
    //accessor: "units",
    accessor: "unit",
    // Cell:({value})=><span color="">{value.unitName}</span>
  },
  {
    Header: "TENANT",
    accessor: "tenant",
  },

  {
    Header: "AMOUNT",
    accessor: "amount",
  },
  {
    Header: "TYPE OF PAYMENT",
    accessor: "paymentType",
  },
  {
    Header: "MODE",
    accessor: "modeOfPayment",
  },
  {
    Header: "tenantID",
    accessor: "tenantId",
  },

  {
    Header: "ID",
    accessor: "_id",
  },
  
];

