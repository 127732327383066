import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { getBuildingTenants ,createTenant,createTenants,updateTenant,deleteTenant} from 'services/tenantService'
import {getAllTenants} from 'services/tenantService'



export const fetchAllTenants = createAsyncThunk('All/tenants', async () => {
    const response = await getAllTenants()
    return response
  })
export const fetchBuildingTenants = createAsyncThunk('building/tenants', async (id) => {
    const response = await getBuildingTenants(id)
    return response
  })
export const addTenant = createAsyncThunk('add/tenant', async (values) => {
    const res = await createTenant(values)
    return res
  })
export const addTenants = createAsyncThunk('add/tenants', async (values) => {
  const res = await createTenants(values)
  return res
})
export const updateTenantReducer = createAsyncThunk('update/tenant', async (tenant) => {
    const {tenantId,values} =tenant
    const res = await updateTenant(tenantId,values)
    return res
  })
export const deleteTenantReducer = createAsyncThunk('delete/tenant', async (id) => {
    const res = await deleteTenant(id)
    return res
  })



export const tenantsSlice = createSlice({
    name: 'tenants',
    initialState:{
        value:[],
        status:'idle',
        error:null
    },
    reducers: {    
      clearTenantsState:(state,action)=>{
        state.value=[];
        state.status='idle'
    }
    },
    extraReducers(builder) {
        builder
        .addCase(fetchAllTenants.fulfilled, (state, action) => {
            state.status ='complete'
            state.value = action.payload
          })
        .addCase(fetchBuildingTenants.fulfilled, (state, action) => {
            if(action.payload !== undefined)state.value = action.payload
            
          })
        .addCase(addTenant.pending, (state, action) => {
            state.status = 'loading'
          })
        .addCase(addTenant.fulfilled, (state, action) => {
              state.status = 'complete'
              action.payload.fullName = `${action.payload.firstName} ${action.payload.lastName}`
              state.value.push(action.payload)
          })
        .addCase(addTenants.fulfilled, (state, action) => {
          state.status = 'complete'
          action.payload.map((item)=>item.fullName = `${item.firstName} ${item.lastName}`)
          state.value = action.payload
        })
        .addCase(updateTenantReducer.fulfilled, (state, action) => {
          state.status = 'idle'
          const findIndex = state.value.findIndex(item=>item._id === action.payload._id)
          action.payload.fullName = `${action.payload.firstName} ${action.payload.lastName}`
          state.value[findIndex]=action.payload
          })
        .addCase(deleteTenantReducer.fulfilled, (state, action) => {
            state.status = 'idle'
            action.payload.map((item)=>item.fullName = `${item.firstName} ${item.lastName}`)
            state.value = action.payload
           
          })
    }
})

export const {clearTenantsState} = tenantsSlice.actions

export const selectTenants = state => state.tenants.value;
export const selectTenantById = (state,buildingId) => 
    state.tenants.value.filter((tenant)=>tenant.buildingId === buildingId)
export const selectTenantStatus = state => state.tenants.status;
export default tenantsSlice.reducer;
