import { useDispatch, useSelector } from "react-redux";
import {useState, useEffect} from 'react'

import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import { useFormik} from "formik";

import * as Yup from "yup";
import formGroup from "../../components/Forms/FormFieldFunction";
import { ToastContainer } from "react-toastify";
import { fetchAllBuildings,selectBuildings } from "store/reducers/buildingsSlice";
import {createInvoice} from "services/paymentsService"
import { sendReminder } from "services/tenantService";

const InvoiceForm = (props) => {
  const { modal, closeModal, tenant } = props;
  const toggle = () => closeModal();
  const dispatch = useDispatch();
  const buildings = useSelector(selectBuildings)
  const [utilityOptions,setUtilityOptions] = useState([])
  const [building,setBuilding] = useState(undefined)
  

  const paymentOptions = ["RENT", "UTILITY", "MAINTENANCE"].map((val,index) => (
    <option value={val} key={index}>{val}</option>
  ));
 

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      amount: 0,
      paymentType: "",
      utility: "",
      Quantity:0
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("required *"),
      paymentType: Yup.string().required("required *"),
      utility: Yup.string().when('paymentType', { is: "UTILITY", then: Yup.string().required("required *") }),
    }),
    onSubmit: async (value, { resetForm }) => {  
        
        let values = {...value,buildingId:tenant.buildingId,unitId:tenant.unitId,ownerId:tenant.ownerId,utilityId:value?.utility,tenantId:tenant.tenantId}
        if(values.paymentType !== 'RENT')
          values.amount = document.getElementById('amount').value
        console.log("Hello")
        await createInvoice(values)
        await sendReminder(tenant.tenantId,values);
        resetForm();
        toggle();
    },
  });

  useEffect(() => {
    const result = buildings.find(item => item._id === tenant.buildingId)
    setBuilding(result)
    if (result !== undefined) {
      const resul = result.utilities.map((val, index) => (
        <option value={val._id} key={index}>{val.utilityName}</option>
      ));
      setUtilityOptions(resul)
    }
  }, [tenant])


  return (
    <>
      <ToastContainer />

      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader className="text-center d-block">Invoice</ModalHeader>
        <ModalBody>
        <Form role="form" onSubmit={formik.handleSubmit}>
            {formGroup("far", "select", "paymentType", formik, "select type of payment", paymentOptions)}
            {formik.values.paymentType === "UTILITY" ? formGroup("far", "select", "utility", formik, "select a utility", utilityOptions) : formik.values.utility = ""}
            {formik.values.paymentType === "MAINTENANCE" ?formGroup("far", "text", "amount", formik, "amount"):null}
            {formik.values.paymentType === "UTILITY" ? formGroup("far", "text", "Quantity", formik, "enter a quantity") :null}
          { formik.values.paymentType === "UTILITY"?
            <Input
                placeholder="amount"
                type="text"
                name="amount"
                id="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                formik.values.paymentType === "UTILITY" && formik.values.utility?
                formik.values["Quantity"]*(buildings.find(item => item._id === tenant.buildingId).utilities.find((utility)=>utility._id === formik.values["utility"])).utilityUnit 
                : formik.values["amount"]}
                readOnly = {formik.values.paymentType == "UTILITY"?true:false}
              />:null
            }
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
            type="submit"
            disabled={!formik.isValid}
          >
            Invoice
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InvoiceForm;
