import loader from 'assets/img/brand/rentika-loading.gif'

function Loader() {
    return ( <>
    <div className="vh-100 d-flex justify-content-center align-items-center">
        <img src={loader} />
    </div>
    
    
    </> );
}

export default Loader;