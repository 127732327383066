import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DynamicModal from "./DynamicModal";

const DeleteModal = (props) => {

    const {modal,closeModal,title,item,confirmModal} = props
    const toggle = () => closeModal(!modal);
    const confirm = () => confirmModal(item["_id"])
  return (
    <>
    <DynamicModal>
      
    </DynamicModal>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader className="text-center d-block">
          Delete {title}
        </ModalHeader>
        <ModalBody className="text-center d-block">
        Are you sure you want to delete {item["name"] ? item['name']:item['firstName']} ?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={()=>{toggle();confirm()}}>Delete</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteModal;
