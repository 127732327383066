import {Badge,Button} from 'reactstrap'
import {format} from 'date-fns'
import { dateBetweenFilterFn, DateRangeColumnFilter } from './DateRangeColumnFilter';


export const smstopupColumns = [
  {
    Header: "TopUp Date",
    accessor: "createdTime",
    Cell:({value})=>{return format(new Date(value),'dd/MM/yyyy')},
    Filter: DateRangeColumnFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
  {
    Header: "SMS UNITS",
    accessor: "units",
  },
  {
    Header: "PHONE",
    accessor: "phone",
  },
  {
    Header: "TRANSACTION TRACKING ID",
    accessor: "pesapalTransactionTrackingId",
  },
  {
    Header: "MERCHANT REFERENCE",
    accessor: "pesapalMerchantReference",
  },
  {
    Header: "PAYMENT METHOD",
    accessor: "paymentMethod",
  },
  {
    Header: "STATUS",
    accessor: "status",
    Cell:({value})=><Badge color="" className="badge-dot mr-4">
    <i className={value.toLowerCase() === "completed" ? "bg-success":(value.toLowerCase() === "initiated" || value.toLowerCase() === "pending" ? "bg-info": "bg-danger") } />{value}</Badge>
  },
  {
    Header: "ID",
    accessor: "_id",
  },
  
];

