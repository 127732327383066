import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardHeader, Button, Container, Row, FormGroup, Input } from "reactstrap";
import TenantTable from "components/Table/TenantTable";
import TenantForm from "components/Forms/TenantForm";
import SelectBuildingModal from 'components/Modals/SelectBuildingModal'
import { fetchAllTenants, addTenants, selectTenants } from 'store/reducers/tenantsSlice';
import { fetchAllBuildings, selectBuildings } from 'store/reducers/buildingsSlice';
import { handleFileChange } from 'utils/bulkTenantUpload'

import 'assets/css/inputs.css'


const Tenants = () => {
  const dispatch = useDispatch()
  const headings = ["Name", "email", "gender", "Actions"];
  const [OpenSelect, setSelectOpen] = useState(false);
  const [openCreateTenant, setCreateTenantOpen] = useState(false);
  const [buildingName, setBuildingName] = useState('All');
  const [buildingId, setBuildingId] = useState('');
  const tenants = useSelector(selectTenants)
  const buildings = useSelector(selectBuildings)
  const [specificBuilding, setSpecificBuilding] = useState(undefined);
  const [fileError, setFileError] = useState([]);



  const uploadTenants = (tenants, err) => {
    //remove all previous errors
    setFileError([])
    if (err.length !== 0) {
      setFileError(err)
    } else {
      const tenantSet = new Set();
      let result = []
      tenants.map((tenant) => {
        //check if unit is not duplicate
        if (tenantSet.has(tenant.unitId)) {
          setFileError((prevError) => [...prevError, `Error: duplicate unitId ${tenant.unitId}`])
        } else {
          tenantSet.add(tenant.unitId)
          const building = buildings.find((item) => item._id === buildingId)
          const unit = building.units.find((item) => item.name == tenant.unitId)
          //check if unit exists
          if (unit === undefined) {
            setFileError((prevError) => [...prevError, `Error: unitId ${tenant.unitId} does not exist in ${buildingName}`])
          } else {
            //check unit is not occupied
            if (unit.isOccupied) {
              setFileError((prevError) => [...prevError, `Error: unit ${tenant.unitId} is already occupied`])
            } else {
              tenant.buildingId = buildingId
              tenant.unitId = unit._id
              tenant.phone = `+${tenant.phone}`
              result.push(tenant)
            }
          }


        }

      })
      if (fileError.length === 0) {
        dispatch(addTenants(result))
        dispatch(fetchAllTenants())
      } else {
        console.log(fileError)
      }
    }

  }

  const handleClose = () => {
    setSelectOpen(false)
    setCreateTenantOpen(false)
  }

  const handleConfirm = (id, name) => {

    id !== undefined ? setBuildingName(name) : setBuildingName(`All`)
    id && setBuildingId(id)
    id !== undefined ? setSpecificBuilding(id) : setSpecificBuilding(undefined)
    setSelectOpen(false)
    setCreateTenantOpen(false)
  }

  useEffect(() => {
    dispatch(fetchAllBuildings())
    dispatch(fetchAllTenants())
  }, [])
  return (
    <>
      {/* Page content */}

      <SelectBuildingModal modal={OpenSelect} closeModal={handleClose} title={"building"} confirmModal={handleConfirm}></SelectBuildingModal>
      <TenantForm modal={openCreateTenant} closeModal={handleClose} action={"create"} buildingId={buildingId}></TenantForm>
      <div className="" style={{ height: '200px' }} ></div>

      <Container className="mt--7" fluid>

        <Row>
          <div className="col">
            {/* Bulk upload error message box */}
            <div className='bg-white p-3 text-center mb-2' style={{ display: fileError.length === 0 ? 'none' : null }}>
              {fileError.map((err, index) => <small className="text-danger d-block" key={index}>{err}</small>)}
            </div>
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-4">
                    <h3 className="mb-0">{buildingName}</h3>
                  </div>
                  <div className="col-4 row">
                    <span>
                      <Input
                        id="tenantFile"
                        color="primary"
                        style={{ display: buildingName === `All` ? `none` : null, opacity: 1, }}
                        bsSize="sm"
                        type="file"
                        className="custom-file-input col "
                        accept=".xlsx,.xls,.csv"
                        onChange={async (e) => {

                          const fileResult = await handleFileChange(e, uploadTenants)
                          e.target.value = ''
                        }
                        }
                      />
                    </span>
                    <span>
                     
                    </span>
                  </div>

                  <div className="col-4 text-right">
                    <span>
                      <Button
                        color="info"
                        onClick={() => { setSelectOpen(true) }}
                        className="btn-sm"
                      >
                        <i className="fas fa-sign-out-alt mr-0 mr-md-2"></i>
                        <span className="d-none d-md-inline">change building</span>
                      </Button>
                    </span>
                    <span>
                      <Button
                        color="primary"
                        style={{ display: buildingName === `All` ? `none` : null }}
                        onClick={() => { setCreateTenantOpen(true) }}
                        size="sm"
                        className="btn-sm ml-2"
                      >
                        <i className="fas fa-plus mr-0 mr-md-2"></i>
                        <span className="d-none d-md-inline">Add a tenant</span>
                      </Button>
                    </span>

                  </div>
                </Row>
              </CardHeader>
              <TenantTable headings={headings} tenants={tenants} specificBuilding={specificBuilding}></TenantTable>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tenants;
