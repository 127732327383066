import Index from "views/Index.js";
import Properties from "views/pages/Properties"
import Payments from "views/pages/Payments.js";
import AllPayments from "views/pages/AllPayments.js";
import SmsTopUps from "views/pages/SmsTopUps";
import Tenants from "views/pages/Tenants";
import Register from "views/pages/Auth/Register.js";
import Login from "views/pages/Auth/Login.js";
import Reports from "views/pages/Reports";
import ForgotPassword from "views/pages/ForgotPassword";
import Profile from "views/pages/profile";
import Maintenances from "views/pages/Maintenances/Maintenances";
import confirmEmail from "views/pages/Auth/confirmEmail";
import Agents from "views/pages/Agents";
import UtilityTypes from "views/pages/UtilityTypes";
import Parameters from "views/pages/Parameters";
import ReadingsWhatsapp from "views/pages/Readings/ReadingsWhatsapp";
import MaintenanceWhatsapp from "views/pages/Maintenances/MaintenanceWhatsapp";
import MaintenanceWhatsappForm from "views/pages/Maintenances/MaintenanceWhatsappForm";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-home text-primary",
    component: Index,
    layout: "/admin",
    show: true
  },

  {
    path: "/properties",
    name: "Properties",
    icon: "fas fa-building text-info",
    component: Properties,
    layout: "/admin",
    show: true
  },
  {
    path: "/tenants",
    name: "Tenants",
    icon: "fas fa-users text-warning",
    component: Tenants,
    layout: "/admin",
    show: true
  },
  {
    path: "/agents",
    name: "Team Members",
    icon: "fas fa-users text-gray",
    component: Agents,
    layout: "/admin",
    show: true
  },
  {
    path: "/rent",
    name: "Payments",
    icon: "fas fa-dollar-sign text-green",
    component: Payments,
    layout: "/admin",
    show: true
  },
  {
    path: "#",
    name: "Apps",
    icon: "far fa-file-alt text-pink",
    component: Maintenances,
    layout: "/admin",
    show: true,
    children: [
        {
            path: "/payments",
            name: "Payment Confirmation",
            component: AllPayments,
            layout: "/admin",
            show: true
          },  
        {
            path: "/maintenances",
            name: "Maintenance",
            component: Maintenances,
            layout: "/admin",
            show: true
        },
    ]
  }, 
  {
    // ensure parents have path as # to avoid duplicate routes
    path: "#",
    name: "Reports",
    icon: "far fa-file-alt text-yellow",
    component: Reports,
    layout: "/admin",
    show: true,
    children: [
        {
            path: "/reports",
            name: "Payment Report",
            component: Reports,
            layout: "/admin",
            show: true
        }, 
        {
            path: "/smstopups",
            name: "Sms Top-Ups Report",
            component: SmsTopUps,
            layout: "/admin",
            show: true
        }
    ]
  },
  {
    // ensure parents have path as # to avoid duplicate routes
    path: "#",
    name: "Setup",
    icon: "far fa-circle text-red",
    component: Reports,
    layout: "/admin",
    show: true,
    children: [
        {
            path: "/utilitytypes",
            name: "Utility Types",
            component: UtilityTypes,
            layout: "/admin",
            show: true
        }, 
        {
            path: "/parameters",
            name: "Parameters",
            component: Parameters,
            layout: "/admin",
            show: true
        }
    ]
  },
  {
    path: "/addApp",
    name: "Account settings",
    icon: "fab fa-app-store-ios text-pink",
    component: Profile,
    layout: "/admin",
    show: false
  },
  {
    path: "/register",
    name: "Register",
    icon: "fab fa-app-store-ios text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    icon: "fas fa-sign-out-alt",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/confirm-email",
    name: "Confirm Email",
    icon: "fas fa-sign-out-alt",
    component: confirmEmail,
    layout: "/info",
  },
  {
    path: "/forgotPassword",
    name: "Forgot Password",
    icon: "fas fa-sign-out-alt",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/forgot/:id",
    name: "Forgot Password",
    icon: "fas fa-sign-out-alt",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/readings/whatsapp",
    name: "Readings",
    icon: "fas fa-dollar-sign text-green",
    component: ReadingsWhatsapp,
    layout: "/management",
    show: false
  },
  {
    path: "/whatsappmaintenances",
    name: "Maintenances",
    icon: "fas fa-dollar-sign text-green",
    component: MaintenanceWhatsapp,
    layout: "/management",
    show: false
  },
  {
    path: "/addwhatsappmaintenances",
    name: "Add Maintenance",
    icon: "fas fa-dollar-sign text-green",
    component: MaintenanceWhatsappForm,
    layout: "/management",
    show: false
  },

];
export default routes;
