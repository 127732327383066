import {API} from "../utils/api";

async function createReading(reading) {
  try {
    const response = await API.post(`readings/add`,reading);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getReading(id){
    try {
        const response = await API.get(`readings/${id}`);
        return response?.data
      } catch {
          console.log("something went wrong")
      }
}

async function getReadings() {
  try {
    const response = await API.get(`readings/`);
    return response?.data
  } catch(error) {
      console.log("something went wrong ")
  }
}

async function updateReading(id,readingData) {
  try {
    const response = await API.post(`readings/update/${id}`,readingData);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function deleteReading(id) {
  try {
    const response = await API.delete(`readings/delete/${id}`);
    return response.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function getWhatsappReadingsByBuildingAndUtility(id, utilityType, memberId) {
    try {
        const url = `readings/whatsapp/readingsByBuildingIdAndUtilityType?buildingId=${id}&utilityType=${utilityType}&memberId=${memberId}`;
        const encodedUrl = encodeURI(url);
        const response = await API.get(encodedUrl);
        return response?.data
    } catch(error) {
        console.log("something went wrong ")
    }
}


async function postWhatsappReading(reading) {
    try {
        const url = `readings/whatsapp/addReadingsByBuildingId`;
        const response = await API.post(url, reading);
        return response?.data
    } catch(error) {
        console.log("something went wrong ")
    }
}


export {
    getReading,
    getReadings,
    createReading,
    updateReading,
    deleteReading,
    getWhatsappReadingsByBuildingAndUtility,
    postWhatsappReading
}