import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { 
    Alert, 
    Table, 
    Card, 
    CardHeader, 
    CardBody, 
    Container, 
    Row, 
    Col, 
    Button } from "reactstrap";
import Loader from "components/loader/Loader";

import { 
    getMaintenancesByBuilding,
    selectMaintenanceStatus,
    selectMaintenanceError,
    disableReadingActions,
    postWhatsappMaintenanceByBuilding,
    selectMaintenanceBuildingDetails
} from "store/reducers/maintenanceSlice";


const MaintenanceWhatsapp = () => {
    const disableActions = useSelector(disableReadingActions);
    const error = useSelector(selectMaintenanceError);
    const buildingData = useSelector(selectMaintenanceBuildingDetails);
    const maintenanceStatus = useSelector(selectMaintenanceStatus);
    const buildingName = buildingData?.building ? buildingData.building.name : "";
    const maintenances =  buildingData?.maintenances ? buildingData.maintenances : [];

    // state objects for maintenance modal
    /*

    const [open, setOpen] = useState(false);
    const [selectedMaintenance, setSelectedMaintenance] = useState({});
    const [isEditAction, setIsEditAction] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    */
    
    //get search parameters
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const dispatch = useDispatch();

    useEffect(() => {
        // fetch building readings
        if (maintenanceStatus === 'idle') {
            if(params.get("buildingId") && params.get("memberId")){
                const getParams = {
                    buildingId: params.get("buildingId"),
                    memberId: params.get("memberId")
                }
                dispatch(getMaintenancesByBuilding(getParams));
            }
        }
    }, [maintenanceStatus]);

    const updateHandler = (isEdit, data) =>{
        /*
        handleOpen();
        setSelectedMaintenance(data);
        setIsEditAction(isEdit);
        */
    }

    return (
        <div>
            <div className="" style={{ height: '100px' }} ></div>
            <Container className="mt--5" fluid>
            <Row>
                {
                    error && (
                        <Col sm="12" xl={{ size: 8, offset: 2 }}>
                            <Alert color="danger" className="text-center"> {error} </Alert>
                        </Col>
                    )
                }
                <Col xl="12">
                    <Card className=" shadow overflow-hidden">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">  
                                <div className="col-12">
                                    <h2 className="mb-3 text-dark">Maintenance Requests</h2>
                                    <h4 className="mb-0 text-dark">Building: {buildingName}</h4>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table
                                className="align-items-center table-flush"
                                responsive
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th>Unit</th>
                                        <th>Date</th> 
                                        <th>New</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { 
                                    maintenances.length > 0 && maintenances.map((rd)=>{
                                        return(
                                        <tr key={rd._id}>
                                            <td> {rd.unit} </td>
                                            <td> {rd?.eventDate} </td>
                                            <td> 
                                                <div>
                                                    <Button
                                                        className="btn-danger"
                                                        onClick={() => {
                                                            if(rd.status.toLowerCase() !== "complete" && rd.status.toLowerCase() !== "cancelled"){
                                                                updateHandler(false, rd);
                                                            }
                                                        }}
                                                        outline
                                                        color={rd.status == "schedule" ? "danger" : (rd.status == "pending" ? "primary" : (rd.status=="complete" ? "success" : "default"))}
                                                        size="sm"
                                                    >
                                                        {rd.status}
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    }) 
                                }

                                { 
                                    maintenances.length == 0 && 
                                        (
                                        <tr>
                                            <td colSpan="3" className="text-center"> <h3> No Maintenance Requests Found </h3> </td>
                                        </tr>
                                        )
                                }

                                </tbody>

                                </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    );
}



const Properties = () => {
  const maintenanceStatus = useSelector(selectMaintenanceStatus);

  return (
    <>
      {maintenanceStatus === 'loading' ? <Loader /> : <MaintenanceWhatsapp />}
    </>
  );
};




export default Properties;


