import React from 'react';
import { PieChart, Pie, Cell,Label } from 'recharts';


function PieChartComp(props)
{
    const {COLORS} = props;

    return(
        <PieChart width={100} height={100}>
        <Pie
          data={props.data}
         
          innerRadius={40}
          outerRadius={45}
          fill="#8884d8"
          paddingAngle={4}
          dataKey="value"
        >
          {props.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label value={props.paid} position="center"/>
        </Pie>
   
      </PieChart>
    );
}

export default PieChartComp