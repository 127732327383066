import { useEffect,useState} from "react";
import { Container, Row, Col } from "reactstrap";
import RecentPaymentTable from './RecentPaymentTable';

const RecentPaymentSummary = (props) => {

    return (
        <div>
            <Row className="mt-5">
                <Col xl="12">
                    <RecentPaymentTable />
                </Col>
            </Row>
        </div>
    );
};


export default RecentPaymentSummary;
