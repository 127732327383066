import { useState, useEffect, useMemo } from "react";
import { useTable, usePagination } from "react-table";

// reactstrap components
import { Button, Card, CardHeader, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// core components
import PaymentForm from "components/Forms/PaymentForm";
import PaymentsTable from "components/Table/PaymentsTable";
import { paymentColumns } from "utils/paymentsColumns";
import SelectBuildingModal from "components/Modals/SelectBuildingModal";
import { fetchAllBalances} from "store/reducers/paymentsSlice";
import { selectPaymentsBalances,setAccessor,selectAccessor } from "store/reducers/paymentsSlice";
import { fetchAllBuildings } from "store/reducers/buildingsSlice";
import { useRowSelect } from "react-table/dist/react-table.development";
import IndeterminateCheckbox from "components/Forms/IndeterminateCheckbox";

const Payments = () => {
  const [Open, setOpen] = useState(false);
  const [OpenSelect, setOpenSelect] = useState(false);
  const [buildingName, setBuildingName] = useState("All");
  const [buildingId, setBuildingId] = useState("");

  const dispatch = useDispatch();

  const selectBalances = useSelector(selectPaymentsBalances);
  const accessor = useSelector(selectAccessor);

  const checkBuilding = ()=>{
    if(buildingId === ""){
      return selectBalances;
    }else {
      return selectBalances.filter((payment) => payment.buildingId === buildingId);
    }
  }
  
  const toggleFilter = (column)=>{
   const hiddenColumns = tableInstance.state.hiddenColumns
   const found = hiddenColumns.find(item=>item === column)
   if(found === undefined)tableInstance.setHiddenColumns([...hiddenColumns.filter(item=>item !== 'balance'),column])
   else tableInstance.setHiddenColumns([...hiddenColumns.filter(item=>item !== column),'balance'])
  }
  const data = useMemo(
    () =>
    checkBuilding(),
    [selectBalances, buildingId]
  );
  const columns = useMemo(() => paymentColumns, []);
  const initialState = { hiddenColumns: ['owedRent','owedUtilities','owedMaintenance'] };

  const tableInstance = useTable({ columns, initialState, data }, usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns =>[
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) =>(
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) =>(
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns
      ])
    }
    );

  const handleClose = async () => {
    setOpen(false);
    setOpenSelect(false);
    
  };

  const handleConfirm = async (id, name) => {
    if (id !== undefined) {
      setBuildingName(name);
      setBuildingId(id);
    } else {
      setBuildingName(`All`);
      setBuildingId("");
    }
    setOpenSelect(false);
  };

  useEffect(() => {
    const fetch = async () => {
        await dispatch(fetchAllBalances());
    };
    fetch();
    dispatch(fetchAllBuildings());
  }, []);
  


  return (
    <>
      <SelectBuildingModal
        modal={OpenSelect}
        closeModal={handleClose}
        title={"building"}
        confirmModal={handleConfirm}
      ></SelectBuildingModal>      
      <PaymentForm
        modal={Open}
        closeModal={handleClose}
        action={"add"}
        tenants={selectBalances.filter(
          (payment) => payment.buildingId === buildingId
        )}
        buildingId = {buildingId}
        
      ></PaymentForm>
      
      <div className="" style={{height:'200px'}} ></div>

      <Container style={{backgroundColor: '#f1f1f1'}} className="mt--7"  fluid>
        <Row >
          <div className="col">
            <Card className="shadow" style={{overflow:'scroll'}}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-2">
                    <h3 className="mb-0">{buildingName}</h3>
                  </div>
                  <div className="col-3">

                  </div>
                  <div className="col-7 text-right d-flex justify-content-between">
                      
                    
                    <span>
                    <div className="mr-2 d-block d-md-inline">
                      <i className="fa fa-filter mr-2"></i>
                      <Button color={tableInstance.state.hiddenColumns.find((item)=>item==='owedRent') === undefined? 'dark':'outline-dark'} size="sm"
                        onClick={()=>{toggleFilter('owedRent')}}
                      > 
                        <i className="fas fa-building mr-0 mr-md-2"></i>
                          <span className="d-none d-lg-inline">
                            Rent
                          </span>
                      </Button>
                      <Button color={tableInstance.state.hiddenColumns.find((item)=>item==='owedUtilities') === undefined? 'dark':'outline-dark'} size="sm"
                      onClick={()=>{toggleFilter('owedUtilities')}}
                      >
                      <i className="fas fa-shower mr-0 mr-md-2"></i>
                          <span className="d-none d-lg-inline">
                            Utility
                          </span>
                      </Button>
                      <Button color={tableInstance.state.hiddenColumns.find((item)=>item==='owedMaintenance') === undefined? 'dark':'outline-dark'} size="sm"
                      onClick={()=>{toggleFilter('owedMaintenance')}}    
                      >
                      <i className="fas fa-wrench mr-0 mr-md-2"></i>
                          <span className="d-none d-lg-inline">
                            Maintenance
                          </span>
                      </Button>
                      </div>
                      <span className="mt-2">
                      <Button
                        color="info"
                        onClick={() => {
                          setOpenSelect(true);
                        }}
                        size="sm"
                        className="btn-sm"
                      >
                        <i className="fas fa-sign-out-alt mr-0 mr-md-2"></i>
                        <span className="d-none d-lg-inline">
                          change building
                        </span>
                      </Button>
                      </span>
                    </span>
                    <span>
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={() => setOpen(true)}
                        size="sm"
                        className="btn-sm ml-2"
                        style={{
                          display: buildingName === `All` ? `none` : null,
                        }}
                      >
                        <i className="fas fa-plus mr-0 mr-md-2"></i>
                        <span className="d-none d-lg-inline">Add payment</span>
                      </Button>
                    </span>
                  </div>
                </Row>
              </CardHeader>
              <PaymentsTable tableInstance={tableInstance} />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Payments;