import React, { useState, useEffect,useMemo } from "react";
import { useDispatch } from "react-redux";
import { Table, Tooltip } from "reactstrap";
import { useTable, usePagination } from "react-table";

import "assets/css/tables.css";

const ChargesBar = (props) => {
    const { id, total, utilities, period } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    
    const toggle = () =>{
        setTooltipOpen(!tooltipOpen);
    }

    return (
        <span id={'Tooltip-' + id}>
            <div className="progress" style={{maxWidth: "100%", height: "20px"}}>
                {
                    utilities.map((item,index)=>{
                        const barWidth = ((item.count/total) * 100).toFixed();
                        return (
                            <div className="progress-bar"
                                style={{width: `${barWidth}%`, backgroundColor: item.color}}  key={index}>
                            </div>
                        )
                    })
                }
            </div>

            <Tooltip style={{backgroundColor: '#222e3c'}} placement="left" isOpen={tooltipOpen} target={'Tooltip-' + id} toggle={toggle}>
                {period}
                <ul style={{listStyle: 'none', margin: 0, padding: 0}}>
                {
                    utilities.map((item, index)=>{
                        return (
                            <li key={index} style={{
                                textAlign: 'center', 
                                fontSize: '12px',
                                }}>
                                <span style={{
                                    width: '10px', 
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: item.color,
                                    display: 'inline-block',
                                    marginRight: '3px'
                                    }}></span>
                                {item.name} - 

                                <span style={{
                                    width: '40px', 
                                    display: 'inline-block'                                    
                                    }}>{item.count}</span>
                            </li>
                        )
                    })
                }
                </ul>
                
            </Tooltip>
        </span>
    );
  }

export const buildingColumns = [
  
    {
      Header: "Building Name",
      accessor: "name",
    },
    {
      Header: "Total",
      accessor: "total",
    },
  
  ];

function MTable(props) {
  const [activeIndexState, setActiveIndex] = useState({ activeIndex: 0 });
  const dispatch = useDispatch();
  const  { data, clickable } = props;

  const columns = useMemo(() => buildingColumns, []);

  const tableInstance = useTable({ columns, data }, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
  } = tableInstance;
  const { pageIndex } = state;


  useEffect(() => {
      console.log("mtable ... ");
        setActiveIndex({ activeIndex: 0 });
  }, [data]);

  return (
    <>
      <Table
        {...getTableProps()}
        className="align-items-center table-flush"
        responsive
      >
        <thead className="thead-light2">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th scope="col" {...column.getHeaderProps()} style={{backgroundColor: '#EDF2F8'}}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row,index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
              onClick={() => {
                setActiveIndex({ activeIndex: index });
              }}
              className={`customRows`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
                <td>
                    <ChargesBar key={index} id={index} utilities={row.original["utilities"]} period={row.original["period"]} total={row.original["total"]}/>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center" style={{
		  color: '#8898aa',
		  backgroundColor: '#EDF2F8'
	  }}>
      </div>
    </>
  );
}

export default MTable;
