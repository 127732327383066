import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import UtilityTypeTable from "components/Table/UtilityTypeTable";
import UtilityTypeForm from 'components/Forms/UtilityTypeForm'
import Loader from "components/loader/Loader";
import {
    fetchAllUtilityTypes,
    selectUtilityTypes, 
    selectUtilityTypeStatus,
    utilitytypeError
} from "store/reducers/utilitytypesSlice";



const UtilityTypes = () => {
    const utilitytypes = useSelector(selectUtilityTypes);
    const error = useSelector(utilitytypeError);
    const headings2 = ["Name"];
    const [OpenUtilityType, setOpenUtilityType] = useState(false);
    const [OpenUnit, setOpenUnit] = useState(false);

    const handleClose = () => {
        setOpenUtilityType(false)
        setOpenUnit(false)
    }

    return (
        <div>
            <div className="" style={{ height: '200px' }} ></div>
            <Container className="mt--5" fluid>
            <UtilityTypeForm modal={OpenUtilityType} closeModal={handleClose} action={"create"}></UtilityTypeForm>
            
            <Row>
                {
                    error && (
                        <Col sm="12" xl={{ size: 8, offset: 2 }}>
                            <Alert color="danger" className="text-center"> {error} </Alert>
                        </Col>
                    )
                }
                <Col xl="12">
                    <Card className=" shadow overflow-hidden">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">  
                                <div className="col-6">
                                    <h3 className="mb-0 text-dark">Utility Types</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <Button
                                        color="primary"
                                        onClick={() => setOpenUtilityType(true)}
                                        size="sm"
                                        className="btn-sm ml-2"
                                    >
                                        <i className="fas fa-plus mr-0 mr-md-2"></i>
                                        <span className="d-none d-md-inline">Add Type</span>
                                    </Button>
                                </div>
                            </Row>
                        </CardHeader>
                        <UtilityTypeTable
                            headings={headings2}
                            data={utilitytypes}
                            actions={true}
                            clickable={true}
                        />
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    );
}



const Properties = () => {
  const utilitytypeStatus = useSelector(selectUtilityTypeStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (utilitytypeStatus === 'idle') {
      dispatch(fetchAllUtilityTypes());
    }
  }, [utilitytypeStatus]);

  return (
    <>
      {utilitytypeStatus === 'loading' ? <Loader /> : <UtilityTypes />}
    </>
  );
};




export default Properties;


