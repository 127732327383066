import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboardRevenues } from "../../services/revenueService";


export const fetchDashboardRevenues = createAsyncThunk("All/RevenuePayments", async (period) => {
    const response = await getDashboardRevenues(period);
    return response;
})


export const revenuesSlice = createSlice({
  name: "revenues",
  initialState: {
    value: [],
    status:'idle',
    error:null
  },
  reducers: {
    clearRevenuesState:(state,action)=>{
      state.value=[];
      state.status='idle'
  }
  },
  extraReducers(builder) {
    builder.addCase(fetchDashboardRevenues.pending, (state, action) => {

      state.status = "loading";
     
    }).addCase(fetchDashboardRevenues.fulfilled, (state, action) => {
        state.status = "complete";
        state.value = action.payload;
    })
  },
});


export const {clearRevenuesState} = revenuesSlice.actions

export const selectRevenues = (state) => state.value;

export const selectRevenuesStatus = state=>state.revenues.status


export default revenuesSlice.reducer;
