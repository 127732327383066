import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { login } from "../../../services/authService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from 'react-router-dom'

import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { getUser } from 'store/reducers/userSlice'
const Login = (props) => {

  const dispatch = useDispatch()
  let history = useHistory()
  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      let cred = {
        creds: {
          email: values.email,
          password: values.password,
        },
      };
      login(cred).then(res => {
          dispatch(getUser(res.data.user))
          history.push('/admin/index')

      }).catch((err)=>{
          setError(err.response.data.message)
      })


    },
  });

  const imgStyle = {
    height: 64,
  };

  const [eyeIcon, setEyeIcon] = useState(true);
  const [password, setPassword] = useState("password");

  const TogglePasswordVisibilty = () => {
    if (password === "password") {
      setPassword("text");
      setEyeIcon(false);
    } else {
      setPassword("password");
      setEyeIcon(true);
    }
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5 d-flex justify-content-center">
            <div>
              <img
                alt="Rentika logo"
                src={
                  require("../../../assets/img/brand/apple-touch-icon.png").default
                }
                style={imgStyle}
              />
              <h3
                className="mt-3 font-weight-bold"
                style={{ textAlign: "center" }}
              >
                Login
              </h3>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {
              error != ""?
            <div className="alert alert-danger" role="alert">
                {error}
            </div>:null
            }
            <Form role="form" onSubmit={formik.handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="new-email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </InputGroup>
                {formik.touched.email && formik.errors.email ? (
                  <small className="text-danger">{formik.errors.email}</small>
                ) : null}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={password}
                    name="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                    <i
                      onClick={TogglePasswordVisibilty}
                      className={`fa ${eyeIcon ? "fa-eye-slash" : "fa-eye"}`}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {formik.touched.password && formik.errors.password ? (
                  <small className="text-danger">{formik.errors.password}</small>
                ) : null}
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link
              className="text-light"
              to="/auth/forgotPassword"
            >
              <small>Forgot password?</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link
              className="text-light"
              to="/auth/register"
            >
              <small>Create new account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default withRouter(Login);
