
import {useEffect,useRef} from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";
import {toast, ToastContainer} from "react-toastify";
import {selectMaintenanceError} from "../store/reducers/maintenanceSlice";
import {selectPaymentError} from "../store/reducers/paymentsSlice";

const Management = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();
  const PaymentError = useSelector(selectPaymentError)
  const MaintenanceError = useSelector(selectMaintenanceError)
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
  const notify = (type,message) => {
      toast[type](message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
      });
  }


  useEffect(()=>{if(PaymentError !== null){notify("error",PaymentError)}},[PaymentError])
  useEffect(()=>{if(MaintenanceError !== null){notify("error",MaintenanceError)}},[MaintenanceError])
    const getRoutes = (routes) => {
        let formattedRoutes = [];
        routes.map((prop, key) =>{
            formattedRoutes.push(prop)
            if(prop.children){
                prop.children.map((childProp, key) =>{
                    formattedRoutes.push(childProp)
                })
            }
        })

        return formattedRoutes.map((prop, key) => {
            // check if parent has path as # to avoid duplicate routes
            if (prop.layout === "/management" && prop.path !== "#") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

  return (
    <>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
        />
      <div className="main-content" ref={mainContent}>
        <Switch>
          {getRoutes(routes)}
          
          <Redirect from="*" to="/management/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Management;
