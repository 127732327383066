
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/rentika-systems.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import InfoLayout from "layouts/Info.js";
import ManagementLayout from "layouts/Management.js";
import store from './store/store'
import { Provider } from "react-redux";
import {PrivateRoute} from 'utils/authChecks'

import 'react-toastify/dist/ReactToastify.css'




ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
    <Switch>
      
      <PrivateRoute component={AdminLayout} path="/admin" />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/info" render={(props) => <InfoLayout {...props} />} />
      <Route path="/management" render={(props) => <ManagementLayout {...props} />} />
      <Redirect from="/" to="/admin/index" />
    
    </Switch>
  </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

