import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup, Input, InputGroup
} from "reactstrap";
import axios from 'axios';
import { createSmsTopup } from 'services/smstopupsService'
import { getUserProfile } from 'services/authService'
import loader from 'assets/img/brand/rentika-loading.gif'
import { PESAPAL_URL} from "../../utils/api";

const TopUpForm = (props) => {
    const dispatch = useDispatch();
    const { unit, modal, user, closeModal, action } = props;
    const toggle = () => closeModal();
    
    const [amount, setAmount] = useState("1.50");
    const [units, setUnits] = useState("1");
    const [phoneNumber, setPhoneNumber] = useState(user.phone);
    const [email, setEmail] = useState(user.email);
    const [name, setName] = useState(user.userName);
    const [reference, setReference] = useState("");
    const [description, setDescription] = useState("SMS Top Up");
    const [loading, setLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [pesapalContent, setPesapalContent] = useState("");

    // reset the fields everytime the modal is opened.
    useEffect(() => {
        if(modal){
            setAmount("1.50");
            setUnits("1");
            setPhoneNumber(user.phone);
            setPesapalContent("");
            setLoading(false)
            // set profile
            const getProfile = async () => {
                const response = await getUserProfile() 
                console.log("profile ", response)
                setEmail(response.email)
                setPhoneNumber(response.phone)
                setName(response.userName)
            }
            getProfile()
            
        }
      }, [modal]);

    const handleChange = (event, name) => {
        const value = name.toLowerCase() === "status" ? event.target.checked : event.target.value;
        switch (name.toLowerCase()) {
            case "phonenumber": {
                setPhoneNumber(value);
                break;
            }
            case "amount": {
                setAmount(value)
                // calculate units
                const units = value / 1.50;
                const removedDecimal = Math.trunc(units);
                setUnits(removedDecimal);
                break;
            }
            default:
                break;
        }
    };

    const submitPayment = async (event) =>{
        event.preventDefault();
        const data = new FormData();
        data.append("first_name", name);
        data.append("last_name", ""); 
        data.append("email", email);
        data.append("phone_number", phoneNumber);
        data.append("currency", "KES");
        data.append("amount", amount);
        data.append("description", description);

        const url = PESAPAL_URL;
        const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'multipart/form-data'
            }
        }

        // create a post first then send it
        const topup = {
            phone: phoneNumber,
            amount: amount,
            units: units,
            pesapalMerchantReference: "",
            pesapalTransactionTrackingId: "",
            pesapalNotificationType: "",
            status: "INITIATED"
        }

        setLoading(true);
        const response = await createSmsTopup(topup);
        if(response._id){
            data.append("reference", response._id);
            axios.post(url, data, config)
                .then(response => {
                    const json = response.data;
                    console.log(json)
                    setPesapalContent(json);
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setLoading(false);
                    setPesapalContent("");
                    setLoading(false);
                });
        }
    }

    return (
        <>
            <Modal isOpen={modal} size="md" toggle={toggle} className="modal-dialog-centered">
                <ModalHeader className="text-center d-block">
                    Sms Top Up
                </ModalHeader>
                <ModalBody>
                    {
                        !loading && pesapalContent === "" && (
                            <Form role="form">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                    >
                                        Phone Number
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                            placeholder="Enter Phone Number"
                                            type={"text"}
                                            value={phoneNumber}
                                            onChange={(event) => handleChange(event, "phonenumber")}
                                            
                                        >
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                    >
                                        Amount
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                            placeholder="Enter Amount"
                                            type={"number"}
                                            value={amount}
                                            onChange={(event) => handleChange(event, "amount")}
                                            
                                        >
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                    >
                                        Sms Units (Kshs. 1.50 = 1 sms unit)
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                            placeholder="Enter Units"
                                            type={"text"}
                                            value={units}
                                            disabled
                                        >
                                        </Input>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        )
                    }

                    {
                        loading && (
                        <>
                            <div className="d-flex justify-content-center align-items-center" style={{height: "30vh !important"}}>
                                <img src={loader} />
                            </div>
                        </>
                        )
                    }

                    {
                        !loading && pesapalContent !== "" && (
                            <>
                                <div style={{width: '100%', marginTop: '10px'}} dangerouslySetInnerHTML={ {__html: pesapalContent} } />
                            </>
                        )
                    }
                    
                </ModalBody>
                <ModalFooter>
                    {
                        pesapalContent === "" && (
                            <>
                                <Button
                                    color="primary"
                                    type="submit"
                                    onClick={submitPayment}
                                    disabled={disableSubmit}
                                >
                                    Top Up
                                </Button>{" "}
                            </>
                        )
                    }
                   
                    <Button color="secondary" onClick={toggle}> Cancel </Button>
                    
                </ModalFooter>
            </Modal>
        </>
    );
};

export default TopUpForm;
