import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import {
    getReadings,
    getWhatsappReadingsByBuildingAndUtility,
    postWhatsappReading,
    createReading,
    updateReading,
    deleteReading } from 'services/readingsService'

import { notify } from 'utils/sharedFunctions'

export const fetchAllReadings = createAsyncThunk('All/readings', async () => {
    const response = await getReadings()
    return response;
});

export const addReading = createAsyncThunk('add/reading', async (reading) => {
    const response = await createReading(reading)
    return response;
});

export const updateReadingReducer = createAsyncThunk('update/reading', async (reading) => {
    const { id, readingData } = reading
    const response = await updateReading(id, readingData)
    return response;
});

export const removeReading = createAsyncThunk('delete/reading', async (readingId) => {
    const response = await deleteReading(readingId)
    return response;
});


export const getReadingByBuildingAndUtility = createAsyncThunk('whatsapp/getreadingByBuildingId', async (reading) => {
    const { buildingId, utilityType, memberId } = reading;
    const response = await getWhatsappReadingsByBuildingAndUtility(buildingId, utilityType, memberId);
    return response;
});


export const postWhatsappReadingByBuilding = createAsyncThunk('whatsapp/postreadingByBuildingId', async (reading) => {
    const response = await postWhatsappReading(reading);
    return response;
});


export const readingsSlice = createSlice({
    name: 'readings',
    initialState:{
        value:[],
        readingDetails:{},
        status:'idle',
        error: '',
        disableActions: false
    },
    reducers: {
        clearReadingsState:(state,action)=>{
          state.value=[]
          state.readingDetails = {}
          state.status = 'idle'
          state.disableActions = false
      }
    },
    extraReducers(builder){
        builder
        .addCase(getReadingByBuildingAndUtility.pending, (state, action) => {
            state.status = "loading";
          })
        .addCase(getReadingByBuildingAndUtility.fulfilled, (state, action)=>{
            state.status = "complete"
            state.readingDetails = action.payload?.data;

            if(action.payload.responseCode == "01"){
                state.readingDetails = {}
                state.error = action.payload.responseDescription
            }
        })
        .addCase(fetchAllReadings.pending, (state, action) => {
            state.status = "loading";
          })
        .addCase(fetchAllReadings.fulfilled, (state, action)=>{
            state.status = "complete"
            state.value = action.payload?.data
            if(action.payload.responseCode == "01"){
                state.value = []
                state.error = action.payload.responseDescription
            }
        })
        .addCase(addReading.fulfilled, (state, action)=>{
            if(action.payload == undefined || action.payload.responseCode == "01"){
                state.error = action.payload.responseDescription;
                notify("error", "Error Adding Team Member!");
            }else{
                state.value.push(action.payload.data);
                state.error = '';
                notify("success", "Team Member added Successfully!");
            }
        })
        .addCase(updateReadingReducer.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                const findIndex = state.value.findIndex(item=>item._id === action.payload.data._id)
                state.value[findIndex]=action.payload.data
                notify("success", "Team Member updated Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Updating Team Member!");
            }

            const findIndex = state.value.findIndex(item=>item._id === action.payload._id)
            state.value[findIndex]=action.payload
        })
        .addCase(removeReading.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                state.value = state.value.filter(reading=>reading._id !==action.payload.data)
                notify("success", "Team Member removed Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Removing Team Member!");
            }
        })
        .addCase(postWhatsappReadingByBuilding.fulfilled, (state, action)=>{
            if(action.payload == undefined || action.payload.responseCode == "01"){
                state.error = action.payload.responseDescription;
                notify("error", "Error Adding Reading!");
            }else{
                state.value.push(action.payload.data);
                state.error = '';
                notify("success", "Reading added Successfully!");
                state.status = "idle";
                state.disableActions = true;
            }
        })
    }
})

export const { clearReadingsState } = readingsSlice.actions



export const selectReadings = state => state.readings.value;
export const selectReadingStatus = state => state.readings.status;
export const selectReadingDetails = state => state.readings.readingDetails;
export const selectedReading = state => state.readings.readingDetails;
export const readingError = state => state.readings.error;
export const disableReadingActions = state => state.readings.disableActions;

export default readingsSlice.reducer;