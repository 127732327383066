import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import { actions } from 'react-table/dist/react-table.development'
import {
    getUtilityTypes,
    getUtilityType,
    createUtilityType,
    updateUtilityType,
    deleteUtilityType } from 'services/utilityTypesService'

import { notify } from 'utils/sharedFunctions'

export const fetchAllUtilityTypes = createAsyncThunk('All/utilitytypes', async () => {
    const response = await getUtilityTypes()
    return response
})

export const addUtilityType = createAsyncThunk('add/utilitytype', async (utilitytype) => {
    const response = await createUtilityType(utilitytype)
    return response
  })
export const updateUtilityTypeReducer = createAsyncThunk('update/utilitytype', async (utilitytype) => {

    const {id,utilitytypeData} = utilitytype
    const response = await updateUtilityType(id, utilitytypeData)
    return response
  })
export const removeUtilityType = createAsyncThunk('delete/utilitytype', async (utilitytypeId) => {
    const response = await deleteUtilityType(utilitytypeId)
    return response
  })



export const utilitytypesSlice = createSlice({
    name: 'utilitytypes',
    initialState:{
        value:[],
        utilitytypeDetails:{},
        status:'idle',
        error: ''
    },
    reducers: {
        clearUtilityTypesState:(state,action)=>{
          state.value=[]
          state.utilitytypeDetails = {}
          state.status='idle'
      }
    },
    extraReducers(builder){
        builder
        .addCase(fetchAllUtilityTypes.pending, (state, action) => {
            state.status = "loading";
          })
        .addCase(fetchAllUtilityTypes.fulfilled, (state, action)=>{
            state.status = "complete"
            state.value = action.payload?.data
            if(action.payload.responseCode == "01"){
                state.value = []
                state.error = action.payload.responseDescription
            }
        })
        .addCase(addUtilityType.fulfilled, (state, action)=>{
            if(action.payload == undefined || action.payload.responseCode == "01"){
                state.error = action.payload.responseDescription;
                notify("error", "Error Adding Utility Type!");
            }else{
                state.value.push(action.payload.data);
                state.error = '';
                notify("success", "Utility Type added Successfully!");
            }
        })
        .addCase(updateUtilityTypeReducer.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                const findIndex = state.value.findIndex(item=>item._id === action.payload.data._id)
                state.value[findIndex]=action.payload.data
                notify("success", "Utility Type updated Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Updating Utility Type!");
            }

            const findIndex = state.value.findIndex(item=>item._id === action.payload._id)
            state.value[findIndex]=action.payload
        })
        .addCase(removeUtilityType.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                state.value = state.value.filter(utilitytype=>utilitytype._id !==action.payload.data)
                notify("success", "Utility Type removed Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Removing Utility Type!");
            }
        })
    }
})

export const { clearUtilityTypesState } = utilitytypesSlice.actions



export const selectUtilityTypes = state => state.utilitytypes.value;
export const selectUtilityTypeStatus = state => state.utilitytypes.status;
export const selectUtilityTypeDetails = state => state.utilitytypes.utilitytypeDetails;
export const utilitytypeError = state => state.utilitytypes.error;

export default utilitytypesSlice.reducer;