import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { Table, Button } from "reactstrap";
import { useTable, usePagination } from "react-table";

import DeleteModal from "components/Modals/DeleteModal";
import AgentForm from "components/Forms/AgentForm";
import { agentColumns } from 'utils/agentColumns'
import { 
    fetchAllAgents,
    selectAgents,
    selectAgentStatus,
    removeAgent, 
    updateAgentReducer
 } from "store/reducers/agentsSlice";
import "assets/css/tables.css";

import { ToastContainer } from "react-toastify";

function AgentTable(props) {
  const [activeIndexState, setActiveIndex] = useState({ activeIndex: 0 });
  const [OpenDelete, setOpenDelete] = useState(false);
  const [OpenUpdate, setOpenUpdate] = useState(false);
  const dispatch = useDispatch();
  const { data, clickable } = props;
  const agents = useSelector(selectAgents)
  const agentStatus = useSelector(selectAgentStatus)
  const [agent, setAgent] = useState({});

  const columns = useMemo(() => agentColumns, []);

  const tableInstance = useTable({ columns, data }, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
  } = tableInstance;
  const { pageIndex } = state;


  const handleClose = (modalState) => {
    modalState === undefined ? setOpenUpdate() : setOpenDelete(modalState);
  };
  const handleConfirm = async (id, agentData) => {
    if (agentData === undefined) {
      dispatch(removeAgent(id));
    } else {
      let agentObject = { id: id, agentData: agentData }
      dispatch(updateAgentReducer(agentObject))
    }
  };
  useEffect(async () => {
    if (agentStatus === 'idle') {
      await dispatch(fetchAllAgents());
    }
  }, [agents]);
  return (
    <>

        <ToastContainer />

      <AgentForm modal={OpenUpdate} closeModal={handleClose} action={"edit"} agent={agent} confirmModal={handleConfirm}></AgentForm>

      <DeleteModal modal={OpenDelete} closeModal={handleClose} title={"agent"} item={agent} confirmModal={handleConfirm}></DeleteModal>

      <Table
        {...getTableProps()}
        className="align-items-center table-flush"
        responsive
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th scope="col" {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th>buildings</th>
              <th>actions</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
                onClick={() => {
                  console.log("clicked index => ", index );
                }}
                className={`customRows ${activeIndexState.activeIndex === index && clickable ? "clicked" : null
                  } toggler${index + 1}`}
                key={index}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}

                <td>
                    {
                        row.original.manageAllBuildings == "TRUE" ? "All" : (
                            <ul style={{paddingLeft: 0}}>
                                {row.original?.buildings.map((building, ind) => {
                                    return (
                                        <li key={"building_"+ind}> {building.name}</li>
                                    );
                                })}
                            </ul>
                        )
                    }
                  
                </td>

                <td>
                  <Button color="primary" size="sm" onClick={() => {
                    setOpenUpdate(true);
                    setAgent(row.original);
                  }}
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </Button>
                  <Button className="btn-danger" style={{ color: "white" }} size="sm"
                    onClick={() => {
                      setOpenDelete(true);
                      setAgent(row.original);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center mb-3">
        <span className="mr-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </div>


    </>
  );
}


export default AgentTable;
