import { Table, Button } from "reactstrap";
import { useEffect } from "react";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaintenanceScheduleModal from 'components/Modals/MaintenanceScheduleModal';


const useStyles = makeStyles({
  customTable: {
      // padding: "200px 200px 60px 160px",
      // background: "yellow"
  },
});

function MaintenanceTable(props) {
  const classes = useStyles();
  const { tableInstance, item, returnData } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
  } = tableInstance;
  const [open, setOpen] = React.useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = React.useState({});
  const [isEditAction, setIsEditAction] = React.useState(false);

  useEffect(() => {
    returnData && returnData(rows)
  }, [rows]);

const {pageIndex} = state
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);


const updateHandler = (isEdit, data) =>{
    handleOpen();
    setSelectedMaintenance(data);
    setIsEditAction(isEdit);
}

//  let dd = "http://localhost:7877/api/download/61cab6b572c37c13d8fed1a7"


function downloadPDF(pdf) {
  console.log('pdf', pdf)
  let link = `http://localhost:7877/api/download/${pdf}`
  console.log("link", link)
  const linkSource = link;
  const downloadLink = document.createElement("a");
  const fileName = "New File.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}


  return (
    <>
        <MaintenanceScheduleModal modal={open} isEditAction={isEditAction} closeModal={handleClose} selectedMaintenance={selectedMaintenance} action={"Schedule Maintenance"}></MaintenanceScheduleModal>
        <Table
            {...getTableProps()}
            classes={{root: classes.customTable}}
            className="align-items-center table-flush"
            responsive
            
        >
            <thead  className="thead-light">
                {headerGroups.map((headerGroup) => (
                    <tr style={{fontFamily: "Open Sans, sans-serif",
                    fontSize: "0.7rem",
                    color: "#8898aa",
                    fontWeight: "350",
                    lineHeight: "2",
                    textAlign: "left",
                    borderTop:"0.5px solid #F0F0F0",
                    background:"#f8f9fe"
                    }} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th style={{ padding:"0.5rem", paddingLeft:"1rem"}} scope="col" {...column.getHeaderProps()}>
                            {column.render("Header")}
                        </th>
                    ))}
                        <th>status</th>
                        <th>actions</th>
                    </tr>
                ))}
            </thead>
            <tbody  {...getTableBodyProps()}>
            { page.map((row) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}
                        style={{cursor:"pointer", borderTop:"0.2px solid #F0F0F0",
                        borderWidth:"0.2px", 
                        lineHeight:"90px",
                        fontFamily: "Open Sans, sans-serif",
                        fontSize: "0.75rem",
                        color: "#525f7f",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#525f7f",
                        textAlign: "left"
                        }}
                        >
                        {row.cells.map((cell) => {
                            return (
                                <td style={{ padding:"1rem"}} {...cell.getCellProps()}> {cell.render("Cell")}  </td>
                            );
                        })}
                        <td>
                            <Button
                                className="btn-danger"
                                onClick={() => {
                                    if(row.original.status.toLowerCase() !== "complete" && row.original.status.toLowerCase() !== "cancelled"){
                                        updateHandler(false, row.original);
                                    }
                                }}
                                outline
                                color={row.original.status == "schedule" ? "danger" : (row.original.status == "pending" ? "primary" : (row.original.status=="complete" ? "success" : "default"))}
                                size="sm"
                            >
                                {row.original.status}
                            </Button>
                        </td>
                        <td>
                            
                            <Button
                                color="primary"
                                disabled={row.original.status == "schedule"}
                                onClick={() => {
                                    updateHandler(true, row.original);
                                }}
                                size="sm"
                            >
                                <i className="fas fa-pencil-alt"></i>
                            </Button>
                            
                            {/* <Button
                                className="btn-danger"
                                style={{ color: "white" }}
                                onClick={() => {
                                    // setOpenDelete(true);
                                    // setBuilding(row.original);
                                }}
                                size="sm"
                            >
                                <i className="fas fa-trash-alt"></i>
                            </Button> */}
                        </td>
                    </tr>
                );

            })}
            </tbody>
        </Table>
        <div className="text-center mb-5">
            <span className="mr-2">
                Page {' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
            </span>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
            </Button>
        </div>
    </>
  );
}

export default MaintenanceTable;
