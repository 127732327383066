import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import { deleteBuilding } from 'services/buildingsService';
import {getBuildings,getBuildingsPerformance,getBuilding,createBuilding,updateBuilding} from 'services/buildingsService';
import {updateUnit,createUnit,deleteUnit} from 'services/unitService';


export const fetchAllBuildings = createAsyncThunk('All/buildings', async () => {
    const response = await getBuildings()
    return response
});

export const fetchAllBuildingsPerformance = createAsyncThunk('All/buildings/Performance', async () => {
    const response = await getBuildingsPerformance()
    return response
});

export const addBuilding = createAsyncThunk('add/building', async (building) => {
    const response = await createBuilding(building)
    return response
});

export const addUnit = createAsyncThunk('add/unit', async (unit) => {
    const {buildingId,unitData} = unit
    const response = await await createUnit(buildingId, unitData);
    return {...response,buildingId}
});

export const updateBuildingReducer = createAsyncThunk('update/building', async (building) => {
    const {id,buildingData} = building
    const response = await updateBuilding(id, buildingData)
    return response
});

export const getBuildingById = createAsyncThunk('get/building', async (buildingId) => {
    const response = await getBuilding(buildingId)
    return response
});

export const removeBuilding = createAsyncThunk('delete/building', async (buildingId) => {
    const response = await deleteBuilding(buildingId)
    return response
});

export const removeUnit = createAsyncThunk('delete/unit', async (Id) => {
    const {buildingId,unitId} = Id
    const response = await deleteUnit(buildingId,unitId)
    return response
});

export const updateUnitReducer = createAsyncThunk('update/unit', async (unit) => {

    const {buildingId,unitId,value} = unit
    const response = await updateUnit(buildingId, unitId,value)
    return {response:response,unitId:unitId}
});



export const buildingsSlice = createSlice({
    name: 'buildings',
    initialState:{
        value:[],
        units:[],
        buildingDetails:{},
        building:{},
        status:'idle',
        error:null
    },
    reducers: {

        getUnits:(state,action)=>{
            let building = state.value.find((building)=>building._id===action.payload)
            state.units = building?.units
            state.buildingDetails = building
        },
        clearBuildingsState:(state,action)=>{
          state.value=[]
          state.units=[]
          state.buildingDetails = {}
          state.building = {}
          state.status='idle'
      }
    },
    extraReducers(builder){
        builder
        .addCase(getBuildingById.pending, (state, action) => {
            state.status = "loading";
          })
        .addCase(getBuildingById.fulfilled, (state, action)=>{
            state.status = "complete"
            state.building = action.payload
        })
        .addCase(fetchAllBuildings.pending, (state, action) => {
            state.status = "loading";
          })
        .addCase(fetchAllBuildings.fulfilled, (state, action)=>{
            state.status = "complete"
            state.value = action.payload
        })
        .addCase(fetchAllBuildingsPerformance.fulfilled, (state, action)=>{
            state.status = "complete"
            state.value = action.payload
        })
        .addCase(addBuilding.fulfilled, (state, action)=>{
            if(action.payload != undefined)state.value.push(action.payload)
        })
        .addCase(addUnit.fulfilled, (state, action)=>{
            state.units = action.payload.units
            let buildings = state.value.map((building)=>{
                if(building._id === action.payload.buildingId){
                    building.units = state.units
                }
                return building;
            })
            state.value = buildings
        })
        .addCase(updateBuildingReducer.fulfilled, (state, action)=>{
            const findIndex = state.value.findIndex(item=>item._id === action.payload._id)
            state.value[findIndex]=action.payload
        })
        .addCase(removeBuilding.fulfilled, (state, action)=>{
            state.value = state.value.filter(building=>building._id !==action.payload)
            if(state.buildingDetails._id === action.payload){
                state.units = []
            }
        })
        .addCase(removeUnit.fulfilled, (state, action)=>{
            state.units = state.units.filter(unit=>unit._id !==action.payload)
        })
        .addCase(updateUnitReducer.fulfilled, (state, action)=>{
            const unit = action.payload.response.units.find((unit)=>unit._id===action.payload.unitId)
            const findIndex = state.units.findIndex(item=>item._id === action.payload.unitId)
            state.units[findIndex]=unit
        })
       
    }
})

export const { getUnits, clearBuildingsState } = buildingsSlice.actions;


export const selectBuildings = state => state.buildings.value;
export const selectBuildingStatus = state => state.buildings.status;
export const selectUnits = state => state.buildings.units;
export const selectBuildingDetails = state => state.buildings.buildingDetails;
export const selectedBuildingById = state => state.buildings.building;

export default buildingsSlice.reducer;