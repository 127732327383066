import { useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { selectBuildings } from "store/reducers/buildingsSlice";
import 'assets/scss/custom/modalStyles.scss'

const SelectBuildingModal = (props) => {
  const buildings = useSelector(selectBuildings);
  const { modal, closeModal, title, confirmModal } = props;
  const toggle = () => closeModal(!modal);

  const buildingList = buildings.map((item, index) => {
    return (

        <li
        className="border-bottom p-2"
          onClick={() => {
            confirmModal(item["_id"],item["name"]);
          }}
          key={index}
          style={{
              listStyle:"none",
              textAlign:"left"
          }}
        >
          {item["name"]}
        </li>
    );
  });
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader className="text-center d-block">
          Select {title}
        </ModalHeader>
        <ModalBody className="text-center d-block">

          <ul>
          <li
        className="border-bottom p-2"
          onClick={() => {
            confirmModal();
          }}
          style={{
              listStyle:"none",
              textAlign:"left"
          }}
        >
          All
        </li>
              {buildingList}</ul>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};

export default SelectBuildingModal;
