import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import { actions } from 'react-table/dist/react-table.development'
import {
    getAgents,
    getAgent,
    createAgent,
    updateAgent,
    deleteAgent } from 'services/agentsService'

import { notify } from 'utils/sharedFunctions'

export const fetchAllAgents = createAsyncThunk('All/agents', async () => {
    const response = await getAgents()
    return response;
});

export const addAgent = createAsyncThunk('add/agent', async (agent) => {
    const response = await createAgent(agent)
    return response;
});

export const updateAgentReducer = createAsyncThunk('update/agent', async (agent) => {
    const { id, agentData } = agent
    const response = await updateAgent(id, agentData)
    return response;
});

export const removeAgent = createAsyncThunk('delete/agent', async (agentId) => {
    const response = await deleteAgent(agentId)
    return response;
});



export const agentsSlice = createSlice({
    name: 'agents',
    initialState:{
        value:[],
        agentDetails:{},
        status:'idle',
        error: ''
    },
    reducers: {
        clearAgentsState:(state,action)=>{
          state.value=[]
          state.agentDetails = {}
          state.status='idle'
      }
    },
    extraReducers(builder){
        builder
        .addCase(fetchAllAgents.pending, (state, action) => {
            state.status = "loading";
          })
        .addCase(fetchAllAgents.fulfilled, (state, action)=>{
            state.status = "complete"
            state.value = action.payload?.data
            if(action.payload.responseCode == "01"){
                state.value = []
                state.error = action.payload.responseDescription
            }
        })
        .addCase(addAgent.fulfilled, (state, action)=>{
            if(action.payload == undefined || action.payload.responseCode == "01"){
                state.error = action.payload.responseDescription;
                notify("error", "Error Adding Team Member!");
            }else{
                state.value.push(action.payload.data);
                state.error = '';
                notify("success", "Team Member added Successfully!");
            }
        })
        .addCase(updateAgentReducer.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                const findIndex = state.value.findIndex(item=>item._id === action.payload.data._id)
                state.value[findIndex]=action.payload.data
                notify("success", "Team Member updated Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Updating Team Member!");
            }

            const findIndex = state.value.findIndex(item=>item._id === action.payload._id)
            state.value[findIndex]=action.payload
        })
        .addCase(removeAgent.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                state.value = state.value.filter(agent=>agent._id !==action.payload.data)
                notify("success", "Team Member removed Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Removing Team Member!");
            }
        })
    }
})

export const { clearAgentsState } = agentsSlice.actions



export const selectAgents = state => state.agents.value;
export const selectAgentStatus = state => state.agents.status;
export const selectAgentDetails = state => state.agents.agentDetails;
export const agentError = state => state.agents.error;

export default agentsSlice.reducer;