export const tenantColumns = [
  
  {
    Header: "Name",
    accessor: "fullName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  // {
  //   Header: "Gender",
  //   accessor: "gender",
  // }

];
