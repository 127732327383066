import { useEffect, useState} from "react";
import {useSelector} from "react-redux"
import { Card, CardBody, CardHeader, Row, Input } from "reactstrap"
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { selectBuildings} from "store/reducers/buildingsSlice"
import './Maintenance.css'

 const MaintenanceChart = () => {

    const [selectedType, setSelectedType] = useState('');
    const [Options, setOptions] = useState([]);
    const [data, setData] = useState([]);
    const buildings = useSelector(selectBuildings)

    const COLORS = ['#2a7be4', '#B6C2D2'];
    const createData = (building)=>{
        //calculate the number of occupied and vacant units in the selected building
        let occupied = 0;
        let vacant = 0;
        building?.units.map(unit=>{
            if(unit.isOccupied === true){
                occupied+=1
            }else vacant+=1
        })
        const data = [
        { name: 'Occupied', value: occupied },
        { name: 'Vacant', value: vacant },
        ]
        return data
    }
    useEffect(() => {
        let options = []
        buildings.map((building,index)=>{
            options.push(<option value={building._id} key={index}>{building.name}</option>)
        })
        setOptions(options)
        //set first building to default if there exists buildings
        if(buildings.length > 0){
            setData(createData(buildings[0]))
        }
      }, [buildings]);


    const handleSelect = (event) => {
        const value = event.target.value;
        setSelectedType(value);
        const building = buildings.find(({_id})=>_id === value)
        setData(createData(building))
        
    };



    return (
        <Card className="card-stats mb-4 mb-xl-0 custom-card ">
            <CardHeader className="border-0" style={{backgroundColor: '#F9FAFD'}}>
                <Row className="align-items-center">
                    <div className="col-8">
                        <h3 className="mb-0 text-dark">Occupancy Rate</h3>
                    </div>
                    <div className="col-4 text-right">
                        <Input
                            id="exampleSelect"
                            name="select"
                            bsSize="sm"
                            type="select"
                            value={selectedType}
                            onChange={handleSelect}
                            >
                            <option>Select Building</option>
                            {Options}
                            </Input>
                    </div>
                </Row>
            </CardHeader>
            <CardBody>
                <ResponsiveContainer width={'100%'} height={300}>
                    <PieChart height={200}>
                        <Pie data={data} 
                            cx="50%" 
                            cy="50%" 
                            paddingAngle={5} 
                            innerRadius={55} 
                            outerRadius={80} 
                            label>
                            {
                            data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                            ))
                            }
                        </Pie>
                        <Legend verticalAlign="bottom" height={20} iconType='circle'/>
                    </PieChart>
                </ResponsiveContainer>
            </CardBody>
        </Card>
    )
}

export default MaintenanceChart;