import { useState, useEffect, useMemo } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useTable, useFilters, usePagination } from "react-table";
// reactstrap components
import { Card, CardHeader, Container, Row } from "reactstrap";
import { CSVLink } from "react-csv";
// core components
import { getAllPayments } from "services/paymentsService";
import { selectPayments,fetchAllPayments } from "store/reducers/paymentsSlice";
import ReportTable from "components/Table/ReportTable"


import { reportColumns } from "utils/reportColumns";
import { DefaultColumnFilter } from "utils/DefaultColumnFilter";

const Reports = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [rows, setRows] = useState([]);
  const payments = useSelector(selectPayments)
  const dispatch = useDispatch();
  const data = useMemo(() => payments, [payments]);
  const columns = useMemo(() => reportColumns, []);
  
    const defaultColumn = useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );
  const initialState = { hiddenColumns: ['tenantId', '_id'] };
  const tableInstance = useTable({ columns, data, initialState, defaultColumn}, useFilters,usePagination);
  

  let tableData = () => {
    let data = rows.map((item) => {
      return item.original;
    });
    return data;
  };
  const returnRow = (row)=>{
    setRows(row)
  }
  useEffect(() => {
      dispatch(fetchAllPayments())
  }, []);

  return (
    <>
      {/* Page content */}
      <div className="" style={{height:'200px'}} ></div>

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow"  style={{overflow:'scroll'}} >
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-4">
                    <h3 className="mb-0">Reports</h3>
                  </div>
                  <div className="col-4"></div>
                  <div className="col-4 text-right">
                    <span>
                      <CSVLink
                        data={payments}
                        filename={"Rent Payments-Rentika.csv"}
                        className="btn-success btn-sm btn"
                      >
                        <i className="fas fa-download  mr-0 mr-md-2"></i>
                        <span className="d-none d-md-inline">Download</span>
                      </CSVLink>
                    </span>
                    <span>
                     
                    </span>
                  </div>
                </Row>
              </CardHeader>
              <ReportTable item={false} tableInstance={tableInstance} returnData={returnRow}/>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Reports;
