
export function dateBetweenFilterFn(rows, id, filterValues) {
  let year = filterValues[0] ? filterValues[0] : undefined
  let month = filterValues[1] ? filterValues[1] : undefined
  
  if(year || month){

    return rows.filter(r => {
      let date = new Date(r.values[id])
      let y = date.getFullYear()
      let m = date.toLocaleString('default', { month: 'long' })
      
      if(year && month){
        if(y==year && m==month)return r
        if(year==='All' && month==m)return r
        if(month==='All'&&year==y)return r
        if(year==='All'&&month==='All')return r
      }else if(year){
        if(y==year)return r
        if(year==='All')return r
      }else if(month){
        if(m==month)return r
        if(month==='All')return r
      }
    })
    
  }else{
    return rows
  }

}

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {

  const months = new Array(12).fill(0).map((_, i) => {
    return new Date(`${i + 1}/1`).toLocaleDateString(undefined, {month: 'long'})
  });

  
  const years = (back) => {
    const year = new Date().getFullYear();
    return Array.from({ length: back }, (v, i) => year - back + i + 1);
  };

  

  return (
    <div>

      <select
      className="form-select customInput mr-3"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? val : undefined, old[1]]);
        }}
      >
        <option value="All" >All</option>
        {
          years(20).map((year,key)=>{
           return <option value={year} key={key}>{year}</option>
          })
        
      }
      </select>
      <select
      className="form-select customInput"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old=[])=>[old[0], val ? val : undefined]);
        }}
      >
                <option value="All" >All</option>
        {
          months.map((month,key)=>{
           return <option value={month} key={key}>{month}</option>
          })
        
      }
      </select>

    </div>
  );
}
