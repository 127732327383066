import React, { useState } from 'react';
import { NavLink as NavLinkRRD, useLocation } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import './Sidebar.css';

const NavItemHeader = props => {
    const {name, icon, layout, path, key, children} = props.item;
    const [expanded, setExpand] = useState(false);


    const onExpandChange = e => {
        e.preventDefault();
        setExpand(expanded => !expanded);
    };

    const rotate = expanded ? "rotate(180deg)" : "rotate(0)"

	
  return (
    <>
        <NavItem key={key} className="parent-menu" onClick={onExpandChange}>
            <NavLink
                to={'#'}
                tag={NavLinkRRD}
                activeClassName={expanded ? "active" : "actives"}
            >
                <i className={icon} />
                <span>{name}</span>
                <div style={{right:  '10%', position: 'absolute'}}>
                    <i className="fas fa-chevron-down" style={{ transform: rotate, transition: "all 0.2s linear" }}></i>
                </div>
            </NavLink>
        </NavItem>

        {expanded && (
            children.map((item, index) => {
                const key = `${item.name}-${index}`;

                const show = item.show !== 'undefined' && item.show;
                if(!show){
                    return null;
                }

                if (item.children) {
                    return (
                    <div key={`${key}_1`}>
                        <NavItemHeader item={item} />
                    </div>
                    );
                }

                return (
                    <NavItem key={key} className="child-menu-active" onClick={()=>console.log("child path = ", item.layout + item.path)}>
                        <NavLink
                            to={item.layout + item.path}
                            tag={NavLinkRRD}
                            activeClassName="active-child"
                            >
                            <i className={item.icon} />
                            {item.name}
                        </NavLink>
                    </NavItem>
                );
            })
        )}
    </>
  );
};

export default NavItemHeader;
