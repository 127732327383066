import { API } from "../utils/api";


async function getDashboardMaintenances(period) {
  try {
    console.log("fetch maintenance ...", period)
    const response = await API.get(`maintenances/dashboard/${period}`);
    console.log("fetch maintenance ...", response?.data)
    return response?.data
  } catch (err) {
      console.error("err")
  }
}


export { getDashboardMaintenances }
