import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  getUnits,
  selectBuildingDetails,
  updateUnitReducer,
  addUnit
} from "store/reducers/buildingsSlice";
import formGroup from "components/Forms/FormFieldFunction";

const UnitForm = (props) => {
  const dispatch = useDispatch();
  const { unit, modal, closeModal, action } = props;
  const toggle = () => closeModal();
  const building = useSelector(selectBuildingDetails);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: unit?.name ? unit.name : "",
      bedrooms: unit?.bedrooms ? unit.bedrooms : 0,
      rent: unit?.rent ? unit.rent : 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("required *"),
      bedrooms: Yup.number().required("required *"),
      rent: Yup.number().required("required *"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let res = false;
      if (action === `edit`) {
        let unitObject = {buildingId:building["_id"],unitId:unit["_id"],value:values}
        dispatch(updateUnitReducer(unitObject))
        toggle();
      } else {
        let unitObject = {buildingId:building["_id"],unitData:values}
        dispatch(addUnit(unitObject))
        toggle()
      }
      if (res) {
        resetForm();
        toggle();
      }
    },
  });

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader className="text-center d-block">
          {action} House
        </ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={formik.handleSubmit}>
            {formGroup("fas fa-home", "text", "name", formik, "house name")}
            {formGroup("fas fa-bed", "number", "bedrooms", formik)}
            {formGroup("far fa-money-bill-alt", "number", "rent", formik)}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
            type="submit"
            disabled={!formik.isValid}
          >
            {action}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UnitForm;
