import {useEffect, useMemo, useState} from "react";
import {useFilters, usePagination, useTable} from "react-table";
// reactstrap components
import {Card, CardHeader, Container, Row} from "reactstrap";
import {CSVLink} from "react-csv";
// core components
import SmsTopUpTable from "components/Table/SmsTopUpTable"


import {smstopupColumns} from "utils/smstopupColumns";
import {DefaultColumnFilter} from "utils/DefaultColumnFilter";
import {fetchAllSmstopups, selectSmsTopUps} from "../../store/reducers/smstopupsSlice";
import {useDispatch, useSelector} from "react-redux";

const SmsTopUps = () => {
    const dispatch = useDispatch()
    const topupData = useSelector(selectSmsTopUps)
    const [rows, setRows] = useState([]);
    const data = useMemo(() => topupData, [topupData]);
    const columns = useMemo(() => smstopupColumns, []);

  
    const defaultColumn = useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const initialState = { hiddenColumns: ['tenantId', '_id'] };
    const tableInstance = useTable({ columns, data, initialState, defaultColumn}, useFilters,usePagination);
  

    let tableData = () => {
        return rows.map((item) => {
            return item.original;
        });
    };
    const returnRow = (row)=>{
        setRows(row)
    }
    useEffect(() => {
        dispatch(fetchAllSmstopups())
    }, []);

  return (
    <>
      {/* Page content */}
      <div className="" style={{height:'200px'}} ></div>

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow"  style={{overflow:'scroll'}} >
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col-4">
                    <h3 className="mb-0">Sms Top Ups</h3>
                  </div>
                  <div className="col-4"></div>
                  <div className="col-4 text-right">
                    <span>
                      <CSVLink
                        data={tableData()}
                        filename={"Sms Top Ups-Rentika Systems.csv"}
                        className="btn-success btn-sm btn"
                      >
                        <i className="fas fa-download  mr-0 mr-md-2"></i>
                        <span className="d-none d-md-inline">Download</span>
                      </CSVLink>
                    </span>
                    <span>
                     
                    </span>
                  </div>
                </Row>
              </CardHeader>
              <SmsTopUpTable item={false} tableInstance={tableInstance} returnData={returnRow}/>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SmsTopUps;
