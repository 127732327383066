import React from 'react';
import { NavLink as NavLinkRRD } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import NavItemHeader from './NavItemHeader';


const CNavItem = props => {
    const { name, icon, layout, path, children } = props.item;

    const show = props.item.show !== 'undefined' && props.item.show;
    if(!show){
        return null;
    }

    if (children) {

        // check if parent has children that are visible
        let visibleChildren = [];
        children.map((prop, key) =>{
            if(prop.show){
                visibleChildren.push(prop)
            }
        })

        // don't display even the parent if none of the children are visible
        if(children && visibleChildren.length == 0){
            return null;
        }

        return <NavItemHeader item={props.item} />;
    }

    return (
        <NavItem>
            <NavLink
                to={layout + path}
                tag={NavLinkRRD}
                activeClassName="active"
            >
                <i className={icon} />
                {name}
            </NavLink>
        </NavItem>
    );
};

export default CNavItem;
