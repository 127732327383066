import React from "react";
import {Button} from "reactstrap";
import { Link } from "react-router-dom";

function confirmEmail(){

    return(
        <>
             <div className="text-center mx-auto p-3">
                <h2>Your email has been confirmed successfully</h2>
                <i class="fas fa-check-circle d-block fa-8x text-success"></i>
                <Button className="my-4 text-light btn-primary">
                        <Link to="/auth/login">
                            Sign in
                        </Link>
                </Button>
            </div>
        </>
    )
}

export default confirmEmail