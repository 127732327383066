import {API} from "../utils/api";

async function createUtilityType(utilitytype) {
  try {
    const response = await API.post(`utilitytypes/add`,utilitytype);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getUtilityType(id){
    try {
        const response = await API.get(`utilitytypes/${id}`);
        return response?.data
      } catch {
          console.log("something went wrong")
      }
}

async function getUtilityTypes() {
  try {
    const response = await API.get(`utilitytypes/`);
    return response?.data
  } catch(error) {
      console.log("something went wrong ")
  }
}

async function updateUtilityType(id,utilitytypeData) {
  try {
    const response = await API.post(`utilitytypes/update/${id}`,utilitytypeData);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function deleteUtilityType(id) {
  try {
    const response = await API.delete(`utilitytypes/delete/${id}`);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}


export {
    getUtilityType,
    getUtilityTypes,
    createUtilityType,
    updateUtilityType,
    deleteUtilityType
}