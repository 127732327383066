import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState:{
        value:{},
        status:'idle',
        error:null
    },
    reducers: {

        getUser:(state,action)=>{
            state.value = action.payload
        },
        clearState:(state,action)=>{
            state = undefined;
        }
        
    },
})

export const {getUser,clearState} = userSlice.actions

export const selectUser = state => state.user.value;


export default userSlice.reducer;
