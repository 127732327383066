import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import {getSmsTopups, createSmsTopup, updateSmsTopup, deleteSmsTopup} from 'services/smstopupsService'
import {fetchAllMaintenances} from "./maintenanceSlice";



export const fetchAllSmstopups = createAsyncThunk('All/smstopups', async () => {
    const response = await getSmsTopups()
    return response
})

export const addSmstopup = createAsyncThunk('add/smstopup', async (topup) => {
    const response = await createSmsTopup(topup)
    return response
})

export const updateSmstopupReducer = createAsyncThunk('update/smstopup', async (topup) => {
    const {id, topupData} = topup
    const response = await updateSmsTopup(id, topupData)
    return response
})

export const removeSmstopup = createAsyncThunk('delete/smstopup', async (topupId) => {
    const response = await deleteSmsTopup(topupId)
    return response
})


export const smstopupsSlice = createSlice({
    name: 'smstopup',
    initialState:{
        value:[],
        status:'idle',
        error:null
    },
    reducers: {

    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllSmstopups.rejected,(state,action)=>{
                state.error = "An error occurred while fetching sms top up data"
            })
            .addCase(fetchAllSmstopups.fulfilled, (state, action) => {
                state.status ='complete'
                state.value = action.payload
            })
    }
})

export const selectSmsTopUps = state => state.smstopup.value
export const selectSmsTopUpsError = state => state.smstopup.error
export default smstopupsSlice.reducer;