import { useEffect} from "react";
import { Container} from "reactstrap";


import { useDispatch, useSelector } from "react-redux";
import Header from "components/Headers/Header.js";
import {fetchAllBuildings, selectBuildingStatus} from "store/reducers/buildingsSlice";
import {selectPaymentsStatus} from "store/reducers/paymentsSlice"
import Loader from "components/loader/Loader";
import RevenueSummary from 'components/Dashboard/Revenue/RevenueSummary'
import MaintenanceSummary from 'components/Dashboard/Maintenance/MaintenanceSummary'
import RevenueAndPerformanceSummary from 'components/Dashboard/RevenueAndPerformance/RevenueAndPerformanceSummary'
import RecentPaymentSummary from 'components/Dashboard/RecentPayment/RecentPaymentSummary'



const Index = () => {
	const dispatch = useDispatch();
	const paymentStatus = useSelector(selectPaymentsStatus)
	const buildingStatus = useSelector(selectBuildingStatus)
	let content = ''


	useEffect(() => {
		if(buildingStatus === 'idle'){
			dispatch(fetchAllBuildings());
		}
	}, []);
	if(paymentStatus === 'loading'){
			content = <Loader/>
	}else{
		content =  <div>
			<Header />
			{/* Page content */}
			<Container className="mt--7" fluid>
			
				<RevenueSummary />
				
				<MaintenanceSummary />

				<RevenueAndPerformanceSummary />

				<RecentPaymentSummary />
			</Container>
		</div>
	}
	return (
		<>
			{ content }
		</>
	);
};

export default Index;
