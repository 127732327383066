import { API } from "../utils/api";


async function getDashboardRevenues(period) {
  try {
    console.log(period)
    const response = await API.get(`allpayments/revenue/${period}`);
    return response?.data
  } catch (err) {
      console.error("err")
  }
}


export { getDashboardRevenues }
