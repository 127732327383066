import axios from 'axios';

const URL = process.env.NODE_ENV === 'development' ? `http://localhost:7877/api/` : `https://api.rentika.app/api`;
// const URL = `https://dev.rentikasystem.com/api/`;


const PESAPAL_URL =  `https://portal.rentika.app/pesapal/iframe.php`;

const API = axios.create({
    baseURL: URL
});

const uninterceptedAxiosInstance = axios.create({
    baseURL: URL
});

//interceptor
const requestHandler = request => {    
    request.headers["x-authentication"] = localStorage.getItem("authToken");  
    return request;
};

const errorHandler = error => {
    return Promise.reject(error);
};

API.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

const authorizedDownload = (request) => {

  fetch(request.route, {
        method: request.method,
        headers: {
            'Content-Type': 'application/json',
            "x-authentication": localStorage.getItem("authToken")
        },
        body:JSON.stringify({...request.data}),
    })
    .then((response) => response.blob())
    .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
            'download',
            `RentikaReport.pdf`,
        );

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    });
};


export   { PESAPAL_URL, API, uninterceptedAxiosInstance, authorizedDownload };
