import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react'

import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import { useFormik } from "formik";

import * as Yup from "yup";
import formGroup from "../../components/Forms/FormFieldFunction";
import { addPayment } from "store/reducers/paymentsSlice";
import { ToastContainer } from "react-toastify";
import { fetchAllBuildings, selectBuildings } from "store/reducers/buildingsSlice";
import { fetchAllBalances } from "store/reducers/paymentsSlice";

const PaymentForm = (props) => {
  const { modal, closeModal, action, tenants, buildingId } = props;
  const toggle = () => closeModal();
  const dispatch = useDispatch();
  const buildings = useSelector(selectBuildings)
  const [utilityOptions, setUtilityOptions] = useState([])
  const [amount,setAmount] = useState(0)
  const tenantOptions = tenants.map((val, index) => (
    <option value={val.tenantId} key={index}>{val.tenantName}</option>
  ));

  const modeOptions = ["BANK", "M-PESA", "CASH"].map((val, index) => (
    <option value={val} key={index}>{val}</option>
  ));
  const paymentOptions = ["RENT", "UTILITY", "MAINTENANCE"].map((val, index) => (
    <option value={val} key={index}>{val}</option>
  ));

  

  const formik = useFormik({
    
    initialValues: {
      tenantId: "",
      amount: 0,
      Quantity:0,
      paymentType: "",
      utility: "",
      transactionCode: "",
      modeOfPayment: "",
      sendReceipt: false
    },
    enableReinitialize:true,
    validationSchema: Yup.object({
      tenantId: Yup.string().required("required *"),
      amount: Yup.string().required("required *"),
      transactionCode: Yup.string(),
      paymentType: Yup.string().required("required *"),
      utility: Yup.string().when('paymentType', { is: "UTILITY", then: Yup.string().required("required *") }),
      modeOfPayment: Yup.string().required("required *"),
      sendReceipt: Yup.boolean()
    }),
    onSubmit: async (value, { resetForm }) => {
      let values = { ...value }
      if (values.utility == '') {

        delete values.utility
      }
      values.amount = document.getElementById('amount').value
      
      let obj = {
        buildingId,
        tenantId: values.tenantId,
        modeOfPayment: values.modeOfPayment,
        transactionCode: values.transactionCode,
        sendReceipt: values.sendReceipt,
        status:'CONFIRMED',
        ...values
      }
      dispatch(addPayment(obj));
      dispatch(fetchAllBalances());
      resetForm();

      toggle();
    },
  });

  useEffect(() => {
    dispatch(fetchAllBuildings())
    const building = buildings.find(item => item._id === buildingId)
    if (building !== undefined) {
      const result = building.utilities.map((val, index) => (
        <option value={val._id} key={index}>{val.utilityName}</option>
      ));
      setUtilityOptions(result)
    }

  }, [buildingId])


  return (
    <>
      <ToastContainer />

      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
        <ModalHeader className="text-center d-block">Add Payment</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={formik.handleSubmit}>
            {formGroup("fas fa-home", "select", "tenantId", formik, "select tenant", tenantOptions)}
            {formGroup("far", "select", "paymentType", formik, "select type of payment", paymentOptions)}
            {formik.values.paymentType === "UTILITY" ? formGroup("far", "select", "utility", formik, "select a utility", utilityOptions) : formik.values.utility = ""}
            {formGroup("far", "select", "modeOfPayment", formik, "select mode of payment", modeOptions)}
            {formGroup("far", "text", "transactionCode", formik, "transaction code *optional")}
            {formik.values.paymentType === "UTILITY" ? formGroup("far", "text", "Quantity", formik, "enter a quantity") :null}
            <Input
                placeholder="amount"
                type="text"
                name="amount"
                id="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.paymentType === "UTILITY" && formik.values.utility?
                formik.values["Quantity"]*(buildings.find(item => item._id === buildingId).utilities.find((utility)=>utility._id === formik.values["utility"])).utilityUnit 
                : formik.values["amount"]}
                readOnly = {formik.values.paymentType === "UTILITY"?true:false}
              />
                  

            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="sendReceipt"
                type="checkbox"
                onChange={formik.handleChange}
              />
              <label
                className="custom-control-label"
                htmlFor="sendReceipt"
              >
                <span className="text-muted">Send receipt</span>
              </label>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
            type="submit"
            disabled={!formik.isValid}
          >
            {action}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PaymentForm;
