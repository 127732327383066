import React, { useState, useEffect,useMemo } from "react";
import { useDispatch,connect} from "react-redux";
import { Table, Button, Tooltip, Pagination, PaginationItem, PaginationLink, Progress } from "reactstrap";
import { useTable, usePagination } from "react-table";

import DeleteModal from "components/Modals/DeleteModal";
import {
  getUnits,
  fetchAllBuildings
} from "store/reducers/buildingsSlice";
import "assets/css/tables.css";
import { removeBuilding,updateBuildingReducer } from "store/reducers/buildingsSlice";

const dataColumns = [
  
    {
      Header: "Building Name",
      accessor: "name",
    },
    {
      Header: "Paid",
      accessor: row => <span className="text-primary">{row.paid}</span>
    },
    {
        Header: "Unpaid",
        accessor: row => <span className="text-danger">{row.unpaid}</span>
    },
    {
      Header: "Graph",
      accessor: row => <Progress
                color={generateProgressColor(parseInt(row.graph))}
                value={row.graph}
                max={100}
                style={{ height: "20px", width: "180px" }}
            />
    },
    {
        Header: "Performance",
        accessor: "performance",
    },
  
  ];

const generateProgressColor = (percentage) =>{
    let color = "primary";
    if(percentage < 25){
        color = "danger"
    }else if(percentage > 25 && percentage < 50){
        color = "warning"
    }else if(percentage > 50 && percentage < 75){
        color = "primary"
    }else if(percentage == 100){
        color = "success"
    }

    return color;
}

function MTable(props) {
  const [activeIndexState, setActiveIndex] = useState({ activeIndex: 0 });
  const [OpenDelete, setOpenDelete] = useState(false);
  const [OpenUpdate, setOpenUpdate] = useState(false);
  const dispatch = useDispatch();
  const { data, clickable, buildings,buildingStatus } = props;
  const [building, setBuilding] = useState({});

  const columns = useMemo(() => dataColumns, []);

  const tableInstance = useTable({ columns, data }, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
  } = tableInstance;
  const { pageIndex } = state;


  useEffect(() => {
    setActiveIndex({ activeIndex: 0 });
  }, [data]);

  return (
    <>
      <Table
        {...getTableProps()}
        className="align-items-center table-flush"
        responsive
      >
        <thead className="thead-light2">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th scope="col" {...column.getHeaderProps()} style={{backgroundColor: '#EDF2F8'}}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row,index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
              onClick={() => {
                setActiveIndex({ activeIndex: index });
                dispatch(getUnits(row.original["_id"]));
              }}
              className={`customRows`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center" style={{
		  color: '#8898aa',
		  backgroundColor: '#EDF2F8'
	  }}>
		{/* <span className="mr-2">
			Page{" "}
			<strong>
				{pageIndex + 1} of {pageOptions.length}
			</strong>{" "}
		</span>
		<Button onClick={() => previousPage()} disabled={!canPreviousPage}>
			Previous
		</Button>
		<Button onClick={() => nextPage()} disabled={!canNextPage}>
			Next
		</Button> */}
		
		{/* <Pagination size="sm" aria-label="Page navigation example">
			<PaginationItem>
				<PaginationLink previous href="#" />
			</PaginationItem>
			<PaginationItem active>
				<PaginationLink href="#">
					1
				</PaginationLink>
			</PaginationItem>
			<PaginationItem>
				<PaginationLink href="#">
					2
				</PaginationLink>
			</PaginationItem>
			<PaginationItem>
				<PaginationLink href="#">
					3
				</PaginationLink>
			</PaginationItem>
			<PaginationItem>
				<PaginationLink next href="#" />
			</PaginationItem>
		</Pagination> */}
      </div>
    </>
  );
}
const mapPropsToState = state =>{
  return {
    buildings:state.buildings.value,
    buildingStatus:state.buildings.status
  }
}

export default connect(mapPropsToState)(MTable);
