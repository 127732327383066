import { useEffect,useState} from "react";
import { Container, Row, Col } from "reactstrap";
import RevenueChart from './RevenueChart';

const RevenueSummary = (props) => {

    return (
        <div>
            <Row className="mt-5">
                <Col xl="12">
                    <RevenueChart />
                </Col>
            </Row>
        </div>
    );
};


export default RevenueSummary;
