import { useEffect, useState, useMemo} from "react";
import { Card, CardHeader, Row } from "reactstrap"
import { useDispatch, connect } from "react-redux";
import { fetchDashboardMaintenances } from "store/reducers/dashboardmaintenanceSlice";
import './Maintenance.css'
import MTable from './MTable';

 const MaintenanceTable = (props) =>{
    const {dashboardmaintenances, dashboardmaintenanceStatus, buildings} = props;
    const dispatch = useDispatch();
    const headings = ["Building Name", "Total"];
    const [selectedPeriod, setSelectedPeriod] = useState("year");
    const data = useMemo( () => dashboardmaintenances, [dashboardmaintenances] );


    useEffect(async () => {
        if (dashboardmaintenanceStatus === 'idle') {
            await  dispatch(fetchDashboardMaintenances(selectedPeriod));
        }
    }, [dashboardmaintenances]);

    const setPeriod = async (period) =>{
        setSelectedPeriod(period);
        await  dispatch(fetchDashboardMaintenances(period));
    }

    return (
        <Card className="shadow overflow-hidden">
            <CardHeader className="border-0" style={{backgroundColor: '#F9FAFD'}}>
              <Row className="align-items-center">
                <div className="col-6">
                  <h3 className="mb-0 text-dark">Maintenance</h3>
                </div>
                <div className="col-6 text-right">
                    <a color="primary" className={selectedPeriod == "week" ? "active-period" : "period" } onClick={()=>setPeriod("week")} size="sm">Week</a>
                    <a color="primary" className={selectedPeriod == "month" ? "active-period" : "period" } onClick={()=>setPeriod("month")} size="sm">Month</a>
                    <a color="primary" className={selectedPeriod == "year" ? "active-period" : "period" } onClick={()=>setPeriod("year")} size="sm">Year</a>
                </div>
              </Row>
            </CardHeader>
                <MTable
                    headings={headings}
                    data={data}
                    actions={true}
                    clickable={true}
                />
          </Card>
    )
}

const mapPropsToState = state =>{
    return{
        dashboardmaintenances: state.dashboardmaintenances.value,
        dashboardmaintenanceStatus: state.dashboardmaintenances.status
    }
  }
  
  
  export default connect(mapPropsToState)(MaintenanceTable);