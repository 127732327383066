export const buildingColumns = [
  
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "LOCATION",
    accessor: "address",
  },
  {
    Header: "TYPE OF PROPERTY",
    accessor: "propertyType",
  },
  {
    Header: "NO. OF HOUSE",
    accessor: "noOfUnits",
  },

];
