import React from 'react'
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap"
import './DashboardTile.css'
 function DashboardTile(props) {
    return (
        <Card className="card-stats mb-4 mb-xl-0 custom-card ">
                  <CardBody>
                    <Row>
                      <div className="col-6 info-col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {
                              props.name
                          }
                        </CardTitle>
                          {props.children}
                      </div>
                      <div className='col-6 analytics-col'>
                        {props.analytics}
                      </div>
                    </Row>
                  </CardBody>
          </Card>
    )
}

export default DashboardTile