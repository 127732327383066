import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
import { actions } from 'react-table/dist/react-table.development'
import {
    getParameters,
    getParameter,
    createParameter,
    updateParameter,
    deleteParameter } from 'services/parametersService'

import { notify } from 'utils/sharedFunctions'

export const fetchAllParameters = createAsyncThunk('All/parameters', async () => {
    const response = await getParameters()
    return response
})

export const addParameter = createAsyncThunk('add/parameter', async (parameter) => {
    const response = await createParameter(parameter)
    return response
  })
export const updateParameterReducer = createAsyncThunk('update/parameter', async (parameter) => {

    const {id,parameterData} = parameter
    const response = await updateParameter(id, parameterData)
    return response
  })
export const removeParameter = createAsyncThunk('delete/parameter', async (parameterId) => {
    const response = await deleteParameter(parameterId)
    return response
  })



export const parametersSlice = createSlice({
    name: 'parameters',
    initialState:{
        value:[],
        parameterDetails:{},
        status:'idle',
        error: ''
    },
    reducers: {
        clearParametersState:(state,action)=>{
          state.value=[]
          state.parameterDetails = {}
          state.status='idle'
      }
    },
    extraReducers(builder){
        builder
        .addCase(fetchAllParameters.pending, (state, action) => {
            state.status = "loading";
          })
        .addCase(fetchAllParameters.fulfilled, (state, action)=>{
            state.status = "complete"
            state.value = action.payload?.data
            if(action.payload.responseCode == "01"){
                state.value = []
                state.error = action.payload.responseDescription
            }
        })
        .addCase(addParameter.fulfilled, (state, action)=>{
            if(action.payload == undefined || action.payload.responseCode == "01"){
                state.error = action.payload.responseDescription;
                notify("error", "Error Adding Parameter!");
            }else{
                state.value.push(action.payload.data);
                state.error = '';
                notify("success", "Parameter added Successfully!");
            }
        })
        .addCase(updateParameterReducer.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                const findIndex = state.value.findIndex(item=>item._id === action.payload.data._id)
                state.value[findIndex]=action.payload.data
                notify("success", "Parameter updated Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Updating Parameter!");
            }

            const findIndex = state.value.findIndex(item=>item._id === action.payload._id)
            state.value[findIndex]=action.payload
        })
        .addCase(removeParameter.fulfilled, (state, action)=>{
            if(action.payload.responseCode == "00"){
                state.error = '';
                state.value = state.value.filter(parameter=>parameter._id !==action.payload.data)
                notify("success", "Parameter removed Successfully!");
            }else{
                state.error = action.payload.responseDescription
                notify("error", "Error Removing Parameter!");
            }
        })
    }
})

export const { clearParametersState } = parametersSlice.actions



export const selectParameters = state => state.parameters.value;
export const selectParameterStatus = state => state.parameters.status;
export const selectParameterDetails = state => state.parameters.parameterDetails;
export const parameterError = state => state.parameters.error;

export default parametersSlice.reducer;