import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import routes from "routes.js";



 const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/info") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

function Info(props){

    return(
        <>
            <div className="container">
                <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/info/confirm-mail" />
                </Switch>
            </div>
        </>
    )
}

export default Info;