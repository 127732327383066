import {
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,

  } from "reactstrap";


const option = (type,options,placeholder)=>{
    if (type === 'select') {
        return(
            <>
            <option value='' disabled>{placeholder} </option>
          {options !==undefined ? options:
          (<><option value="COMMERCIAL">COMMERCIAL</option>
          <option value="RESIDENTIAL">RESIDENTIAL</option></>)
          }
            
            </>
        )
    }else return null

}

const input = (icon,type,name,formik,placeholder,options,label)=>{

  return<>
  {icon===null? <label
                              className="form-control-label"
                              htmlFor={name}
                            >
                              {label}
                            </label>: <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className={icon}></i>
                </InputGroupText>
              </InputGroupAddon>}
  <Input
                placeholder={placeholder}
                type={type}
                name={name}
                id={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[name]}
                accept={type==="file"?"image/png, image/jpeg":null}
              >
                  
                      {
                          option(type,options,placeholder)
                      }
         </Input>
  </>
}

const formGroup = (icon,type,name,formik,placeholder,options,label)=>{
    return(
      <FormGroup className="mb-3">
           {icon===null?input(icon,type,name,formik,placeholder,options,label):<InputGroup className="input-group-alternative">{input(icon,type,name,formik,placeholder,options)} </InputGroup>}
            {formik.touched[name] && formik.errors[name] ? (
              <small className="text-danger">{formik.errors[name]}</small>
            ) : null}
          </FormGroup>
    )
  }


  export default formGroup