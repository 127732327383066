import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

const FullEventCalendar = (props) => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if(props.events){
            let items = [];
            props.events.map(item =>{
                if(item.status.toLowerCase() == "pending"){
                    console.log("item ", item);
                    items.push({ title: item.maintenanceType, date: item.eventDate, color: item.eventColor })
                }
            })

            setEvents(items);
        }
      }, [props.events]);

    return (
        <div className="col-md-12 col-sm-6">
            <FullCalendar
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
                events={events}
            />
        </div>
    );
};

export default FullEventCalendar;
