import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboardMaintenances } from "../../services/dashboardMaintenanceService";


export const fetchDashboardMaintenances = createAsyncThunk("All/DashboardMaintenance", async (period) => {
    const response = await getDashboardMaintenances(period);
    return response;
})


export const dashboardMaintenanceSlice = createSlice({
    name: 'dashboardmaintenances',
    initialState: {
        value: [],
        status:'idle',
        error:null
    },
    reducers: {
        clearDashboardMaintenanceState:(state,action)=>{
            state.value=[];
            state.status='idle'
        }
    },
    extraReducers(builder) {
        builder
        .addCase(fetchDashboardMaintenances.pending, (state, action) => {
            state.status = "loading";
        })
        .addCase(fetchDashboardMaintenances.fulfilled, (state, action) => {
            state.status = "complete";
            state.value = action.payload;
        })
    },
});


export const {clearDashboardMaintenanceState} = dashboardMaintenanceSlice.actions
export const selectDashboardMaintenances = state => state.dashboardmaintenances.value;
export const SelectDashboardMaintenancesStatus = state =>state.dashboardmaintenances.status;


export default dashboardMaintenanceSlice.reducer;
