import { useEffect,useState} from "react";
import { Button, Card, CardBody, CardHeader, Row } from "reactstrap"
import { useDispatch, useSelector,connect } from "react-redux";
import { fetchDashboardRevenues } from "store/reducers/revenuesSlice";
import './Revenue.css'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {selectPaid} from "store/reducers/paymentsSlice"
import {abbreviate} from "utils/shortenNumber"


const RevenueChart = (props) =>{
    const { revenues } = props;
    const dispatch = useDispatch();
    const paid = useSelector(selectPaid)
    const [selectedPeriod, setSelectedPeriod] = useState("year");
    
    useEffect(() => {
        dispatch(fetchDashboardRevenues(selectedPeriod));
    }, []);

    const setPeriod = (period) =>{
        setSelectedPeriod(period);
        dispatch(fetchDashboardRevenues(period));
    }

    return (
        <Card className="shadow overflow-hidden">
            <CardHeader className="border-0" style={{backgroundColor: '#F9FAFD'}}>
              <Row className="align-items-center">
                <div className="col-6">
                  <h3 className="mb-0 text-dark">Revenue</h3>
                </div>
                <div className="col-6 text-right">
                    <a color="primary" className={selectedPeriod == "week" ? "active-period" : "period" } onClick={()=>setPeriod("week")} size="sm">Week</a>
                    <a color="primary" className={selectedPeriod == "month" ? "active-period" : "period" } onClick={()=>setPeriod("month")} size="sm">Month</a>
                    <a color="primary" className={selectedPeriod == "year" ? "active-period" : "period" } onClick={()=>setPeriod("year")} size="sm">Year</a>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
                <Row className="align-items-center">
                    <div className="col-4 mb-4 ">
                        <Row className="align-items-center">
                            <div className="col-12">
                                <div className="rent_box_parent">
                                    <i className="fas fa-building"></i>
                                </div>
                                <div>
                                    <h4 className="utility_name">Rent</h4>
                                    <h2 className="utility_count">{abbreviate(paid?.rent,1,false,false)}</h2>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className="col-4 mb-4 ">
                        <Row className="align-items-center">
                            <div className="col-12">
                                <div className="service_box_parent">
                                <i className="fas fa-shower"></i>
                                </div>
                                <div>
                                    <h4 className="utility_name">Utilities</h4>
                                    <h2 className="utility_count">{abbreviate(paid?.utilities,1,false,false)}</h2>
                                </div>
                            </div>
                        </Row>
                    </div>
                    <div className="col-4 mb-4 ">
                        <Row className="align-items-center">
                            <div className="col-12">
                                <div className="water_box_parent">
                                <i className="fas fa-wrench"></i>
                                </div>
                                <div>
                                    <h4 className="utility_name">Maintenance</h4>
                                    <h2 className="utility_count">{abbreviate(paid?.maintenance,1,false,false)}</h2>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row>
                <ResponsiveContainer width={"100%"} height={300}>
                <LineChart
                        width={500}
                        height={300}
                        data={revenues}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="NAME" axisLine={false} />
                        <YAxis axisLine={false} />
                        <Tooltip />
                        <Line dataKey="RENT" stroke="#2A7BE4" activeDot={{ r: 8 }} />
                        <Line dataKey="UTILITY" stroke="#00D27B" activeDot={{ r: 8 }} />
                        <Line dataKey="MAINTENANCE" stroke="#E63756" activeDot={{ r: 8 }} />
                        </LineChart>
                </ResponsiveContainer>
            </CardBody>
          </Card>
    )
}

const mapPropsToState = state =>{
    return{
      revenues:state.revenues.value,
      revenuesStatus:state.revenues.status
    }
  }
  
  
  export default connect(mapPropsToState)(RevenueChart);
