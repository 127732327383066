
import React from 'react'


const Analysis = (props)=>{
    return <>
              <img
              src={
                require(`../../assets/img/icons/dashboard/${props.asset}`).default
              }
              />
           </>
  }


export default Analysis