import { useState, useMemo } from "react";
import { useDispatch, connect } from "react-redux";
import { useTable, usePagination } from "react-table";

import { Button, Table } from "reactstrap";
import { deleteTenantReducer } from "store/reducers/tenantsSlice";
import TenantForm from "components/Forms/TenantForm";
import DeleteModal from "components/Modals/DeleteModal";
import { tenantColumns } from "utils/tenantColumns";
const TenantTable = (props) => {
  const { tenants, specificBuilding } = props;
  const dispatch = useDispatch();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [OpenDelete, setOpenDelete] = useState(false);
  const [tenant, setTenant] = useState({});

  const data = useMemo(
    () =>
      specificBuilding !== undefined
        ? props.tenants.filter(
          (tenant) => tenant.buildingId === specificBuilding
        )
        : tenants,
    [tenants, specificBuilding]
  );

  const columns = useMemo(() => tenantColumns, []);

  const tableInstance = useTable({ columns, data }, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
  } = tableInstance;
  const { pageIndex } = state;


  const actions = (icon, color, clickFn, setItemFn, item, disabled) => {
    return (
      <Button
        color={color}
        onClick={() => {
          clickFn(true);
          setItemFn(item);
        }}
        size="sm"
        disabled={disabled}
      >
        <i className={icon}></i>
      </Button>
    );
  };



  const handleClose = () => {
    setOpenUpdate(false);
    setOpenDelete(false);
  };

  const handleConfirm = async (id) => {
    dispatch(deleteTenantReducer(id));
  };

  return (
    <>
      <TenantForm modal={openUpdate} closeModal={handleClose} action={"edit"} tenant={tenant}/>
      <DeleteModal modal={OpenDelete} closeModal={handleClose} title={"tenant"} item={tenant} confirmModal={handleConfirm}/>

      <Table
        {...getTableProps()}
        className="align-items-center table-flush"
        responsive
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th scope="col" {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th>actions</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
                <td>
                  {actions(
                    "fas fa-pencil-alt",
                    "primary",
                    setOpenUpdate,
                    setTenant,
                    row.original
                  )}
                  {actions(
                    "fas fa-trash-alt",
                    "danger",
                    setOpenDelete,
                    setTenant,
                    row.original
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center mb-5">
        <span className="mr-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tenants: state.tenants.value,
  };
};

export default connect(mapStateToProps)(TenantTable);
