import {Badge,Button} from 'reactstrap'
import {format} from 'date-fns'
import { dateBetweenFilterFn, DateRangeColumnFilter } from './DateRangeColumnFilter';


export const maintenanceColumns = [
  {
    Header: "Request Date",
    accessor: "createdTime",
    Cell:({value})=>{return format(new Date(value),'dd/MM/yyyy')},
    Filter: DateRangeColumnFilter,
    filter: dateBetweenFilterFn,
  },
  {
    Header: "TENANT NAME",
    accessor: "tenantName",
  },
  {
    Header: "TENANT PHONE",
    accessor: "tenantPhone",
  },
  {
    Header: "MAINTENANCE TYPE",
    accessor: "maintenanceType",
  },
  {
    Header: "BUILDING",
    accessor: "building",
  },
  {
    Header: "UNIT",
    accessor: "unit",
  },
  {
    Header: "ID",
    accessor: "_id",
  },
  
];

