import {useEffect, useMemo, useState} from "react";
import {useFilters, usePagination, useTable} from "react-table";
// reactstrap components
import {Button, Card, CardHeader, Container, Row} from "reactstrap";
import {CSVLink} from "react-csv";
// core components
import MaintenanceTable from "components/Table/MaintenanceTable";
import FullEventCalendar from "components/FullCalendar/FullEventCalendar";


import {maintenanceColumns} from "utils/maintenanceColumns";
import {DefaultColumnFilter} from "utils/DefaultColumnFilter";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllMaintenances, selectMaintenances} from "store/reducers/maintenanceSlice";

const Maintenances = () => {
    const [rows, setRows] = useState([]);
    const maintenanceData = useSelector(selectMaintenances)
    const data = useMemo(() => maintenanceData, [maintenanceData]);
    const dispatch = useDispatch()
    const columns = useMemo(() => maintenanceColumns, []);
    const [showCalendar, setShowCalendar] = useState(false);
  
    const defaultColumn = useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const initialState = { hiddenColumns: ['tenantId', '_id'] };
    const tableInstance = useTable({ columns, data, initialState, defaultColumn}, useFilters,usePagination);
  

    let tableData = () => {
        let newRow = [...rows]
        return newRow.map((item) => {
            return item.original;
        });
    };
    const returnRow = (row)=>{
        setRows(row)
    }
    useEffect(() => {
        dispatch(fetchAllMaintenances())
    }, []);

  return (
    <>
        <div className="" style={{height:'200px'}} ></div>

        {/* Page content */}
        
        <Container className="mt--7" fluid>
            <Row>
                <div className="col">
                    <Card className="shadow"  style={{overflow:'scroll'}} >
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col-8 col-sm-6">
                                    <h3 className="mb-0">Maintenance Requests</h3>
                                </div>
                                <div className="col-4 col-sm-6 text-right">
                                    <Button 
                                        color="link"
                                        size="sm"
                                        onClick={()=>setShowCalendar(!showCalendar)}
                                        >
                                            <i className={showCalendar ? `fas fa-list-alt` : `fas fa-calendar-alt`}></i> {showCalendar ? `Maintenance List` : `Calendar`}
                                    </Button>

                                    <CSVLink
                                        data={tableData()}
                                        filename={"Maintenances.csv"}
                                        className="btn-success btn-sm btn"
                                        >
                                        <i className="fas fa-download  mr-0 mr-md-2"></i>
                                        <span className="d-none d-md-inline">Download</span>
                                    </CSVLink>
                                </div>
                            </Row>
                        </CardHeader>
                        {
                            showCalendar ? (
                                <FullEventCalendar events={maintenanceData}/>
                            ) : (
                                <MaintenanceTable item={false} tableInstance={tableInstance} returnData={returnRow}/>
                            )
                        }
                    </Card>
                </div>
            </Row>
        </Container>
    </>
  );
};

export default Maintenances;
