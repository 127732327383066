import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import AgentTable from "components/Table/AgentTable";
import AgentForm from 'components/Forms/AgentForm'
import Loader from "components/loader/Loader";
import {
    fetchAllAgents,
    selectAgents, 
    selectAgentStatus,
    agentError
} from "store/reducers/agentsSlice";



const Agents = () => {
    const agents = useSelector(selectAgents);
    const error = useSelector(agentError);
    const headings2 = ["Name", "Phone Number ", "ID Number", "Email", "Permission"];
    const [OpenAgent, setOpenAgent] = useState(false);
    const [OpenUnit, setOpenUnit] = useState(false);

    const handleClose = () => {
        setOpenAgent(false)
        setOpenUnit(false)
    }

    return (
        <div>
            <div className="" style={{ height: '200px' }} ></div>
            <Container className="mt--5" fluid>
            <AgentForm modal={OpenAgent} closeModal={handleClose} action={"create"}></AgentForm>
            
            <Row>
                {
                    error && (
                        <Col sm="12" xl={{ size: 8, offset: 2 }}>
                            <Alert color="danger" className="text-center"> {error} </Alert>
                        </Col>
                    )
                }
                <Col xl="12">
                    <Card className=" shadow overflow-hidden">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">  
                                <div className="col-6">
                                    <h3 className="mb-0 text-dark">Team Members</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <Button
                                        color="primary"
                                        onClick={() => setOpenAgent(true)}
                                        size="sm"
                                        className="btn-sm ml-2"
                                    >
                                        <i className="fas fa-plus mr-0 mr-md-2"></i>
                                        <span className="d-none d-md-inline">Add Member</span>
                                    </Button>
                                </div>
                            </Row>
                        </CardHeader>
                        <AgentTable
                            headings={headings2}
                            data={agents}
                            actions={true}
                            clickable={true}
                        />
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    );
}



const Properties = () => {
  const agentStatus = useSelector(selectAgentStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (agentStatus === 'idle') {
      dispatch(fetchAllAgents());
    }
  }, [agentStatus]);

  return (
    <>
      {agentStatus === 'loading' ? <Loader /> : <Agents />}
    </>
  );
};




export default Properties;


