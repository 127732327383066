import {Badge,Button} from 'reactstrap'
import store from 'store/store'
import {remindPayment} from 'store/reducers/paymentsSlice'


export const paymentColumns = [
  
  {
    Header: "TENANT",
    accessor: "tenantName",
  },
  {
    Header: "UNIT",
    accessor: "unitName",
  },
  {
    Header: "BALANCE",
    accessor: "balance",
  },
  {
    Header: "RENT",
    accessor: "owedRent",
  },
  {
    Header: "UTILITY",
    accessor: "owedUtilities",
  },
  {
    Header: "MAINTENANCE",
    accessor: "owedMaintenance",
  },

  {
    Header: "STATUS",
    id:1,
    accessor: "balance",
    Cell:({value})=><Badge color="" className="badge-dot mr-4">
    <i className={value <= 0 ? "bg-success":"bg-danger" } />{value <= 0? "COMPLETED":"INCOMPLETE"}</Badge>
  },
  
];
