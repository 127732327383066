import {useEffect,useState} from 'react';
import { useDispatch,useSelector} from "react-redux";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import {updateTenantReducer} from "store/reducers/tenantsSlice"
import {selectBuildings} from "store/reducers/buildingsSlice"
import formGroup from "components/Forms/FormFieldFunction";
import {getVacantUnits,getUnit} from 'services/unitService'
import { addTenant } from 'store/reducers/tenantsSlice';

const TenantForm = (props) => {
    
    const dispatch = useDispatch();
    const building = useSelector(selectBuildings)
    const {tenant,action,modal,closeModal,buildingId} = props
    const toggle = () => closeModal();
    const [unitOptions, setUnitOptions] = useState([]);


    const options = [
      <option value="MALE"key={1} >MALE</option>,
          <option value="FEMALE" key={2} defaultValue>FEMALE</option>,
          <option value="OTHER" key={3} >OTHER</option>
    ]
    const formik = useFormik({
        enableReinitialize: true,
    
        initialValues: {
          firstName: tenant?.firstName ? tenant.firstName : "",
          lastName: tenant?.lastName ? tenant.lastName : "",
          IDNo: tenant?.IDNo ? tenant.IDNo : "",
          email: tenant?.email ? tenant.email : "",
          phone: tenant?.phone ? tenant.phone : "",
          unitId:tenant?.unitId?tenant.unitId:"",
          arrearAmount: tenant?.arrearAmount ? tenant.arrearAmount : 0,
        },
        validationSchema: Yup.object({
          firstName: Yup.string().required("required *"),
          lastName: Yup.string().required("required *"),
          IDNo: Yup.number().required("required *"),
          email: Yup.string().required("required *").email("invalid email"),
          phone: Yup.string().required("required *").matches("(?:^([+]254))((7)\\d{8})$","Incorrect phone number format. Start with +254"),
        }),
        onSubmit: async (values, { resetForm }) => {
          //convert 07 number to +254
          let re = new RegExp("(?:^(0))((7)\\d{8})$")
          if(re.test(values.phone)){
            let phone = values.phone
            console.log(phone)
            phone = phone.split('')
            phone.splice(0,1)
            phone = ['+254',...phone].join('')
            values.phone = phone
          }
         if (action === `create`){
            values.buildingId = buildingId
          try{
            dispatch(addTenant(values))
          }catch(error){
            console.log("Hello")
          }
           resetForm()
           toggle()
         }else{
           const updated = {tenantId:tenant["_id"],values:values}
           dispatch(updateTenantReducer(updated))
           resetForm()
           toggle()
         }
        },
      });
      
      useEffect(() => {
        if(modal){
        let units = []
        const fetch = async ()=>{
          try {
            const response = await getVacantUnits(buildingId?buildingId:tenant?.buildingId)
            if(action ==="edit"){
              const res=tenant && await getUnit(tenant?.buildingId,tenant?.unitId)
              units.push(
                <option value={res['_id']} key={res['_id']} defaultValue>{res['name']}</option>,    
              )
            }
           
            response.map((unit,index)=>{
              units.push(
                <option value={unit['_id']} key={index}>{unit['name']}</option>,
              )
            })
            setUnitOptions(units)
            
          } catch (error) {
            
          }
        }
        fetch()
      }
      },[modal]);
     
    return ( 
        <>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
                <ModalHeader className="text-center d-block">
                    {action} Tenant 
                </ModalHeader>
                <ModalBody>
                    <Form role="form" onSubmit={formik.handleSubmit}>
                        {formGroup("fas fa-user", "text", "firstName", formik, "first name")}
                        {formGroup("fas fa-user", "text", "lastName", formik, "last name")}
                        {formGroup("fas fa-id-card", "number", "IDNo", formik,)}
                        {formGroup("fas fa-envelope", "email", "email", formik,"janeDoe@gmail.com")}
                        {formGroup("fas fa-building", "select", "unitId", formik,'',unitOptions)}
                        {formGroup("fas fa-phone", "text", "phone", formik)}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                        formik.handleSubmit();
                        }}
                        type="submit"
                        disabled={!formik.isValid && !formik.dirty}
                    >
                        {action}
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
     );
}
 
export default TenantForm;