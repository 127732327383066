import React, {useEffect, useState} from 'react';
import './ColorPicker.css'
import { ChromePicker } from 'react-color'
import Tippy from '@tippyjs/react'



const ColorPicker = (props) => {
    const [color, setColor] = useState('#eb1616');
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(typeof props.eventColor !== 'undefined'){
            setColor(props.eventColor);
        }
      }, [props.eventColor]);

    const handleClick = () => {
        setVisible(!visible)
      };
  
    const hide = () =>{
        setVisible(false)
      }
  
    const onChangeHandler = (color) =>{
        setColor({ color });
        props.setColorCode(color);
      }

    return (
        <span>
          <Tippy interactive={true} 
            visible={visible} placement={'bottom'} onClickOutside={hide}
            content={
              <ChromePicker
                        color={color}
                        onChangeComplete={color => onChangeHandler(color.hex)}
                      />
                    }>
              <button className='pickerbtn px-3 py-2' onClick={handleClick} type="button" disabled={props.disabled}>Pick a color</button>
          </Tippy>

          <button className='pickerbtn px-4 py-2' 
            style={{ 
              backgroundColor: color,
              textIndent: '-9000px', 
              textTransform: 'capitalize',
              cursor: 'not-allowed',
              outline: 'none'
             }} 
             type="button">Pick a color</button>
      </span>
    );
};

export default ColorPicker;
