import {createSlice} from '@reduxjs/toolkit'

export const smsbalanceSlice = createSlice({
    name: 'user',
    initialState:{
        value:{},
        status:'idle',
        error:null
    },
    reducers: {

        getSmsUnitsBalance:(state,action)=>{
            state.value = action.payload
        }
    },
})

export const { getSmsUnitsBalance } = smsbalanceSlice.actions

export const selectBalance = state => state.smsbalance.value;


export default smsbalanceSlice.reducer;
