import {Badge,Button} from 'reactstrap'
import {format} from 'date-fns'
import { dateBetweenFilterFn, DateRangeColumnFilter } from './DateRangeColumnFilter';
import store from 'store/store'
import {setPaymentStatus} from 'store/reducers/paymentsSlice'
import { fetchAllPayments } from "store/reducers/paymentsSlice";


export const paymentColumns = [
  
    {
        Header: "Date",
        accessor: "createdTime",
        Cell:({value})=>{return format(new Date(value),'dd/MM/yyyy HH:mm:ss')},
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "PAYMENT TYPE",
        accessor: "paymentType",
      },
      {
        Header: "BUILDING",
        accessor: "building",
      },
      {
        Header: "UNIT",
        accessor: "unit",
      },
      {
        Header: "TENANT",
        accessor: "tenant",
      },
      {
        Header: "PAYMENT MODE",
        accessor: "modeOfPayment",
      },
      {
        Header: "TRANSACTION CODE",
        accessor: "transactionCode",
      },
      {
        Header: "AMOUNT",
        accessor: "amount",
      },
      {
        Header: "ACTION",
        accessor: "status",
        
        Cell:props=>{
            let paymentStatus = props.row.original.status;
            // if status is null e.g for old records or if it contains pending
            const result = !paymentStatus || (paymentStatus && paymentStatus.toLowerCase().includes("pending")) ? (
                <>
                    <Button
                        id= {props.row.original._id}
                        color="success"
                        onClick={(e) => {
                            e.stopPropagation()
                            let obj = {id: props.row.original._id, status: "CONFIRMED"}
                            store.dispatch(setPaymentStatus(obj))
                            store.dispatch(fetchAllPayments())
                            console.log("on click approve status=>", paymentStatus, " for id=>", props.row.original._id)
                        }}
                        size="sm"
                    >
                            Approve {' '}
                            <i className={`fas fa-check`}></i>
                    </Button>

                    {' '}

                    <Button
                        id= {props.row.original._id}
                        color="danger"
                        onClick={(e) => {
                            e.stopPropagation()
                            let obj = {id: props.row.original._id, status: "REJECTED"}
                            store.dispatch(setPaymentStatus(obj))
                            store.dispatch(fetchAllPayments())
                            console.log("on click reject status=>", paymentStatus, " for id=>", props.row.original._id)
                        }}
                        size="sm"
                    >
                            Reject {' '}
                            <i className={`fas fa-times`}></i>
                    </Button>
                </>
            ) : (
                <Button
                    id= {props.row.original._id}
                    disabled={props.row.original.status.toLowerCase().includes("rejected") || props.row.original.status.toLowerCase().includes("confirmed")}
                    color={props.row.original.status.toLowerCase().includes("pending") ? "primary" : (props.row.original.status.toLowerCase().includes("rejected") ? "danger" : "success")}
                    size="sm"
                >
                        { props.row.original.status.toUpperCase() }
                </Button>
            )
            return result;
        }
    },
];
