import { API } from "../utils/api";

async function createTenant(tenant) {
  try {
    const response = await API.post(`tenants/add`,tenant);
      return response.data
  } catch(error) {
    return error.response
  }
}

async function createTenants(tenants) {
  try {
    const response = await API.post(`tenants/addtenants`,tenants);
      return response.data
  } catch(error) {
      return error
  }
}
function getFullName(tenants){
  let newTenantsArray = []
  tenants.map(tenant=>{
    let fullName = `${tenant.firstName} ${tenant.lastName}`
    tenant.fullName = fullName
    newTenantsArray.push(tenant)
  })
  return newTenantsArray
}
async function getAllTenants() {
    try {
      const response = await API.get(`all/tenants`);
      const result = getFullName(response?.data?.message)
      return result
    } catch(error) {
        console.log(error)
    }
  }
async function getBuildingTenants(id) {
    try {
      const response = await API.get(`building/tenants/${id}`);
      return response?.data?.message
    } catch {
        console.log("something went wrong")
    }
  }
  async function updateTenant(tenantId,value) {
    try {
      const response = await API.post(`tenants/update/${tenantId}`,value);
      
        return response?.data
      
    } catch {
        console.log("something went wrong")
    }
  }
  async function deleteTenant(id) {
    try {
      const response = await API.delete(`tenants/${id}`);
      
        return response?.data
      
    } catch {
        console.log("something went wrong")
    }
  }
  async function sendReminder(id,value) {
    try {
      const response = await API.post(`tenant/reminder/${id}`,value);
      return response?.data
    } catch {
      console.log("something went wrong")
    }
  }

export {getAllTenants,getBuildingTenants,createTenant,createTenants,updateTenant,deleteTenant, sendReminder}
