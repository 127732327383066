import {API} from "../utils/api";

async function createBuilding(building) {
  try {
    const response = await API.post(`properties/buildings/add`,building);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getBuilding(id){
    try {
        const response = await API.get(`properties/whatsapp/buildings/${id}`);
        return response?.data
      } catch {
          console.log("something went wrong")
      }
}

async function getBuildings() {
  try {
    const response = await API.get(`properties/buildings/`);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getBuildingsPerformance() {
    try {
      const response = await API.get(`properties/buildings/performance/`);
      return response?.data
    } catch {
        console.log("something went wrong")
    }
  }

async function updateBuilding(id,buildingData) {
  try {
    const response = await API.post(`properties/buildings/${id}`,buildingData);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function deleteBuilding(id) {
  try {
    const response = await API.delete(`properties/buildings/${id}`);
    return response.data
  } catch {
      console.log("something went wrong")
      return false
  }
}


export {getBuilding,getBuildings,getBuildingsPerformance,createBuilding,updateBuilding,deleteBuilding}