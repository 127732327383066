import {API} from "../utils/api";

async function createParameter(utilitytype) {
  try {
    const response = await API.post(`parameters/add`,utilitytype);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getParameter(id){
    try {
        const response = await API.get(`parameters/${id}`);
        return response?.data
      } catch {
          console.log("something went wrong")
      }
}

async function getParameters() {
  try {
    const response = await API.get(`parameters/`);
    return response?.data
  } catch(error) {
      console.log("something went wrong ")
  }
}

async function updateParameter(id,utilitytypeData) {
  try {
    const response = await API.post(`parameters/update/${id}`,utilitytypeData);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function deleteParameter(id) {
  try {
    const response = await API.delete(`parameters/delete/${id}`);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}


export {
    getParameter,
    getParameters,
    createParameter,
    updateParameter,
    deleteParameter
}