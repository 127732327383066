import {API} from "../utils/api";

async function createSmsTopup(topup) {
  try {
    const response = await API.post(`smstopups/add`,topup);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getSmsTopup(id){
    try {
        const response = await API.get(`smstopups/${id}`);
        return response?.data
      } catch {
          console.log("something went wrong")
      }
}

async function getSmsTopups() {
  try {
    const response = await API.get(`smstopups/`);
    return response?.data
  } catch(error) {
      console.log(error)
      console.log("something went wrong")
  }
}

async function updateSmsTopup(id,topupData) {
  try {
    const response = await API.post(`smstopups/${id}`,topupData);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function deleteSmsTopup(id) {
  try {
    const response = await API.delete(`smstopups/${id}`);
    return response.data
  } catch {
      console.log("something went wrong")
      return false
  }
}


export { getSmsTopup, getSmsTopups, createSmsTopup, updateSmsTopup, deleteSmsTopup }