import { Table, Button } from "reactstrap";
import { useEffect } from "react";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {genReport} from "../../services/reportService"


const useStyles = makeStyles({
  customTable: {
      // padding: "200px 200px 60px 160px",
      // background: "yellow"
  },
});

function ReportTable(props) {
  const classes = useStyles();
  const { tableInstance, item, returnData } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
  } = tableInstance;
  const [open, setOpen] = React.useState(false);

  useEffect(() => { 
    returnData && returnData(rows)
  }, [rows]);

const {pageIndex} = state



  return (
    <>

      <Table
        {...getTableProps()}
        classes={{root: classes.customTable}}
        className="align-items-center table-flush"
        responsive
        
      >
        <thead  className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr  {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th style={{ padding:"0.5rem", paddingLeft:"1rem"}} scope="col" {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody  {...getTableBodyProps()}>
          { page.map((row) => {
            prepareRow(row);
             return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td style={{ padding:"1rem"}} {...cell.getCellProps()}> {cell.render("Cell")}  </td>
                  );
                })}
        </tr>

            );

          })}
        </tbody>
      </Table>
      <div className="text-center mb-5">
        <span className="mr-2">
          Page {' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </div>
    </>
  );
}

export default ReportTable;
