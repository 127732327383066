import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { 
    getMaintenances, 
    getWhatsappMaintenancesByBuilding, 
    postWhatsappMaintenance } from 'services/maintenanceService'
import { notify } from 'utils/sharedFunctions'


export const fetchAllMaintenances = createAsyncThunk('All/maintenance', async () => {
    const response = await getMaintenances();
    return response
})

export const getMaintenancesByBuilding = createAsyncThunk('whatsapp/getMaintenancesByBuildingId', async (maintenance) => {
    const { buildingId, memberId } = maintenance;
    const response = await getWhatsappMaintenancesByBuilding(buildingId, memberId);
    return response;
});


export const postWhatsappMaintenanceByBuilding = createAsyncThunk('whatsapp/postMaintenanceByBuildingId', async (maintenance) => {
    const response = await postWhatsappMaintenance(maintenance);
    return response;
});

export const maintenanceSlice = createSlice({
    name: 'maintenances',
    initialState:{
        value:[],
        status:'idle',
        error:null,
        disableActions: false,
        buildingDetails: {}
    },
    reducers: {    
      clearTenantsState:(state,action)=>{
        state.value=[];
        state.status='idle'
        state.disableActions = false
        state.buildingDetails = {}
    }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAllMaintenances.rejected,(state,action)=>{
                state.error = "An error occurred while fetching maintenance data"
            })
            .addCase(fetchAllMaintenances.fulfilled, (state, action) => {
                state.status ='complete'
                state.value = action.payload
            })
            .addCase(getMaintenancesByBuilding.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(getMaintenancesByBuilding.fulfilled, (state, action)=>{
                state.status = "complete"
                //state.value = action.payload?.data
                state.buildingDetails = action.payload?.data
                if(action.payload.responseCode == "01"){
                    state.value = []
                    state.error = action.payload.responseDescription
                }
            })
            .addCase(postWhatsappMaintenanceByBuilding.fulfilled, (state, action)=>{
                if(action.payload == undefined || action.payload.responseCode == "01"){
                    state.error = action.payload.responseDescription;
                    notify("error", "Error Adding Maintenance!");
                }else{
                    state.value.push(action.payload.data);
                    state.error = '';
                    notify("success", "Maintenance added Successfully!");
                    // state.status = "idle";
                    state.disableActions = true;
                }
            })
    }
})

export const selectMaintenances = state => state.maintenances.value;
export const selectMaintenanceBuildingDetails = state => state.maintenances.buildingDetails;
export const selectMaintenanceStatus = state => state.maintenances.status;
export const selectMaintenanceError = state => state.maintenances.error;
export const disableReadingActions = state => state.maintenances.disableActions;
export default maintenanceSlice.reducer;
