import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getAllBalances,createPayment,getAllPayments, getAllPaymentsAndTypes, getDashboardRecentPayments, setPaymentStatusService } from "../../services/paymentsService";
import {sendReminder} from "services/tenantService"

export const fetchAllPayments = createAsyncThunk("All/Payments",async () => {
  const response = await getAllPaymentsAndTypes();
  return response;
})

export const fetchDashboardRecentPayments = createAsyncThunk("All/RecentPayments",async () => {
    const response = await getDashboardRecentPayments();
    return response;
  })

export const fetchAllBalances = createAsyncThunk("All/balances", async () => {
  const response = await getAllBalances();
  return response;
})
export const addPayment = createAsyncThunk("create/payment", async (values) => {
  const response = await createPayment(values);
  return values;
})
export const remindPayment = createAsyncThunk("remind/payment", async (id,values) => {
    const response = await sendReminder(id,values);
  return response;
})
export const setPaymentStatus = createAsyncThunk("status/payment", async (value) => {
    const response = await setPaymentStatusService(value);
    return response;
  })
const errorMessage = "An error occurred "
export const paymentsSlice = createSlice({
    name: "payments",
    initialState: {
        value: [],
        balances:[],
        accessor:undefined,
        status:'idle',
        error:null
    },
    reducers: {
        clearPaymentsState:(state,action)=>{
            state.value=[];
            state.status='idle'
        },
        setAccessor:(state,action)=>{
            state.accessor = action.payload
        },
        clearPaymentError:(state,action)=>{
            state.error = null
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchAllBalances.pending, (state, action) => {

        state.status = "loading";
        
        }).addCase(fetchAllBalances.fulfilled, (state, action) => {
            state.status = "complete";
            state.balances = action.payload;
        })
        .addCase(fetchAllPayments.rejected,(state,action)=>{
                state.error = `${errorMessage} while fetching all payments`
            })
        .addCase(fetchAllPayments.fulfilled, (state, action) => {
            state.status = "complete";
            state.value = action.payload;
        })
        .addCase(fetchDashboardRecentPayments.rejected,(state,action)=>{
                state.error = `${errorMessage} while fetching recent payments`
            })
        .addCase(fetchDashboardRecentPayments.fulfilled, (state, action) => {
            state.status = "complete";
            state.value = action.payload;
        })
        .addCase(addPayment.pending, (state, action) => {
        state.status = "loading";
        })
        .addCase(addPayment.fulfilled, (state, action) => {
            state.status = "complete";
        })
        .addCase(remindPayment.fulfilled, (state, action) => {
        const findIndex = state.value.findIndex(item=>item.tenantId === action.payload.tenantId)
        state.value[findIndex]=action.payload
        
        })
        .addCase(setPaymentStatus.fulfilled, (state, action)=>{
            const findIndex = state.value.findIndex(item=>item._id === action.payload._id)
            state.value[findIndex]=action.payload
            state.status = "idle";
        })
    }
});


export const {clearPaymentsState,setAccessor,clearPaymentError} = paymentsSlice.actions

export const selectPayments = (state) => state.payments.value;
export const selectAccessor = (state) => state.payments.accessor;
export const selectPaymentError = (state) => state.payments.error;
export const selectPaymentsBalances = (state)=> state.payments.balances;


export const selectPaid = (state)=> {
    let paid =0 
    let rent = 0
    let utilities = 0
    let maintenance = 0
    state.payments.balances.map((payment)=>{
        paid+=payment.paid
        rent+=payment.rent
        utilities+=payment.utilities
        maintenance+=payment.maintenance
    })
    return {paid,rent,utilities,maintenance}
}
export const selectUnPaid = (state)=> {
    let unPaid =0 
    let rent = 0
    let utilities = 0
    let maintenance = 0
    state.payments.balances.map((payment)=>{
        unPaid+=payment.balance >0 ?payment.balance:0
        rent+= payment.owedRent>0?payment.owedRent:0
        utilities+= payment.owedUtilities>0?payment.owedUtilities:0
        maintenance+= payment.owedMaintenance>0?payment.owedMaintenance:0
    })
    return {unPaid,rent,utilities,maintenance}
}

export const selectPaymentsStatus = state=>state.payments.status

export default paymentsSlice.reducer;
