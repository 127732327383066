import { useEffect,useState,useMemo} from "react";
import { Card, CardHeader, Row } from "reactstrap"
import { useDispatch,connect } from "react-redux";
import { fetchAllBuildingsPerformance } from "store/reducers/buildingsSlice";
import './RevenueAndPerformance.css'
import MTable from './MTable';

 const RevenueTable = (props) =>{
    const {buildings} = props;
    const dispatch = useDispatch();
    const headings = ["Building Name", "No. of houses"];
    const [search, setSearch] = useState("");
    const [selectedType, setSelectedType] = useState(undefined);
    const data = useMemo(() => buildings, [buildings]
    );
    const [results, setResults] = useState([
        {
            _id: "1234",
            name: "Partnered Apartments",
            paid: "123",
            unpaid: "120",
            graph: "64",
            performance: "64%"
        },
        {
            _id: "5678",
            name: "Neighborly Nest",
            paid: "440",
            unpaid: "210",
            graph: "48",
            performance: "48%"
        },
        {
            _id: "90123",
            name: "Ample Apartments",
            paid: "500",
            unpaid: "342",
            graph: "64",
            performance: "64%"
        },
        {
            _id: "38923",
            name: "Nesting Neighbors",
            paid: "230",
            unpaid: "213",
            graph: "14",
            performance: "14%"
        },
        {
            _id: "38993",
            name: "Grand Rosso",
            paid: "410",
            unpaid: "0",
            graph: "100",
            performance: "100%"
        }
    ]);
    
    useEffect(() => {
        dispatch(fetchAllBuildingsPerformance());
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        setSearch(value);
    };

    const handleSelect = (event) => {
        setSelectedType(event.target.value);
    };


    return (
        <Card className="shadow overflow-hidden">
            <CardHeader className="border-0" style={{backgroundColor: '#F9FAFD'}}>
              <Row className="align-items-center">
                <div className="col-8">
                  <h3 className="mb-0 text-dark">Revenue and Performance</h3>
                </div>
                {/* <div className="col-4">
                    <Row>
                        <div className="col-6">
                            <Input
                                placeholder="Search by name"
                                value={search}
                                bsSize="sm"
                                onChange={handleChange}
                            ></Input>
                        </div>
                        <div className="col-6">
                            <Input
                                id="exampleSelect"
                                name="select"
                                bsSize="sm"
                                type="select"
                                value={selectedType}
                                onChange={handleSelect}
                                >
                                <option>
                                Select
                                </option>
                                <option>
                                    Revenue
                                </option>
                                <option>
                                    Income
                                </option>
                            </Input>
                        </div>
                    </Row>
                </div> */}
              </Row>
            </CardHeader>
                <MTable
                    headings={headings}
                    data={data}
                    actions={true}
                    clickable={true}
                />
          </Card>
    )
}

const mapPropsToState = state =>{
    return{
      buildings:state.buildings.value,
      buildingStatus:state.buildings.status
    }
  }
  
  
  export default connect(mapPropsToState)(RevenueTable);