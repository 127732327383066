import { useState } from "react";
import { Table, Button } from "reactstrap";
import InvoiceForm from "components/Forms/invoiceForm";

function PaymentsTable(props) {
    const { tableInstance } = props;
    const [tenant,setTenant] = useState({})
    const [open,setOpen] = useState(false)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        selectedFlatRows,
        state,
        data
    } = tableInstance;

    const handleClose = () => {
        setOpen(false);        
      };

    return (
        <>
        <InvoiceForm
                modal={open}
                closeModal={handleClose}
                action={"add"}
                tenant={tenant}
            ></InvoiceForm>

            <Table
                {...getTableProps()}
                className="align-items-center table-flush"
                responsive
            >
                <thead className="thead-light">
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,index) => (
                                <th key = { index } style={{ padding: "0.5rem", paddingLeft: "1rem" }} scope="col" {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                                </th>
                            ))}
                            <th style={{ padding: "0.5rem", paddingLeft: "1rem" }} scope="col">
                                <div>Action</div>
                            </th>
                        </tr>
                    ))}
                </thead>
                <tbody  {...getTableBodyProps()}>
                    {page.map((row,index) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell,i) => {
                                    return (
                                        <td key={i} style={{ padding: "1rem" }} {...cell.getCellProps()}> {cell.render("Cell")}  </td>
                                    );
                                })}
                                <td style={{ padding: "1rem" }}>
                                    <Button color="primary"
                                        onClick={() => {
                                            setTenant(data[index])
                                            setOpen(true)
                                        }}
                                        size="sm" >
                                        <i className="fas fa-paper-plane"></i>
                                        <span className="d-none d-md-inline">Invoice</span>
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <div className="text-center mb-5">
                <span className="mr-2">
                    Page {' '}
                    <strong>
                        {state.pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    Previous
                </Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                    Next
                </Button>
            </div>
         
        </>
    );
}

export default PaymentsTable;
