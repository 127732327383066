import {API} from "../utils/api";




async function getSmsBalance()
{
    try
    {
      const response = await API.get('smsbalances/')
      return response?.data
    }catch(error)
    {
      return error
    }
}


export { getSmsBalance }
