import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { 
    Alert, 
    Table, 
    Card, 
    CardHeader, 
    CardBody, 
    CardFooter, 
    Container, 
    Row, 
    Col, 
    Input, 
    Button,
    Form,
    FormGroup, Label, InputGroup, FormText } from "reactstrap";
import Loader from "components/loader/Loader";
import ColorPicker from 'components/ColorPicker/ColorPicker';
import Select from 'react-select';

import { 
    selectMaintenanceStatus,
    selectMaintenanceError,
    disableReadingActions,
    postWhatsappMaintenanceByBuilding
} from "store/reducers/maintenanceSlice";


import { 
    getBuildingById, 
    selectedBuildingById
} from "store/reducers/buildingsSlice";


const MaintenanceWhatsapp = () => {
    const disableActions = useSelector(disableReadingActions);
    const error = useSelector(selectMaintenanceError);
    const building = useSelector(selectedBuildingById);
    const maintenanceStatus = useSelector(selectMaintenanceStatus);
    const buildingName = building?.name ? building.name : "";
    const units =  building?.units ? building.units : [];
    const [unitOptions, setUnitOptions] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventOptions, setEventOptions] = useState([
        {value: "GENERAL", label: "GENERAL"},
        {value: "PLUMBING", label: "PLUMBING"},
        {value: "ELECTRICAL", label: "ELECTRICAL"}
    ]);

    const [eventColor, setEventColor] = useState("#eb1616");
    const [eventDate, setEventDate] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [status, setStatus] = useState("pending");
    const [errors, setErrors] = useState({
        unit: "",
        event: "",
        eventDate: "",
        eventDescription: "",
        status: ""
    });
    
    //get search parameters
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    const dispatch = useDispatch();

    useEffect(() => {
        // setup unit options
        let unitoptions = [];
        units.map((unit)=>{
            unitoptions.push({value: unit._id, label: unit.name});
        })
        setUnitOptions(unitoptions);
    }, [building]);

    useEffect(() => {
        // fetch building readings
        if (maintenanceStatus === 'idle') {
            if(params.get("buildingId") && params.get("memberId")){
                const getParams = {
                    buildingId: params.get("buildingId"),
                    memberId: params.get("memberId")
                }
                dispatch(getBuildingById(params.get("buildingId")));
            }
        }
    }, [maintenanceStatus]);

    const handleEventSelect = (evt) => {
        setSelectedEvent(evt);
        let errorStatus = evt.value.toLowerCase() == "" ? "invalid" : "valid";
        setErrors({...errors, event: errorStatus})
    };

    const handleUnitSelect = (evt) => {
        setSelectedUnit(evt);
        let errorStatus = evt.value.toLowerCase() == "" ? "invalid" : "valid";
        setErrors({...errors, unit: errorStatus})
    };


    const handleChange = (event, name) => {
        const value = name.toLowerCase() === "status" ? event.target.checked : event.target.value;
        switch (name.toLowerCase()) {
            case "eventdescription": {
                setEventDescription(value);
                let val = value == "" ? "invalid" : "valid";
                setErrors({...errors, eventDescription: val})
                break;
            }
            case "eventcolor": {
                setEventColor(value);
                break;
            }
            case "eventdate": {
                setEventDate(value);
                let val = value == "" ? "invalid" : "valid";
                setErrors({...errors, eventDate: val})
                break;
            }
            default:
                break;
        }
        
    };

    const isFormValid = () =>{
        let isValid = true;

        if(selectedUnit === null || selectedUnit === "" || typeof selectedUnit === 'undefined'){
            setErrors({...errors, unit: "invalid"})
            isValid = false;
        }

        if(selectedEvent === null || selectedEvent === "" || typeof selectedEvent === 'undefined'){
            setErrors({...errors, event: "invalid"})
            isValid = false;
        }

        if(eventDate === "" || typeof eventDate === 'undefined'){
            setErrors({...errors, eventDate: "invalid"})
            isValid = false;
        }

        if(eventDescription === "" || typeof eventDescription === 'undefined'){
            setErrors({...errors, eventDescription: "invalid"})
            isValid = false;
        }

        return isValid;
    }

    const submitAction = (evt) =>{
        evt.preventDefault();
        
        // check if form is valid
        if(!isFormValid()){
            alert("fill the required details");
            return;
        }

        const newMaintenance = {
            buildingId: params.get("buildingId"),
            agentId: params.get("memberId"),
            unitId: selectedUnit.value,
            maintenanceType: selectedEvent.value,
            eventDate,
            eventColor,
            eventDescription,
            status
        }

        dispatch(postWhatsappMaintenanceByBuilding(newMaintenance));

    }

    const cancelAction = () =>{
        console.log("cancel ")
    }

    return (
        <div>
            <div className="" style={{ height: '100px' }} ></div>
            <Container className="mt--5" fluid>
            <Row>
                {
                    error && (
                        <Col sm="12" xl={{ size: 8, offset: 2 }}>
                            <Alert color="danger" className="text-center"> {error} </Alert>
                        </Col>
                    )
                }
                <Col xl="12">
                    <Card className=" shadow overflow-hidden">
                        <CardHeader className="border-0">
                            <Row className="align-items-center">  
                                <div className="col-12">
                                    <h2 className="mb-3 text-dark">Maintenance Requests</h2>
                                    <h4 className="mb-0 text-dark">Building: {buildingName}</h4>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form role="form">
                                <FormGroup>
                                    <Label for="unit">Unit:</Label>
                                    <Select
                                        isMulti = {false}
                                        value={selectedUnit}
                                        onChange={handleUnitSelect}
                                        options={unitOptions}
                                        disabled={disableActions}
                                    />
                                    {
                                        (errors.unit == 'invalid' || errors.unit == '') && (
                                            <FormText color="danger">
                                            Add valid unit.
                                            </FormText>
                                        )
                                    }
                                </FormGroup>

                                <FormGroup>
                                    <Label for="status">Event:</Label>
                                    <Select
                                        isMulti = {false}
                                        value={selectedEvent}
                                        onChange={handleEventSelect}
                                        options={eventOptions}
                                        disabled={disableActions}
                                    />
                                    {
                                        (errors.event == 'invalid' || errors.event == '') && (
                                            <FormText color="danger">
                                                Select Event
                                            </FormText>
                                        )
                                    }
                                </FormGroup>

                                <FormGroup>
                                    <Label for="eventDate">Event Date:</Label>
                                    <Input
                                        type="date"
                                        name="eventDate"
                                        id="eventDate"
                                        placeholder="Event Date"
                                        value={eventDate}
                                        valid={ errors.eventDate === 'valid' }
                                        invalid={ errors.eventDate === 'invalid' || typeof eventDate === 'undefined' }
                                        onChange={ (event) => handleChange(event, "eventdate") }
                                        disabled={disableActions}
                                    />
                                    {
                                        (errors.eventDate === 'invalid' || errors.eventDate === '' || typeof eventDate === 'undefined') && (
                                            <FormText color="danger">
                                                Field is required.
                                            </FormText>
                                        )
                                    }
                                </FormGroup>

                                <FormGroup>
                                    <label
                                        className="form-control-label" >Event Color
                                    </label>
                                    <div>
                                        <ColorPicker disabled={disableActions} setColorCode={setEventColor} eventColor={eventColor}/>
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                    >
                                        Event Description
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                            placeholder="Enter Description"
                                            type={"textarea"}
                                            value={eventDescription}
                                            valid={ errors.eventDescription === 'valid' }
                                            invalid={ errors.eventDescription === 'invalid' || typeof eventDescription === 'undefined' }
                                            onChange={(event) => handleChange(event, "eventDescription")}
                                            disabled={disableActions}
                                        >
                                        </Input>
                                    </InputGroup>
                                    {
                                        (errors.eventDescription == 'invalid' || errors.eventDescription == '' || typeof eventDescription === 'undefined') && (
                                            <FormText color="danger">
                                                Field is required.
                                            </FormText>
                                        )
                                    }
                                </FormGroup>
                            </Form>
                        </CardBody>
                        <CardFooter className="text-center">
                            <Button 
                                type="submit"
                                color="primary" 
                                onClick={submitAction}
                                disabled={disableActions}
                                >
                                Submit
                            </Button>{" "}
                            <Button 
                                color="secondary" 
                                onClick={cancelAction}
                                disabled={disableActions}
                                >
                                Cancel
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>
    );
}



const Properties = () => {
  const maintenanceStatus = useSelector(selectMaintenanceStatus);

  return (
    <>
      {maintenanceStatus === 'loading' ? <Loader /> : <MaintenanceWhatsapp />}
    </>
  );
};




export default Properties;


