import React, { useState,useMemo } from "react";
import { useTable, usePagination } from "react-table";

import { Table, Button } from "reactstrap";
import {useSelector,useDispatch,connect} from 'react-redux'
import {selectBuildingDetails,removeUnit,selectUnits} from 'store/reducers/buildingsSlice'

import UnitForm from 'components/Forms/UnitForm'
import DeleteModal from 'components/Modals/DeleteModal'

import {unitColumns} from 'utils/unitColumns'

function HouseTable(props) {
  const dispatch = useDispatch()
  const building = useSelector(selectBuildingDetails)
  const units = useSelector(selectUnits)
  const [OpenDelete, setOpenDelete] = useState(false);
  const [OpenUpdate, setOpenUpdate] = useState(false);
  const [unit, setUnit] = useState({});

  const columns = useMemo(() => unitColumns, []);
  const data = useMemo(() => units, [units]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state:{ pageIndex, pageSize }
  } = useTable({ columns, data,initialState:{pageSize:6} }, usePagination);

    const handleClose = ()=>{
    setOpenUpdate(false)
    setOpenDelete(false)

  }

  const handleConfirm = async (id) =>{   
    
     let unitObject = {buildingId:building["_id"],unitId:id}
     dispatch(removeUnit(unitObject))

  }
 

  return (
    <>
    <UnitForm modal = {OpenUpdate} closeModal = {handleClose} action= {"edit"} unit = {unit} ></UnitForm>
    <DeleteModal modal = {OpenDelete} closeModal = {handleClose} title={"unit"} item={unit} confirmModal = {handleConfirm}></DeleteModal>
    <Table
        {...getTableProps()}
        className="align-items-center table-flush"
        responsive
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th scope="col" {...column.getHeaderProps()}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th>actions</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row,index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
              
              className={`customRows`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
                <td>
                <Button
            color="primary"
            href="#pablo"
            onClick={() => {setOpenUpdate(true);setUnit(row.original)}}
            size="sm"
          >
            <i className="fas fa-pencil-alt"></i>
          </Button>
          <Button
            href="#pablo"
            className="btn-danger"
            style={{color: "white" }}
            onClick={() => {setOpenDelete(true);setUnit(row.original) }}
            size="sm"
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center mb-3">
        <span className="mr-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
      </div>
    </>
  );
}



export default HouseTable;
