import {
    Card,
    CardBody,
    Row,
    Col,
  } from "reactstrap";

function ProfileSummary(props) {
    const {buildings,units,tenants,user} = props

    return <Card className="card-profile shadow">
        <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
                <div className="card-profile-image text-center mt-3">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <i class="far fa-user rounded-circle" style={{ fontSize: 3.33 + "em", lineHeight: 1.75 + "em" }}></i>
                    </a>
                </div>
            </Col>
        </Row>
        <CardBody className="pt-0 pt-md-4">
            <Row>
                <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                            <span className="heading">{buildings ? buildings.length : 0}</span>
                            <span className="description">Buildings</span>
                        </div>
                        <div>
                            <span className="heading">{units ? units.length : 0}</span>
                            <span className="description">Units</span>
                        </div>
                        <div>
                            <span className="heading">{tenants ? tenants.length : 0}</span>
                            <span className="description">Tenants</span>
                        </div>
                    </div>
                </div>
            </Row>
            <div className="text-center">
                <h3>
                    {user.userName}
                </h3>
                <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                </div>
                <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {/* Solution Manager - Creative Tim Officer */}
                </div>
                <div>
                    <i className="ni education_hat mr-2" />
                    {/* University of Computer Science */}
                </div>
                <hr className="my-4" />

            </div>
        </CardBody>
    </Card>
}

export default ProfileSummary