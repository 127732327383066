

// reactstrap components
import {useEffect} from 'react'
import {  Container, Row, Col } from "reactstrap"
import {useSelector,useDispatch} from "react-redux"
import Analysis from './Analysis'
import './Header.css'
import {selectBuildings,fetchAllBuildings} from 'store/reducers/buildingsSlice'
import {selectTenants,fetchAllTenants,selectTenantStatus} from 'store/reducers/tenantsSlice'
import {fetchAllBalances,selectPaymentsStatus,selectPaid,selectUnPaid} from 'store/reducers/paymentsSlice'
import DashboardTile from '../DashboardTile/DashboardTile'
import PieChartComp from 'components/Charts/PieChart'
import { abbreviate } from '../../utils/shortenNumber'


const Header = () => {
  const buildings = useSelector(selectBuildings)
  const tenants = useSelector(selectTenants)
  const status = useSelector(selectTenantStatus)
  const paymentStatus = useSelector(selectPaymentsStatus)
  const {paid,rent,utilities,maintenance} = useSelector(selectPaid)
  const unPaid = useSelector(selectUnPaid)
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchAllBuildings())
    if (status === 'idle') {
      dispatch(fetchAllTenants())
    }
    if (paymentStatus === 'idle'){
      dispatch (fetchAllBalances())
    }

  }, []);
  return (
    <>
       <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
            <Col lg="6" className='mt-sm-2 custom-resize'>
              <DashboardTile name={"Buildings"} analytics = {<Analysis asset='buildingGraph.svg' />}>
                <div>
                {buildings?buildings.length:0}
                </div>
                <div>
                  <img src={
                  require("../../assets/img/icons/dashboard/buildings.svg").default
                } />
                </div>
              </DashboardTile>
            </Col>  
              <Col lg="6" className='mt-sm-2 custom-resize'>
                <DashboardTile name={"Tenants"} analytics = {<Analysis asset='tenantsGraph.svg' />}>
                <div>
                {tenants?tenants.length:0}
                </div>
                <div>
                  <img src={
                  require("../../assets/img/icons/dashboard/tenants.svg").default
                } />
                </div>
                </DashboardTile>
              </Col>
              
              <Col lg="6" className='mt-sm-2 custom-resize'>
                <DashboardTile name={"Paid"} analytics={<PieChartComp data={[{name:'Maintenance',value:maintenance},
                {name:'Utilities',value:utilities},{name:'Rent',value:rent}]} paid={abbreviate(paid,1,false,false)}
                COLORS={['#D8E2EE','#27BCFE','#2A7BE4']}
                />}>
                
                <div>
                  <div className='wrapper'>
                  <div className='circle' style={{backgroundColor:'#2A7BE4'}} data-toggle="tooltip" data-placement="right" title='Rent'>
                  </div>
                  <span style={{fontSize:16,fontWeight:400}}>Rent</span>
                  </div>
                  <div className='wrapper'>
                  <div className='circle' style={{backgroundColor:'#27BCFE'}}>
                  </div>
                  <span style={{fontSize:16,fontWeight:400}}>Utilities</span>
                  </div>
                  <div className='wrapper'>
                  <div className='circle' style={{backgroundColor:'#D8E2EE'}}>
                  </div>
                  <span style={{fontSize:16,fontWeight:400}} className=''>Maintenance</span>
                  </div>
                </div>
                </DashboardTile>
              </Col>
      
              <Col lg="6" className='mt-sm-2 custom-resize'>
              <DashboardTile name={"Unpaid"} analytics={<PieChartComp data={[{name:'Maintenance',value:unPaid?.maintenance},
              {name:'Utilities',value:unPaid?.utilities},{name:'Rent',value:unPaid?.rent}]} paid={abbreviate(unPaid?.unPaid,1,false,false)}
              COLORS={['#F68F57','#D8E2EE','#E63756']}
              />}>
                
                <div>
                  <div className='wrapper'>
                  <div className='circle' style={{backgroundColor:'#E63756'}} data-toggle="tooltip" data-placement="right" title='Rent'>
                  </div>
                  <span style={{fontSize:16,fontWeight:400}}>Rent</span>
                  </div>
                  <div className='wrapper'>
                  <div className='circle' style={{backgroundColor:'#D8E2EE'}}>
                  </div>
                  <span style={{fontSize:16,fontWeight:400}}>Utilities</span>
                  </div>
                  <div className='wrapper'>
                  <div className='circle' style={{backgroundColor:'#F68F57'}}>
                  </div>
                  <span style={{fontSize:16,fontWeight:400}} className=''>Maintenance</span>
                  </div>
                </div>
                </DashboardTile>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;