export const parameterColumns = [
  
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "VALUE",
    accessor: "value",
  }

];
