import { API } from "../utils/api";

async function createUnit(id, unit) {
  try {
    const response = await API.post(`properties/unit/add/${id}`, unit);
    return response?.data
  } catch {
    console.log("something went wrong");
    return false;
  }
}
async function updateUnit(propertyId,unitId, unit) {
  try {
    const response = await API.post(`properties/${propertyId}/unit/${unitId}`, unit);
    return response?.data
  } catch {
    console.log("something went wrong");
    return false;
  }
}
async function getUnits(propertyId) {
  try {
    const response = await API.get(`properties/unit/${propertyId}`);
    
      return response?.data;
    
  } catch {
    console.log("something went wrong");
  }
}
async function getUnit(propertyId,unitId) {
  try {
    const response = await API.get(`properties/${propertyId}/unit/${unitId}`);
    
      return response?.data;
    
  } catch {
    console.log("something went wrong");
  }
}

async function getVacantUnits(propertyId) {
  try {
    const response = await API.get(`properties/unit/vacant/${propertyId}`);
    return response?.data
  } catch {
    console.log("something went wrong");
  }
}


async function deleteUnit(propertyId,unitId) {
  try {
    const response = await API.delete(`properties/${propertyId}/unit/${unitId}`);
    return response?.data
  } catch {
    console.log("something went wrong");
    return false;
  }
}



export { createUnit, updateUnit, deleteUnit,getVacantUnits,getUnits,getUnit };
