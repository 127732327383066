import React, { useState, useEffect,useMemo } from "react";
import { useDispatch,connect} from "react-redux";
import { Table } from "reactstrap";
import { useTable, usePagination } from "react-table";
import {getUnits} from "store/reducers/buildingsSlice";
import "assets/css/tables.css";

import moment from "moment";

const dataColumns = [
  
    {
      Header: "Building Name",
      accessor: "building",
    },
    {
      Header: "Amount Paid",
      accessor: "amount",
    },
    {
        Header: "Tenant",
        accessor: "tenant",
    },
    {
        Header: "Date and Time",
        accessor: "createdTime",
        Cell:({value})=>{return moment(value).format('h:mma, d-MMMM-YYYY')},
    },
  
  ];


function MTable(props) {
  const [activeIndexState, setActiveIndex] = useState({ activeIndex: 0 });
  const dispatch = useDispatch();
  const { data } = props;

  const columns = useMemo(() => dataColumns, []);

  const tableInstance = useTable({ columns, data }, usePagination);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;



  useEffect(() => {
        setActiveIndex({ activeIndex: 0 });
  }, [data]);

  return (
    <>
      <Table
        {...getTableProps()}
        className="align-items-center table-flush"
        responsive
      >
        <thead className="thead-light2">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th scope="col" {...column.getHeaderProps()} style={{backgroundColor: '#EDF2F8'}}>
                  {column.render("Header")}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row,index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
              onClick={() => {
                setActiveIndex({ activeIndex: index });
                dispatch(getUnits(row.original["_id"]));
              }}
              className={`customRows`}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default MTable;
