
import {Route,Redirect} from 'react-router-dom';
import React from 'react';
import { isJwtExpired } from 'jwt-check-expiration';
import store from 'store/store';
import { clearState } from 'store/reducers/userSlice';
import { clearBuildingsState } from 'store/reducers/buildingsSlice';
import { clearPaymentsState } from 'store/reducers/paymentsSlice';
import { clearTenantsState } from 'store/reducers/tenantsSlice';
const token = localStorage.getItem('authToken')


function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function isAuthenticated() {

    if(token !== null){
        return true
    }else return false

}



function logOut(props){
    let logout = window.confirm('Confirm logout?')
    if(logout){
    localStorage.removeItem('authToken')
    store.dispatch(clearTenantsState())
    store.dispatch(clearPaymentsState())
    store.dispatch(clearBuildingsState())
    props.history.push('/auth/login')
    }
}



  function PrivateRoute(props){
    const { component: Component, ...rest } = props;

    const render = props => {
        if (localStorage.getItem('authToken') && isJwtExpired(localStorage.getItem('authToken'))===false) {
            return <Component {...props} />;
        }
        else{
        return <Redirect to="/auth/login" />;}
    };

    return <Route {...rest} render={render} />;
}

export { isAuthenticated,PrivateRoute,logOut,parseJwt};