import {API} from "../utils/api";

async function createMaintenance(topup) {
  try {
    const response = await API.post(`maintenances/add`,topup);
    return response?.data
  } catch {
      console.log("something went wrong")
  }
}

async function getMaintenance(id){
    try {
        const response = await API.get(`maintenances/${id}`);
        return response?.data
      } catch {
          console.log("something went wrong")
      }
}

async function getMaintenances() {
    const response = await API.get(`maintenances/`);
    return response?.data
}

async function updateMaintenance(id, requestData) {
  try {
    const response = await API.post(`maintenances/${id}`, requestData);
    return response?.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function deleteMaintenance(id) {
  try {
    const response = await API.delete(`maintenances/${id}`);
    return response.data
  } catch {
      console.log("something went wrong")
      return false
  }
}

async function getWhatsappMaintenancesByBuilding(id, memberId) {
    try {
        const url = `maintenances/whatsapp/maintenancesByBuildingId?buildingId=${id}&memberId=${memberId}`;
        const encodedUrl = encodeURI(url);
        const response = await API.get(encodedUrl);
        return response?.data
    } catch(error) {
        console.log("something went wrong ")
    }
}


async function postWhatsappMaintenance(maintenance) {
    try {
        const url = `maintenances/whatsapp/addMaintenanceByBuildingId`;
        const response = await API.post(url, maintenance);
        return response?.data
    } catch(error) {
        console.log("something went wrong ")
    }
}

export { 
    getMaintenance, 
    getMaintenances, 
    createMaintenance, 
    updateMaintenance, 
    deleteMaintenance,
    getWhatsappMaintenancesByBuilding,
    postWhatsappMaintenance
}