import {uninterceptedAxiosInstance} from "../utils/api";
import {API} from "../utils/api";



async function  login(credentials){
        const response = await uninterceptedAxiosInstance.post(`login`,credentials);
        localStorage.setItem("authToken",response.data.token)    
        return response
     
}

async function  forgot(credentials){
    try {
        const response = await uninterceptedAxiosInstance.post(`forgot`,credentials);
        return response.data
      } catch {
          console.log("something went wrong")
          return false
      }
}

async function  resetPassword(credentials){
    try {
        const response = await uninterceptedAxiosInstance.post(`resetpassword`,credentials);
        return response.data
      } catch {
          console.log("something went wrong")
          return false
      }
}

async function register(userDetails) {
    const response = await uninterceptedAxiosInstance.post(`signup`,userDetails);
    localStorage.setItem("authToken",response.data.token)
    return response

}


async function getUserProfile()
{
    try
    {
      const response = await API.get('profile/getProfile')
      return response?.data
    }catch(error)
    {
      return error
    }
}


async function updateUserProfile(value)
{
    try
    {
      const response = await API.post('profile/updateProfile',value)
      return response?.data
    }catch(error)
    {
      return error
    }
}
async function changePassword(value)
{
    try
    {
      const response = await API.post('profile/changePassword',value)
      return response?.data
    }catch(error)
    {
      return error
    }
}



export {login,register,forgot,resetPassword,getUserProfile,updateUserProfile,changePassword}
