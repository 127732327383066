import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";
import {useEffect,useState} from 'react'
import {useDispatch} from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import {getUserProfile,updateUserProfile,changePassword} from 'services/authService'
import {logOut} from 'utils/authChecks'

// core components
import formGroup from "components/Forms/FormFieldFunction"
import UserHeader from "components/Headers/UserHeader.js";
import ProfileSummary from "components/Profile/ProfileSummary";
import { useSelector } from "react-redux"
import { selectBuildings, selectUnits } from 'store/reducers/buildingsSlice'
import { selectTenants } from 'store/reducers/tenantsSlice'
import {getUser} from 'store/reducers/userSlice'

const Profile = (props) => {
  const token = localStorage.getItem('authToken')
  //const user = parseJwt(token)
  const buildings = useSelector(selectBuildings)
  const units = useSelector(selectUnits)
  const tenants = useSelector(selectTenants)
  const [user, setUser] = useState({})
  const dispatch = useDispatch()
  const profileForm = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        companyName: user.companyName ?? "",
        userName: user.userName ?? "",
        email: user.email ?? "",
        postalCode: user.postalCode ?? "",
        phone: user.phone ?? "",
        phone2: user.phone2 ?? "",
        address: user.address ?? "",
        city: user.city ?? "",
        country: user.country ?? "",
        bankName: user.bankName ?? "",
        branch: user.branch ?? "",
        accountName: user.accountName ?? "",
        accountNo: user.accountNo ?? "",
        payBill: user.payBill ?? "",
        mpesaAccount: user.mpesaAccount ?? "",

      },

      validationSchema: Yup.object({
        companyName: Yup.string(),
        userName: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        postalCode: Yup.string(),
        phone: Yup.string(),
        phone2: Yup.string(),
        address: Yup.string(),
        city: Yup.string(),
        country: Yup.string(),
        bankName:  Yup.string(),
        branch:  Yup.string(),
        accountName:  Yup.string(),
        accountNo:  Yup.number(),
        payBill:  Yup.number(),
        mpesaAccount:  Yup.string(),
      }),
      onSubmit: async (values) => {
        const response = await updateUserProfile(values)
        setUser(response)
        dispatch(getUser(response))

      }
    })



  useEffect(() => {
    const getProfile = async () => {
      const response = await getUserProfile()
      setUser(response)
      dispatch(getUser(response))
    }
    getProfile()
  }, [])
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <ProfileSummary buildings = {buildings} units={units} tenants={tenants} user={user}/>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role={"form"} onSubmit={profileForm.handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        {formGroup(null, "text", "companyName", profileForm, "company name", '', 'Company Name')}
                      </Col>
                      <Col lg="6">
                        {formGroup(null, "email", "email", profileForm, "janedoe@example.com", '', 'Email')}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        {formGroup(null, "text", "userName", profileForm, "User name", '', 'User name')}
                      </Col>
                      {/* <Col lg="6">
                        {formGroup(null, "file", "profileImg", profileForm, "Profile Image", '', 'Profile Image')}
                      </Col> */}

                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        {formGroup(null, "text", "postalCode", profileForm, "Postal code", '', 'Postal code')}

                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        {formGroup(null, "text", "phone", profileForm, "+254712345678", '', 'Phone')}
                      </Col>
                      <Col lg="4">
                        {formGroup(null, "text", "phone2", profileForm, "+254712345678", '', 'Alternative Phone')}
                      </Col>

                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Payment information
                  </h6>
                  <div className="pl-lg-4">
                    <Row className="">
                      <div className="float-left  col-6">
                      <h6 className="heading-small mb-4">
                        Bank
                      </h6>
                        {formGroup(null, "text", "bankName", profileForm, "Equity", '', 'Bank Name')}
                        {formGroup(null, "text", "branch", profileForm, "Nairobi", '', 'Branch')}
                        {formGroup(null, "text", "accountName", profileForm, "janedoe", '', 'Account Name')}
                        {formGroup(null, "number", "accountNo", profileForm, "12345678", '', 'Account Number')}
                      </div>
                      <div className="float-right col-6">
                      <h6 className="heading-small  mb-4">
                        Mpesa
                      </h6>
                      {formGroup(null, "number", "payBill", profileForm, "2323", '', 'Paybill Number')}
                      {formGroup(null, "text", "mpesaAccount", profileForm, "", '', 'Account')}
                        </div>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Location</h6>
                  <Row>
                    <Col md="12">
                      {formGroup(null, "text", "address", profileForm, "Address", '', 'Address')}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="4">
                      {formGroup(null, "text", "city", profileForm, "Nairobi", '', 'City')}
                    </Col>
                    <Col lg="4">
                      {formGroup(null, "text", "country", profileForm, "Kenya", '', 'Country')}
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      className="my-4"
                      color="primary"

                      type="submit"
                    >
                      Update profile
                    </Button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
