import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import BuildingTable from "components/Table/BuildingTable";
import HouseTable from "components/Table/HouseTable";
import BuildingForm from 'components/Forms/BuildingForm'
import UnitForm from 'components/Forms/UnitForm'
import Loader from "components/loader/Loader";
import {fetchAllBuildings,selectBuildings, selectBuildingStatus} from "store/reducers/buildingsSlice";



const PropertyContent = () => {
  const buildings = useSelector(selectBuildings)
  const headings = ["House no.", "Bedrooms", "Rent", "Status"];
  const headings2 = ["Name", "location", "Type of property", "No. of houses"];
  const [OpenBuilding, setOpenBuilding] = useState(false);
  const [OpenUnit, setOpenUnit] = useState(false);

  const handleClose = () => {
    setOpenBuilding(false)
    setOpenUnit(false)
  }

  return <div>
    <div className="" style={{ height: '200px' }} ></div>
    <Container className="mt--5" fluid>
      <BuildingForm modal={OpenBuilding} closeModal={handleClose} action={"create"}></BuildingForm>
      <UnitForm modal={OpenUnit} closeModal={handleClose} action={"create"} ></UnitForm>

      <Row className="mt-5">
        <Col xl="6">
          <Card className=" shadow overflow-hidden">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col-6">
                  <h3 className="mb-0 text-dark">Buildings</h3>
                </div>
                <div className="col-6 text-right">

                  <Button
                    color="primary"
                    onClick={() => setOpenBuilding(true)}
                    size="sm"
                    className="btn-sm ml-2"
                  >
                    <i className="fas fa-plus mr-0 mr-md-2"></i>
                    <span className="d-none d-md-inline">Add a building</span>
                  </Button>
                </div>
              </Row>
            </CardHeader>
            <BuildingTable
              headings={headings2}
              data={buildings}
              actions={true}
              clickable={true}
            />
          </Card>
        </Col>
        <Col className="mt-3 mt-md-0" xl="6">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col-6">
                  <h3 className="mb-0">Units</h3>
                </div>
                <div className="col-6 text-right">
                  <Button
                    color="primary"
                    onClick={() => setOpenUnit(true)}
                    size="sm"
                    className="btn-sm ml-2"
                    disabled={buildings.length === 0 ? true : false}
                  >
                    <i className="fas fa-plus mr-0 mr-md-2"></i>
                    <span className="d-none d-md-inline">Add a unit</span>
                  </Button>


                </div>
              </Row>
            </CardHeader>
            <HouseTable headings={headings} actions={true} />
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
}



const Properties = () => {
  const buildingStatus = useSelector(selectBuildingStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (buildingStatus === 'idle') {
      dispatch(fetchAllBuildings());
    }
  }, [buildingStatus]);

  return (
    <>
      {buildingStatus === 'loading' ? <Loader /> : <PropertyContent />}
    </>
  );
};




export default Properties;


